import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferencedTradingPartnership, TradingPartnership } from 'app/shared/common/interfaces/tradingPartnership';
import { TradingPartnershipService } from 'app/core/services';
import { Subscription, Subject,throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-trading-partnership-modal',
  templateUrl: './trading-partnership-modal.component.html',
})
export class TradingPartnershipModalComponent {
  tradingPartnershipForm: FormGroup;
  tradingPartnership: ReferencedTradingPartnership;
  tradingPartnershipId: TradingPartnership['_id'];
  tradingPartnershipSubscription: Subscription;
  canEdit: boolean = true; // TODO TEMP
  destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private navParams: NavParams,
    private tradingPartnershipService: TradingPartnershipService,
  ) {
    this.tradingPartnershipForm = this.formBuilder.group({
      supplierLocationCode: ['', Validators.required],
      supplierAccountCode: ['', Validators.required],
      buyerLocationCode: ['', Validators.required],
      buyerAccountCode: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.tradingPartnershipId = this.navParams.get('tradingPartnershipId');

    // TODO Get user privilege

    // Get trading partnership details
    if (this.tradingPartnershipId) {
      this.tradingPartnershipService
        .listReferencedTradingPartnerships(this.tradingPartnershipId)
        .pipe(
          takeUntil(this.destroy$),
          catchError((error) => {
            return throwError(() => new Error(`Error fetching trading partnership data: ${error.message}`));
          })
        )
        .subscribe(
          (data) => {
            this.tradingPartnership = data.records[0];

            // Set field values
            this.tradingPartnershipForm.patchValue({
              supplierLocationCode: this.tradingPartnership.autofillData.supplierLocationCode,
              supplierAccountCode: this.tradingPartnership.autofillData.supplierAccountCode,
              buyerLocationCode: this.tradingPartnership.autofillData.buyerLocationCode,
              buyerAccountCode: this.tradingPartnership.autofillData.buyerAccountCode
            });         
          },
          (error) => {
            throw error;
          }          
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async save() {
    if (this.canEdit && this.tradingPartnershipForm.valid) {
      const formData = this.tradingPartnershipForm.value;
      const updatedAutofillData = {
        ...this.tradingPartnership.autofillData, 
        formData
      };
      const updatedTradingPartnership = {
        ...this.tradingPartnership,
        updatedAutofillData
      }

      // TODO update trading partnership endpoint in trading partnership service
      console.log(updatedTradingPartnership); // TEMP

      // this.tradingPartnershipService.updateTradingPartnership(updatedTradingPartnership)
      //   .pipe(
      //     takeUntil(this.destroy$),
      //     catchError((error) => {
      //       return throwError(() => new Error(`Error updating trading partnership: ${error.message}`));
      //     })
      //   )
      //   .subscribe({
      //     next: (response) => {
      //       console.log('Trading partnership updated successfully', response);
      //       this.modalController.dismiss();
      //     },
      //     error: (error) => {
      //       throw error;
      //     }
      //   }
      // );
    }
  }

  async discard() {
    if (this.canEdit) {
      this.tradingPartnershipForm.reset();
    }
  }
}
