import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy{

  @Input() tooltip = ''; // Text for the tooltip
  @Input() delay? = 100; // Optional delay in ms
  @Input() position? = 'bottom'; // Default position

  private myPopup: HTMLElement;
  private timer: any;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove() }
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.timer = setTimeout(() => {
      const rect = this.el.nativeElement.getBoundingClientRect();
      let x = rect.left + rect.width / 2;
      let y = rect.top + rect.height + 6;

      // Adjust the x, y coordinates based on the position
      switch (this.position) {
        case 'right':
          x = rect.right + 6;
          y = rect.top + 6;
          break;
        case 'left':
          x = rect.left - 6;
          y = rect.top + 6;
          break;
        case 'top':
          x = rect.left + rect.width / 2;
          y = rect.top - 6;
          break;
      }

      this.createTooltipPopup(x, y, rect);
    }, this.delay);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) { this.myPopup.remove() }
  }

  private createTooltipPopup(x: number, y: number, rect: DOMRect) {
    let popup = document.createElement('div');
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltip-container");

    // Set initial position
    popup.style.top = `${y}px`;
    popup.style.left = `${x}px`;

    /*
    if(this.position == "left"){
      popup.style.left = (x - (4 * this.tooltip.length)).toString() + "px";
    } else if(this.position == "top"){
      popup.style.top = (y - 28).toString() + "px";
    }
    */

    document.body.appendChild(popup);
    this.myPopup = popup;

    // Adjust tooltip position if it overflows the screen boundaries
    this.adjustTooltipPosition(popup, rect);
  }

  private adjustTooltipPosition(popup: HTMLElement, rect: DOMRect) {
    const tooltipRect = popup.getBoundingClientRect();

    // Check for horizontal overflow
    if (tooltipRect.right > window.innerWidth) {
      let overLength = tooltipRect.right - window.innerWidth;
      popup.style.left = `${popup.offsetLeft - overLength - 2}px`; // Shift left
    } else if (tooltipRect.left < 0) {
      if(this.position == "bottom" || this.position == "top"){
        popup.style.left = `${(tooltipRect.width / 2) + 10}px`; // Shift right
      }else{
        popup.style.left = `5px`; // Shift right
      }
    }

    // Check for vertical overflow
    if (tooltipRect.bottom > window.innerHeight) {
      let overHeight = (rect.top + tooltipRect.height) - window.innerHeight;
      popup.style.top = `${popup.offsetTop - overHeight - 2}px`; // Move above the element
    } else if (tooltipRect.top < 0) {
        popup.style.top = `${rect.bottom + 10}px`; // Move below the element
    }
  }
}
