// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item .filter-pop-over-icon {
  margin-right: 9px !important;
  margin-left: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/action-bar/filter-action-popover/filter-action-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,4BAAA;AACF","sourcesContent":["ion-item .filter-pop-over-icon {\n  margin-right: 9px !important;\n  margin-left: auto !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
