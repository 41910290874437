import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionBarService, AuthService, FilterService, HelperService, TableService } from 'core/services';
import { Action, ActionButton, BinaryActions } from 'app/shared/common/actions';
import * as PrivilegeHelpers from 'app/shared/common/modules/privilege-helpers';
import { PopoverController } from '@ionic/angular';
import { FilterActionPopoverComponent } from './filter-action-popover/filter-action-popover.component';
import { CompanyReference } from 'app/shared/common/interfaces/company';
import { UiService } from 'app/ui/ui.service';
import { CompanyService } from 'app/company/company.service';
import { Router } from '@angular/router';
import { MetadataModalService } from 'app/shared/modals/metadata-modal/metadata-modal.service';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit, OnDestroy {
  renderButtonsSubscription: Subscription;
  selectModeEventSubscription: Subscription;
  roleMsg='';
  selectMode = false ;
  uiRenderSubscription : Subscription ;

  constructor(public authService: AuthService, public actionBarService: ActionBarService,public popoverController: PopoverController,
    public filterService:FilterService, public tableService:TableService,  public uiService:UiService, public companyService: CompanyService,
    public router:Router, public helperService:HelperService, public metadataModalService: MetadataModalService) {

  }

  public showBackButton = true; // TODO: create a more sophisticated method to determine if back button is visible
  // TODO: add unarchive. See template for icon name. asana - https://app.asana.com/0/1206102317277857/1206689932981170/f
  public actionButtons = [ //Index must match with (/shared/common/action.ts) enum index to works.
    {
      icon: 'file-tray-full',
      name: 'ARCHIVE',
      visible: false,
      action: Action.archive,
      backgroundColor: 'inherit'
    },
    {
      icon: 'grid',
      name: 'SELECTMODE',
      visible: false,
      action: Action.selectMode,
      backgroundColor: 'inherit'
    },
    {
      icon: 'download',
      name: 'EXPORT',
      visible: false,
      action: Action.export,
      backgroundColor: 'inherit'
    },
    {
      icon: 'print',
      name: 'PRINT',
      visible: false,
      action: Action.print,
      backgroundColor: 'inherit'
    },
    {
      icon: 'copy',
      name: 'COPY',
      visible: false,
      action: Action.duplicate,
      backgroundColor: 'inherit'
    },
    {
      icon: 'create', 
      name: 'CHANGESTATUS',
      visible: false,
      action: Action.changeStatus,
      backgroundColor: 'inherit'
    },
    {
      icon: 'lock-closed',
      name: 'LOCK',
      visible: false,
      action: Action.lock,
      backgroundColor: 'inherit'
    },
    {
      icon: 'trash',
      name: 'DELETE',
      visible: false,
      action: Action.delete,
      backgroundColor: 'inherit'
    },
    {
      icon: 'mail-open',
      name: 'OPEN',
      visible: false,
      action: Action.open,
      backgroundColor: 'inherit'
    },
    {
      icon: 'add', //CREATE BUTTON
      name: 'CREATE',
      visible: false,
      action: Action.create,
      backgroundColor: 'inherit'
    },
    {
      icon: 'pencil',
      name: 'EDIT',
      visible: false,
      action: Action.edit,
      backgroundColor: 'inherit'
    },
    {
      icon: 'save',
      name: 'SAVE',
      visible: false,
      action: Action.save,
      backgroundColor: 'inherit'
    },
    {
      icon: 'send',
      name: 'RELEASE',
      visible: false,
      action: Action.release,
      backgroundColor: 'inherit'
    },
    {
      icon: 'search',
      name: 'SEARCH',
      visible: false,
      action: Action.search,
      backgroundColor: 'inherit'
    },
    {
      icon: 'ellipsis-vertical',
      name: 'ELLIPSIS',
      visible: false,
      action: Action.ellipsis,
      backgroundColor: 'inherit'
    },
    {
      icon: 'funnel',
      name : 'FILTER',
      visible:false,
      action: Action.filter,
      backgroundColor: 'inherit'
    },
    {
      icon: 'receipt-outline',
      name: 'TRANSACTION',
      visible:false,
      action:Action.transaction,
      backgroundColor: 'inherit'
    },
    {
      icon: 'information-circle-outline',
      name: 'METADATA',
      visible:false,
      action: Action.metadata,
      backgroundColor: 'inherit'
    },
  ];

  ngOnInit() {
    this.selectModeEventSubscription = this.tableService.selectModeSubscription.subscribe(selectMode=>{
      this.selectMode = selectMode; 
      if(this.selectMode == true){
        this.actionButtons.find(button=>button.action == Action.selectMode).backgroundColor='#1c4d67';
      }else{
        this.actionButtons.find(button=>button.action == Action.selectMode).backgroundColor='inherit';
      }
    });
    //will be called each time nav change
    this.renderButtonsSubscription = this.actionBarService.renderButtonsSubscription.subscribe(binaryActions => {
      /* loop over action buttons and decide if they should be visible */
      let companyReference : CompanyReference; 
      this.companyService.getCompanyReferences().subscribe(companies=>{
        companyReference = companies.find(company => company.companyId == this.companyService.activeCompany.companyId );
        this.actionButtons.forEach(item => {
          const actionName = Action[item.action] as keyof typeof BinaryActions;
          if (PrivilegeHelpers.contains(binaryActions, actionName) && (!companyReference.disabled||(this.authService.isSuperAdmin())) ) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      });
    });
    
  }

  ngOnDestroy(): void {
    this.renderButtonsSubscription.unsubscribe();
    this.selectModeEventSubscription.unsubscribe();
  }

  onClick( button:ActionButton , $event:Event) {
    this.actionBarService.buttonEvent( button.action );
    //hard code create action here because it is linked to action bar layout
    this.presentPopover( button.name, $event) ;
  }

  onBack() {
    this.actionBarService.backButtonEvent();
  }
  
  //present a drop down for fitler option in action bar
  async presentFilterActionPopover($event) {
    const popover = await this.popoverController.create({
      component: FilterActionPopoverComponent,
      event:$event,
      componentProps:{}
    });
    await popover.present();
    const {} = await popover.onDidDismiss();
  }

  presentPopover(name:string, $event:Event){
    switch(name){
      case 'FILTER':
        this.presentFilterActionPopover($event);
        break;
      default:
        break;
    }
  }

}
