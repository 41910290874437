import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, Subject } from 'rxjs';

import { User } from 'common/interfaces/user';
import { UserPrivilege } from 'common/interfaces/privileges';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { SelectValue, Ui } from 'app/shared/common/interfaces/ui';
import { CompanyReference } from 'app/shared/common/interfaces/company';
import { NonDocumentRecordType } from 'app/shared/common/interfaces/recordTypes';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  // needed when working with the users as admin/superadmin
  private _openUser: User;

  constructor(private http: HttpClient) { }

  getUsers(companyId: string, recordPageRequest: RecordPageRequest): Observable<{records:User[]; total: number;}> {
    return this.http.post<{records:User[]; total: number;}>(`user/list`, {companyId, recordPageRequest});
  }
  
  getUser(id: string): Observable<User> {
    return this.http.get<User>(`user/${id}`);
  }

  // POST requests use responseType 'text' as thr server doesn't return JSON. Leaving this option out will causes a client side error
  updateUser(user: User) {// , userPrivileges: UserPrivilege[]) {
    // TODO: add userPrivileges object to POST body
    return this.http.put('user', {user}, {responseType: 'text'});
  }

  createUser(user: User, userPrivileges: UserPrivilege[]) {
    return this.http.post('user', {user, userPrivileges}, {responseType: 'text'});
  }

  deleteUsers(userIds: string[]) {
    return this.http.delete('user', { body: { userIds }, responseType: 'text' });
  }
  
  get openUser() {
    return this._openUser;
  }

  set openUser(user: User) {
    this._openUser = user;
  }

  ui:Omit<Ui,'_id'> = { // user ui
    uiTabs: [
      {
        name: 'credentials',
        panel: {
          title: 'CREDENTIALS',
          description: 'CREDENTIALSDESCRIPTION',
          icon: 'person-circle-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'contacts',
            label: 'CONTACTS',
            groupColumnSize: 1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'email',
                label: 'EMAIL',
                type: 'input',
                inputType: 'email',
                fieldColumnSize: 1,
                validators:[
                  {
                    name: 'email_pattern',
                    errorType: 'pattern',
                    message: 'Email pattern use default validator which has mandatory errorType'
                  },
                  {
                    name: 'required',
                    errorType: 'required',
                    message: 'Email is required'
                  }
                ]
              },
              {
                name: 'changePwBtn',
                label: 'CHANGEPASSWORD',
                type: 'button',
                onPress$: new Subject<void>(),
                fieldColumnSize: 1,
              }
            ]
          },
          {
            name: 'personal-information',
            label: 'PERSONALINFORMATION',
            groupColumnSize: 1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'firstName',
                label: 'FIRSTNAME',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'FIRSTNAMEREQUIRED'},
                ]
              },
              {
                name: 'lastName',
                label: 'LASTNAME',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'LASTNAMEREQUIRED'},
                ]
              },
            ]
          }
        ]
      },
      {
        name: 'settings',
        panel: {
          title: 'USERSETTINGS',
          description: 'USERSETTINGSDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'date-and-time',
            label: 'DATEANDTIME',
            groupColumnSize: 1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'dateFormat',
                label: 'DATEFORMAT',
                type: 'select',
                options: [],
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'DATEFORMATREQUIRED'},
                ]
              },
              {
                name: 'timezone',
                label: 'TIMEZONE',
                type: 'select',
                options:[],
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'TIMEZONEREQUIRED'},
                ]
              }
            ]
          },
          {
            name: 'identity-management',
            label: 'IDENTITYMANAGEMENT',
            groupColumnSize: 1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'accountType',
                label: 'ACCOUNTTYPE',
                type: 'select',
                options: [],
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'ACCOUNTTYPEREQUIRED'},
                ]
              },
              {
                name: 'brands',
                label: 'BRANDS',
                type: 'multiselect',
                options: [],
                fieldColumnSize: 1,
              },
              {
                name: 'addUser',
                label: 'ADDUSER',
                type: 'button',
                onPress$: new Subject<void>(),
                fieldColumnSize: 1,
              }
            ]
          },
          {
            name: 'theme',
            label: 'THEME',
            groupColumnSize: 1,
            fieldMaxColumnSize: 1,
            fields: [
              {
                name: 'darkmode',
                label: 'DARKMODE',
                type: 'toggle',
                fieldColumnSize: 1,
              }
            ]
          }
        ]
      },
      {
        name: 'Companies',
        panel: {
          title: 'COMPANIES',
          description: 'COMPANIESDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1, //TODO: layout improvement
        fieldGroups: [
          {
            name: 'administration',
            label: 'ADMINISTRATION',
            groupColumnSize:1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'tenantName',
                label: 'COMPANIES',
                type: 'autocomplete',
                selectedRecord$: new ReplaySubject<SelectValue>(1), // store the most recent emitted value; the '1' indicates it captures only the last emitted value
                availableRecords$: new ReplaySubject<SelectValue[]>(1), 
                fieldColumnSize: 1,
                availableRecordsRecordType: NonDocumentRecordType.Company
              },
              {
                name: 'addCompany',
                label: 'ADDCOMPANY2',
                type: 'button',
                onPress$: new Subject<void>(),
                fieldColumnSize: 1,
              },
              {
                name: 'companies2',
                label: 'COMPANIES2',
                type: 'companyList',
                companies$: new Observable<CompanyReference[]>(),
                allowDetailsView: true,
                fieldColumnSize: 1,
              }
            ]
          }
        ]
      }
    ],
    metadataUiData: [
      {
        name: 'createdBy',
        label: 'CREATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 12,
        readonly: true
      },
      {
        name: 'createdDate',
        label: 'CREATEDDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 12,
        readonly: true
      },
      {
        name: 'updatedBy',
        label: 'UPDATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 12,
        readonly: true
      },
      {
        name: 'updateDate',
        label: 'UPDATEDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 12,
        readonly: true
      }
    ]
  }
}
