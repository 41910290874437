// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointerLink {
  cursor: pointer;
  z-index: 9999;
}

#language {
  text-align: left;
}

#language a {
  cursor: pointer;
  margin-left: 15px;
  margin-right: 10px;
}

ion-select {
  padding-left: 12px;
}

#add-company {
  padding-top: 26px;
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.horizontal-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

form * {
  width: 100%;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --background: var(--ion-color-primary);
  color: white;
}

ion-item.selected ion-icon {
  --background: var(--ion-color-primary);
  color: white;
}

ion-item {
  --inner-padding-end: 0px !important;
}

.company-selection-autocomplete {
  max-height: 80px;
}

.sidenav-header {
  font-size: small;
  margin-bottom: 10px;
}

.sidenav-header-logo {
  text-align: center;
}

.sidenav-header-logo img {
  height: 35px;
}

.sidenav-toolbar ion-col {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/sidenav/sidenav.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAMA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;AAHJ;;AAMA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAHJ;;AAMA;EACI,WAAA;AAHJ;;AAYA;EACI,qBAAA;EACA,eAAA;EAEA,oCAAA;AAVJ;;AAaA;EACI,sCAAA;EACA,YAAA;AAVJ;;AAaA;EACI,sCAAA;EACA,YAAA;AAVJ;;AAaA;EACI,mCAAA;AAVJ;;AAaA;EACI,gBAAA;AAVJ;;AAaA;EACI,gBAAA;EACA,mBAAA;AAVJ;;AAaA;EACI,kBAAA;AAVJ;;AAaA;EACI,YAAA;AAVJ;;AAaA;EACI,kBAAA;AAVJ","sourcesContent":[".pointerLink {\n    cursor: pointer;\n    z-index: 9999;\n}\n\n#language {\n    text-align: left;\n}\n\n#language a {\n    cursor: pointer;\n    margin-left: 15px;\n    margin-right: 10px;\n}\n\nion-select {\n    padding-left: 12px;\n}\n\n#add-company {\n    padding-top: 26px;\n}\n\n#user * {\n    // margin-top: 7px;\n}\n\n.vertical-align {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.horizontal-align {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\nform * {\n    width: 100%;\n}\n\n.view-company {\n    // margin-top: -20px;\n    // margin-left: 10px;\n    // margin-bottom: 15px;\n}\n\nion-note {\n    display: inline-block;\n    font-size: 16px;\n\n    color: var(--ion-color-medium-shade);\n}\n\nion-item.selected {\n    --background: var(--ion-color-primary);\n    color: white;\n}\n\nion-item.selected ion-icon {\n    --background: var(--ion-color-primary);\n    color: white;\n}\n\nion-item {\n    --inner-padding-end: 0px !important;\n}\n\n.company-selection-autocomplete {\n    max-height: 80px;\n}\n\n.sidenav-header {\n    font-size: small;\n    margin-bottom: 10px;\n}\n\n.sidenav-header-logo {\n    text-align: center;\n}\n\n.sidenav-header-logo img {\n    height: 35px;\n}\n\n.sidenav-toolbar ion-col {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
