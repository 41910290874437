import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first, takeUntil,} from 'rxjs/operators';
import { AuthService } from 'core/services';
import { ValidatorService } from 'app/core/services/validator.service';
import { FieldGroup, FormField, Ui, } from 'app/shared/common/interfaces/ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})

export class VerifyComponent implements OnInit {

  ui:Partial<Ui>;
  fieldGroups: FieldGroup<FormField>[];
  passwordFormGroup: FormGroup;
  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private validatorService : ValidatorService,
  ) {}

  ngOnInit() {

    this.fieldGroups = [ // render the verify ui
      {
        name: 'verifyFormUI',
        groupColumnSize:1,
        fieldMaxColumnSize:1,
        fields: [
          {
            name: 'email',
            label: 'EMAIL',
            type: 'input',
            inputType: 'email',
            placeholder: 'EMAIL',
            readonly:true,
            fieldColumnSize: 1,
          },
          {
            name: 'newPassword',
            label: 'NEWPASSWORD',
            type: 'input',
            inputType: 'password',
            placeholder: 'NEWPASSWORD',
            clearOnEdit:false,
            toggleIcon: {
              active: 'eye-off-outline',
              inactive: 'eye-outline',
              iconClicked: false,
            },
            fieldColumnSize: 1,
            validators: [
              {name:'required',errorType:'required',message:'Please Enter a Password'},
              {name:'minlength',errorType:'minlength', value:'2', message:`Must be at least 2 characters long`},
              ],
          },
          {
            name: 'repeatPassword',
            label: 'REPEATPASSWORD',
            type: 'input',
            inputType: 'password',
            placeholder: 'REPEATPASSWORD',
            clearOnEdit:true,
            toggleIcon: {
              active: 'eye-off-outline',
              inactive: 'eye-outline',
              iconClicked: false,
            },
            fieldColumnSize: 1,
            validators: [
              {name:'required',errorType:'required',message:'Please Enter a Password'},
            ],
          },
        ],
      },
    ];
    this.ui = {
      uiTabs: [{
        name: '',
        panel: null,
        groupMaxColumnSize:1,
        fieldGroups: this.fieldGroups
      }],
      formValidators: [{
        name: 'mustMatch',
        fields: ['newPassword', 'repeatPassword'],
        errorType: 'mustMatch',
        message: 'Passwords do not match',
      }],
    };
    this.passwordFormGroup = this.formBuilder.group({
      email: [{ value: '', disabled: true, }],
      newPassword: [''],
      repeatPassword: [''],
    });
    this.passwordFormGroup = this.validatorService.setValidatorFromUI(this.passwordFormGroup, this.ui);
  }

  ionViewWillEnter() { 
    const token = this.route.snapshot.queryParams.token; // grab the JSON web token from URL
    if (!token) {
      return;
    } 
    this.authService.isUserRegistered(token).pipe(
      takeUntil(this.destroy$),
      catchError((err) => {
        this.router.navigate(['/login']);
        throw err;
      })
    ).subscribe((res) => {
        if (res.registered === true) {
          this.router.navigate(['/login']);
        } else {
          this.passwordFormGroup.get('email').setValue(res.email);
        }
    },
  );
  }
  onSubmit() {  // requires password and token for verification
    if (this.passwordFormGroup.invalid) {
      this.passwordFormGroup.markAllAsTouched();
      return;
    }
    const token = this.route.snapshot.queryParams.token;
    this.authService
      .verifySignupAccount(token, this.passwordFormGroup.value)
      .pipe(first(),takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigate(['/login']);
        },
        error: (err) => {
          // TODO: We need to have a popup/validation sweep to show all the correct errors
          return err;
        },
      });
  }

  ionViewWillLeave() {
    this.passwordFormGroup.reset();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
