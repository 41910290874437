import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from 'app/company/company.service';
import { AutofillSetting} from 'app/shared/common/interfaces/global';
import { Document } from 'app/shared/common/interfaces/document';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


/*Method exclusive for document record. No document rules will be created for non document record.
  Input - Document id, null if creating blank document (selected)
        - Document Rule, target document document type (appliedRule)
        - Price List, TradingPartner ship, for enrich 
  
  Output - AutofillDocument
*/

export class AutofillService {
  public autofillDocument$ = new Subject<Document>() ; //output for subscription

  public autofillSetting$ = new BehaviorSubject <AutofillSetting>(null); 
  public set autofillSetting( autofillSetting : AutofillSetting) {
    this.autofillSetting$.next( autofillSetting);
  }

  constructor(  private http:HttpClient, private companyService: CompanyService ) { }

  //emit filled document value to a ReplaySubject, return the ReplaySubject for subscription on value
  getAutofilledDocument( ): Subject<Document> {

    this.autofillSetting$.pipe(

      switchMap( autofillSetting =>{ 
        return this.autofillDocument( autofillSetting ) 
      })

    ).subscribe( document =>{ //emits like a replay subject instead
        this.autofillDocument$.next(document);
    });

    return this.autofillDocument$ ; //return a subject for subscribing
  }

  autofillDocument( autofillSetting: AutofillSetting ) : Observable<Document>{
    let url = `autofill/${autofillSetting.isChildDocument}/${autofillSetting.targetRecordType}/${autofillSetting.tradingPartnershipId}/${this.companyService.activeCompany.companyId}` ;
    if(!autofillSetting.isChildDocument){
      return this.http.get<Document>(url);
    }
    if( !(autofillSetting.selectedDocumentId??false) || autofillSetting.selectedDocumentId?.length == 0 ){ //add selectedDocumentId if not null or undefined
      throw new Error ('a child must require one or more parent document');
    }
    let selectedDocumentIdString = this.idArrayToString(autofillSetting.selectedDocumentId);
    url = url + `/${selectedDocumentIdString}` ;
    return this.http.get<Document>(url);
  }

  idArrayToString ( idArray:Array<string> ):string{
    try{
      idArray.forEach( id=>{
        parseInt(id); //make sure the id can be a number
      })
    }catch(error){
      throw error ;
    }
    return idArray.toString();
  }

}