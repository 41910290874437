import { CompanyReference } from '../interfaces/company';
import { SourceParty } from '../interfaces/documentRule';
import { DocumentRecordType } from '../interfaces/recordTypes';
import { TradingPartnership } from '../interfaces/tradingPartnership';



export function determineRecipientId(documentRecordType: DocumentRecordType, tradingparternship:TradingPartnership ):CompanyReference['companyId']{
    const documentRule = tradingparternship.documentRules.find(documentRule => documentRule.documentType === documentRecordType );
    if(documentRule === undefined){
        throw new Error ('Unexpected document record type, found during determineRecipientId.');
    }
    if (documentRule.sender === SourceParty.Buyer ) {
        return tradingparternship.supplierCompanyId;
    } 
    if (documentRule.sender === SourceParty.Supplier) {
        return tradingparternship.buyerCompanyId;
    }
    throw new Error ('Unexpected value for sender in document rule.')
}
