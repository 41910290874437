import { Injectable } from '@angular/core';
import { DocumentHistory, NestedFieldList } from 'app/shared/common/interfaces/document';
import { InvoiceTotals } from 'app/shared/common/interfaces/invoiceTotals';
import { PriceListProduct } from 'app/shared/common/interfaces/priceList';
import { FormAutocompleteField, FormButtonField, FormCompanyListField, DatetimeField, DatabaseField, HeaderField, InputField, FormLineItemsField, MultiDatetimeField, MultiselectField, SelectField, SelectValue, ToggleField, FormField, UiTab, UrlField, FormVatSummaryTableField, FormInvoiceTotalsField, FormListField } from 'app/shared/common/interfaces/ui';
import { VatBreakdownItem } from 'app/shared/common/interfaces/vatBreakdown';
import { EnrichUiObservable, ObservableItem } from 'app/shared/interfaces/observable-item';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/* Used to convert a UI object from Database Fields to Form Fields */
/* If an observable ID is shared between fields then the same observable is used */
export class EnrichUiService {
  observableItems: ObservableItem[]

  constructor( ) {
    this.observableItems = [];
  }

  /* Loop over each field and enrich with observables */
  enrichUiTabs(uiTabs: UiTab[]): UiTab[] {
    uiTabs = JSON.parse(JSON.stringify(uiTabs));
    for (let uiData of uiTabs) {
      for (let fieldGroup of uiData.fieldGroups) {
        fieldGroup.fields = fieldGroup.fields.map(field => this.enrichField(field as DatabaseField));
      }
    }
    return uiTabs;
  } 

  /* Enriches a field with observables based on field type */
  enrichField(databaseField: DatabaseField): FormField {
    let formField: FormField;
    switch (databaseField.type) {
      case 'input':
        formField = {
          type: 'input',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          readonly: databaseField.readonly,
          inputType: databaseField.inputType,
          isDocumentNumber: databaseField.isDocumentNumber
        } as InputField;
        break;
      case 'toggle':
        formField = {
          type: 'toggle',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          readonly: databaseField.readonly
        } as ToggleField;
        break;
      case 'button':
        formField = {
          type: 'button',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          onPress$: this.createAndSaveObservable({
            observableId: databaseField.onPressObservableId,
            observable: new Subject<void>()
          })
        } as FormButtonField;
        break;
      case 'select':
        formField = {
          type: 'select',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          options: databaseField.options
        } as SelectField;
        break;
      case 'multiselect':
        formField = {
          type: 'multiselect',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          options: databaseField.options
        } as MultiselectField;
        break;
      case 'datetime':
        formField = {
          type: 'datetime',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          format: databaseField.format,
          readonly: databaseField.readonly
        } as DatetimeField;
        break;
      case 'url':
        formField = {
          type: 'url',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          link: databaseField.link
        } as UrlField;
        break;
      case 'list':
        formField = {
          type: 'list',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          properties: databaseField.properties,
          data$: this.createAndSaveObservable({
            observableId: databaseField.dataObservableId,
            observable:new Subject<DocumentHistory[]>()
          }),
        } as FormListField;
        break;
      case 'lineItems':
        formField = {
          type: 'lineItems',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          products$: this.createAndSaveObservable({
            observableId: databaseField.productsObservableId,
            observable: new Subject<Partial<PriceListProduct>[]>()
          }),
          lineItems$: this.createAndSaveObservable({
            observableId: databaseField.lineItemsObservableId,
            observable: new ReplaySubject<NestedFieldList[]>()
          })
        } as FormLineItemsField;
        break;
      case 'autocomplete':
        formField = {
          type: 'autocomplete',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          options: [],
          selectedRecord$: this.createAndSaveObservable({
            observableId: databaseField.selectedRecordObservableId,
            observable: new Subject<SelectValue>()
          }),
          selectedRecordRecordType: databaseField.selectedRecordRecordType,
          filteredRecords$: this.createAndSaveObservable({
            observableId: databaseField.filteredRecordsObservableId,
            observable: new Observable<SelectValue[]>()
          }),
          filteredRecordsRecordType: databaseField.filteredRecordsRecordType,
          availableRecords: [],
          availableRecords$: this.createAndSaveObservable({
            observableId: databaseField.availableRecordsObservableId,
            observable: new ReplaySubject<SelectValue[]>()
          }),
          availableRecordsRecordType: databaseField.availableRecordsRecordType,
        } as FormAutocompleteField;
        break;
      case 'companyList':
        formField = {
          type: 'companyList',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          companies$: this.createAndSaveObservable({
            observableId: databaseField.companiesObservableId,
            observable: new ReplaySubject<SelectValue[]>()
          }),
          allowDetailsView: databaseField.allowDetailsView
        } as FormCompanyListField;
        break;
      case 'vatSummaryTable':
        formField = {
          type: 'vatSummaryTable',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          lineItems$: this.createAndSaveObservable({
            observableId: databaseField.lineItemsObservableId,
            observable: new ReplaySubject<NestedFieldList[]>()
          }),
          surcharge$: this.createAndSaveObservable({
            observableId: databaseField.surchargeObservableId,
            observable: new BehaviorSubject<number>(0)
          }),
          allowDetailsView: databaseField.allowDetailsView,
          vatBreakdown$: new Subject<VatBreakdownItem[]>() // not using createAndSaveObservable because this is never shared
        } as FormVatSummaryTableField;
        break;
      case 'header':
        formField = {
          type: 'header',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
        } as HeaderField;
        break;
      case 'invoiceTotals':
        formField = {
          type: 'invoiceTotals',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          lineItems$: this.createAndSaveObservable({
            observableId: databaseField.lineItemsObservableId,
            observable: new ReplaySubject<NestedFieldList[]>()
          }),
          surcharge$: this.createAndSaveObservable({
            observableId: databaseField.surchargeObservableId,
            observable: new BehaviorSubject<number>(0)
          }),
          vatBreakdown$: new Subject<InvoiceTotals[]>()
        } as FormInvoiceTotalsField;
        break;
      case 'multiDatetime':
        formField = {
          type: 'multiDatetime',
          name: databaseField.name,
          label: databaseField.label,
          text: databaseField.text,
          placeholder: databaseField.placeholder,
          fieldColumnSize: databaseField.fieldColumnSize,
          range: databaseField.range,
          readonly: databaseField.readonly
        } as MultiDatetimeField;
        break;
    }
    return formField;
  }

  /* Creates observable and saves it to an array with a key to avoid duplicates */
  createAndSaveObservable(inputObservableItem: ObservableItem): EnrichUiObservable {
    let matchedObservableItem = this.observableItems.find((observableItem) => {
      return inputObservableItem.observableId == observableItem.observableId;
    });
    if (!matchedObservableItem) {
      this.observableItems.push(inputObservableItem);
      return inputObservableItem.observable;
    }
    // TODO throw error if observableItem objects are not identical; or simply filteredRecordsRecordType is different?
    return matchedObservableItem.observable;
  }
}