import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IonicModule } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';

import { CoreModule } from 'core/core.module';
import { ResetPasswordComponent } from 'shared/modals';
import { AccountActivationModalPage } from 'shared/modals';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { FilterActionPopoverComponent } from './action-bar/filter-action-popover/filter-action-popover.component';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { HammerModule } from "../../node_modules/@angular/platform-browser";

export function createTranslateLoader(http: HttpClient) {
    const path = '../assets/i18n/';
    return new TranslateHttpLoader(http, path, '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ActionBarComponent,
        FilterActionPopoverComponent,
        SidenavComponent,
        ResetPasswordComponent,
        AccountActivationModalPage,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        BrowserAnimationsModule,
        MatExpansionModule,
        MatFormFieldModule,
        CoreModule,
        AuthModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        CommonModule,
        AppRoutingModule,
        SharedModule,
        MaterialModule,
        DirectivesModule,
        HammerModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
