// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emailAlertTable {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/email-alert-action-modal/email-alert-action-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ","sourcesContent":[".emailAlertTable{\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
