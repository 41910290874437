import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TradingPartnership, TradingPartnershipSelector } from 'app/shared/common/interfaces/tradingPartnership';
import { Subject } from 'rxjs';
import { TradingPartnershipModalComponent } from 'app/shared/modals/trading-partnership-modal/trading-partnership-modal.component';

@Component({
  selector: 'trading-partner-selector',
  templateUrl: './trading-partner-selector.component.html',
  styleUrls: ['./trading-partner-selector.component.scss'],
})
export class TradingPartnerSelectorComponent {
  destroy$ = new Subject<void>();

  constructor(private modalController: ModalController) { }

  @Input() layout: 'hscroll' | 'vscroll';
  @Input() action: 'filter' | 'select' | 'modal';
  @Input() outlook: 'highlightStatus' | 'settings';

  @Input() tradingPartnershipSelectors: TradingPartnershipSelector[] = [];

  @Output() tradingPartnershipsSelected: EventEmitter<TradingPartnership['_id'] | TradingPartnershipSelector[]> =
    new EventEmitter<TradingPartnership['_id'] | TradingPartnershipSelector[]>();

  ngOnInit() { }

  ngAfterViewInit() {
    // Handle mouse wheel scroll - Convert to horizontal movement
    this.enableHorizontalMouseScroll('.trading-partner-selector-container');
  }

  chipClickActionHandler(tradingPartnershipSelector: TradingPartnershipSelector) {
    if (tradingPartnershipSelector.disabled) return;

    switch (this.action) {
      case 'filter':
        this.toggleSelection(tradingPartnershipSelector);
        break;
      case 'modal':
        this.openTradingPartnershipModal(tradingPartnershipSelector._id);
        break;
      default:
        this.tradingPartnershipsSelected.emit(tradingPartnershipSelector._id);
        break;
    }  
  }

  enableHorizontalMouseScroll(cssQuery: string): void {
    const element = document.querySelector(cssQuery);

    element.addEventListener('wheel', (event: any) => {
      event.preventDefault();

      element.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    }); 
  }

  async openTradingPartnershipModal(tradingPartnershipId: TradingPartnership['_id']) {
    const modal = await this.modalController.create({
      component: TradingPartnershipModalComponent,
      componentProps: { tradingPartnershipId }
    });
    
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        console.log('Saved Data:', data.data);
        // Handle the saved data
      }
    });

    return await modal.present();
  }

  toggleSelection(tradingPartnershipSelectorSelected: TradingPartnershipSelector): void {
    const updatedTradingPartnershipSelectors = 
      this.tradingPartnershipSelectors.map(tradingPartnershipSelector => {
        if (tradingPartnershipSelector === tradingPartnershipSelectorSelected) {
          const selectionState = tradingPartnershipSelector.selected ? false : true;
          return { ...tradingPartnershipSelector, selected: selectionState };
        }
        return tradingPartnershipSelector;
      });
      
    this.tradingPartnershipSelectors = updatedTradingPartnershipSelectors;
    this.tradingPartnershipsSelected.emit(updatedTradingPartnershipSelectors);
  }
}
