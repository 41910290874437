import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Asset } from 'app/shared/common/interfaces/asset';

@Injectable({
    providedIn: 'root'
  })
  export class AssetsService { 

    constructor(private http: HttpClient) { }

    getAsset(assetId: Asset['_id']): Observable<Asset> {
        return this.http.get<Asset>(`asset/${assetId}`);
    }

    getAssets(assetIds: Asset['_id'][]): Observable<{records: Asset[]; total: number}> {
        return this.http.post<{records: Asset[]; total: number}>('asset/list/', { assetIds} );
    }

    createAsset(asset: Asset) {
        return this.http.post('asset', { asset }, { responseType: 'text' });
    }   
    
    updateAsset(asset: Asset) {
        return this.http.put('asset', { location: asset }, { responseType: 'text' });
    }
    
    deleteAsset(assetIds: string[]) {
        return this.http.delete('asset', { body: { locationIds: assetIds }, responseType: 'text' });
    }   
  }