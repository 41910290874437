import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormValidationOption, Ui, ValidationOption } from 'app/shared/common/interfaces/ui';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  /**
   * Generate the pattern of Validators for email checking
   *
   * @returns Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
   */
  emailPattern(): ValidatorFn{
    return Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
  }

  /**
   * Checks that two form fields hold the same value, set second control errors if invalid
   *
   * @param controlName First form property to compare
   * @param matchingControlName Second form property to compare
   * @returns Angular Forms Abstract Control
  */
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup): AbstractControl => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return; //if another validator has already found an error on the matchingControl
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * pass validator from ui to form
   *
   * @param formGroup to add validator
   *
   * @param ui whole ui object, from top level {uiTabs:Array<any>,formValidators:Array<any>}
   *
   * @returns formGroup with validator added
   */
  setValidatorFromUI(formGroup: FormGroup, ui: Partial<Ui>): FormGroup {
    //field level
    let outputForm = formGroup ;
    ui.uiTabs.forEach( ui =>{
      ui.fieldGroups.forEach( fieldGroup =>{
        fieldGroup.fields.forEach( field =>{
          //set in the field validator
          if(field.validators){
            let validators = [];
            field.validators.forEach( validator =>{
              validators.push(this.getValidatorFunction(validator));
            });

            outputForm.controls[field.name].addValidators(validators);
            outputForm.controls[field.name].updateValueAndValidity();
          }
        })
      })
    });
    //form level
    let formValidators = [];
    ui.formValidators?.forEach(validator=>{
      formValidators.push(this.getFormValidatorFunction(validator));
    });

    outputForm = new FormGroup( outputForm.controls, {validators: formValidators}) ;

    return outputForm  ;
  }

  //predefined validator are expect to have **same name and errorType** or else missmatch and not loading the message
  getValidatorFunction ( validationOption :ValidationOption):ValidatorFn{
    try{
      let value = validationOption.value;
      //let type = validationOption.errorType;
      switch(validationOption.name){
        case 'required':
          return Validators.required;
        case 'email':
          return Validators.email;
        case 'email_pattern':
          return this.emailPattern();
        case 'minlength':
          return Validators.minLength(Number(value));
        case 'maxlength':
          return Validators.maxLength(Number(value));
        case 'min':
          return Validators.min(Number(value));
        case 'max':
          return Validators.max(Number(value));
        case 'pattern':
          return Validators.pattern(value);
        //commented for future custom validator implementation
        //case 'custom':
        //  return (this.customValidator(value,type));//value - TODO : id reference local custom validator ts file / type - customErrorType
        default :
          throwError(Error);
          break;
      }
    }catch(error){
      throw new Error(error); //handle error of unexpected validator type
    }
  }

  getFormValidatorFunction ( formValidationOption:FormValidationOption):ValidatorFn{
    try{
      switch(formValidationOption.name){
        case'mustMatch':
          return this.mustMatch(formValidationOption.fields[0],formValidationOption.fields[1]);
        // commented for future custom validator implementation
        // case'custom':
        //   return this.customFormValidator(formValidationOption);
        case'default':
          break
      }
    }catch(error){
      throwError(error); //handle error of unexpected validator type
    }
  }

  /**
 * input a function and error type, return a custom validatorFn
 *
 * @param functionString String of function expression that validate value
 * expected in format of (function (value){ ... return x ?true:false;})
 *
 * @param errorType custom defined errorType from ValidationOption
 *
 * @error eval() a function string failed
 *
 * @returns a ValidatorFn, (a:AbstractControl):ValidationErrors|null
 *
 */
//commented for future custom validator implementation
/*
  customValidator(functionString:string,errorType:string) : ValidatorFn{
    try{
      //ValidationErros is used by formControl, formControl.hasError check a string against this object key
      return (control:FormControl) : ValidationErrors|null  => {
        try{

        let customFunction:(value:any)=>boolean  = eval(functionString) ;
        let isValid = customFunction(control.value) ; //MUST valid=true / invalid=false

        return isValid?null:{[errorType]:true};
        }catch(error){
          throw new Error('custom function string syntax error, unable to eval on string \n'+functionString+'\n with value of '+control.value);
        }
      }
    }catch(error){
      throw new Error ('error interperting custom field validator');
      //return error ; //handle invalid function string causing all sort of unexpected error
    }
  }
  */

  /**
   * input a function and error type, return a custom validatorFn
   *
   * @param functionString String of function expression that validate formControl Array
   * expected in format of (function (controls){ ... return x ?true:false;})
   *
   * @param errorType custom defined errorType from ValidationOption
   *
   * @error eval() a function string failed
   *
   * @returns a ValidatorFn, (a:AbstractControl):ValidationErrors|null
   *
   */
  //commented for future custom validator implementation
  /*
  customFormValidator(formValidationOption:FormValidationOption): ValidatorFn {
    try{
      return (formGroup: FormGroup) : ValidationErrors|null => {

        let controlNames = formValidationOption.fields;
        let errorType = formValidationOption.errorType;
        let functionString = formValidationOption.value;//TODO: make it id that reference local validator file
        //extract control to validate
        let inputValues:Array<{name:string,value:any}> =[];
        controlNames.forEach(name=>{
          inputValues.push({ name: name, value: formGroup.controls[name].value});
        }) ;

        let customFunction:(inputValues:Array<{name:string,value:any}>)=>Array<{name:string,isValid:boolean}> = eval(functionString);

        let validationResults = customFunction(inputValues);

        //call function add validator to return an error on field level.
        let validForm = true ;
        validationResults.forEach(result=>{

          if(!result.isValid){
            validForm = false ; //once a field is invalid, form level invalid as well
          }
          let errorsToSet = formGroup.controls[result.name].errors;

          if(!result.isValid){
            errorsToSet = this.addErrors(errorsToSet,{[errorType]:true});

          }else{
            errorsToSet = this.removeErrors(errorsToSet,{[errorType]:true});
          }

          formGroup.controls[result.name].setErrors(errorsToSet);//set error "replace" error
        })

        return validForm?null:{[errorType]:true}; //form level error
      }
    }catch(error){
      throw new Error('error interperting custom form validator');
      //handle invalid function string causing all sort of unexpected error
    }
  }
  */

  /**
   * input a function and error type, return a custom validatorFn
   *
   * @param existingErrors errors property in form control
   *
   * @param additionalErrors errors property to be removed from form control
   *
   * @error either the errors removed or removed from is null or undefined
   *
   * @returns ValidationErrors|null
   *
   */
  //commented for future custom validation implmentation
  /*
  addErrors( existingErrors:ValidationErrors,additionalErrors:ValidationErrors ):ValidationErrors{
    return {
      ...(existingErrors||{}), //default {} for null or undefined
      ...additionalErrors,
    };
  }*/


  /**
   * input a function and error type, return a custom validatorFn
   *
   * @param existingErrors errors property in form control
   *
   * @param removingErrors errors property to be removed from form control
   *
   * @error either the errors removed or removed from is null or undefined
   *
   * @returns ValidationErrors|null
   *
   */
  //commented for future custom validator implementation
  /*
  removeErrors(  existingErrors:ValidationErrors, removingError:ValidationErrors  ):ValidationErrors|null{
    try{
      if(!(removingError??false) || (Object.keys(removingError).length)==0 ){
        throw Error('Invalid errors to remove, it should not be undefined or null.');
      }

      if(!(existingErrors??false) || (Object.keys(existingErrors).length)==0 ){
        return null; //there are nothing to remove from
      }

      delete existingErrors[Object.keys(removingError)[0]];

      return Object.keys(existingErrors).length>0?existingErrors:null;//if the out come is empty errors, its a null
    }catch(error){
      throw new Error (error);
    }
  }
  */

}
