// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  margin-left: 5px;
  display: flex;
}

.selected {
  background-color: rgb(177, 177, 177);
}

ion-item::part(native) {
  padding: 0;
}

ion-card {
  margin-bottom: 5px;
  margin-top: 3px;
}

.mobile-label {
  display: flex;
  font-display: column;
}

::ng-deep .ngx-datatable.material {
  box-shadow: none;
}

::ng-deep .ngx-datatable.material .datatable-header .datatable-header-cell {
  font-size: 14px;
}

::ng-deep .ngx-datatable.material .datatable-footer ion-col {
  display: flex;
  align-items: center;
}

::ng-deep .ngx-datatable.material .datatable-footer ion-row ion-col:last-child {
  justify-content: right;
}

::ng-deep .ngx-datatable.material .datatable-footer ion-button {
  margin: 0;
}

::ng-deep .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/line-item-table/line-item-table.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACC,kBAAA;EACA,eAAA;AACD;;AAGA;EACE,aAAA;EACA,oBAAA;AAAF;;AAGA;EAGE,gBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF;;AAKA;EACE,sBAAA;AAFF;;AAKA;EACE,SAAA;AAFF;;AAKA;EAEE,eAAA;AAHF","sourcesContent":["ion-label {\n  margin-left: 5px;\n  display: flex;\n}\n\n.selected {\n  background-color: rgb(177, 177, 177);\n}\n\nion-item::part(native) {\n  padding:0;\n}\n\nion-card {\n margin-bottom: 5px;\n margin-top: 3px;\n}\n\n\n.mobile-label {\n  display: flex;\n  font-display: column;\n}\n\n::ng-deep .ngx-datatable.material {\n  //border: 1px solid #E0E0E0;\n  //border-radius: 10px 10px 0px 0px;\n  box-shadow: none;\n}\n\n::ng-deep .ngx-datatable.material .datatable-header .datatable-header-cell {\n  font-size: 14px;\n}\n\n::ng-deep .ngx-datatable.material .datatable-footer ion-col {\n  display: flex;\n  align-items: center;\n}\n\n::ng-deep .ngx-datatable.material .datatable-footer ion-row ion-col:last-child {\n  justify-content: right;\n}\n\n::ng-deep .ngx-datatable.material .datatable-footer ion-button {\n  margin: 0;\n}\n\n::ng-deep .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover\n.datatable-row-group {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
