import { ChangeDetectorRef, Component, EventEmitter,HostListener, Input,OnInit, Output, TemplateRef, ViewChild, } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { getPlatforms } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { FieldContainerService } from './field-container.service';
import { Filter} from 'app/shared/common/interfaces/filter';
import { AuthService } from 'app/core/services';
import { FieldGroup, FormField, SelectValue, FormValidationOption, ValidationOption } from 'app/shared/common/interfaces/ui';


@Component({
  selector: 'app-field-container',
  templateUrl: './field-container.component.html',
  styleUrls: ['./field-container.component.scss'],
})
export class FieldContainerComponent implements OnInit {

  @Input() editMode: boolean;
  @Input() fieldGroups: FieldGroup<FormField>[];
  @Input() formGroup: FormGroup;
  @Input() formValidators?: FormValidationOption[];
  @Input() filterMode? : boolean;
  @Input() filters? : Array<Filter>;
  @Input() groupMaxColumnSize: number;

  @Output() applyFieldEvent = new EventEmitter<string>();

  @ViewChild('input') input:TemplateRef<any>;
  @ViewChild('toggle') toggle:TemplateRef<any>;
  @ViewChild('select') select:TemplateRef<any>;
  @ViewChild('multiselect') multiselect:TemplateRef<any>;
  @ViewChild('datetime') datetime:TemplateRef<any>;
  @ViewChild('url') url:TemplateRef<any>;
  @ViewChild('autocomplete') autocomplete:TemplateRef<any>;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete!: MatAutocompleteTrigger;
  @ViewChild('lineItems') lineItems:TemplateRef<any>;
  @ViewChild('list') list:TemplateRef<any>;
  @ViewChild('companyList') companyList:TemplateRef<any>;
  @ViewChild('button') button:TemplateRef<any>;
  @ViewChild('vatSummaryTable') vatSummaryTable: TemplateRef<any>;
  @ViewChild('invoiceTotals') invoiceTotals: TemplateRef<any>;
  @ViewChild('tradingPartners') tradingPartners: TemplateRef<any>;
  @ViewChild('inlineMultiDatetime') inlineMultiDatetime:TemplateRef<any>;
  @ViewChild('header') header:TemplateRef<any>;
  @ViewChild('multiDateTime') multiDateTime:TemplateRef<any>;
  @ViewChild('inlineDatetime') inlineDatetime:TemplateRef<any>;

  fieldSubscriptions: Subscription[] = [];
  tradingPartnersHeaders: Array<Object>;
  scrollTop : number ;

  today: string;
  columnMode = ColumnMode;
  selectionType = SelectionType;
  loadingIndicator = true;
  reorderable = false;

  selectElements: SelectValue[];

  fieldDateRange:Array<{name:string,currentDateRange:Array<string>}>=[];

  showDateTimePicker = false;

  //css variable, typescript has variable control simplicity
  viewportWidth: number;
  viewportHeight: number;

  constructor(public translate: TranslateService, private cdRef: ChangeDetectorRef, public fieldContainerService: FieldContainerService,
    private authService:AuthService ) {
    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;
  }

  togglePassword(field: FormField) {
    field.toggleIcon.iconClicked = !field.toggleIcon.iconClicked;
    const selectIcon = (this.fieldGroups[0].fields as FormField[]).filter((click)=>click.toggleIcon); // Filter fields to only include those with an 'toggleIcon' property
    selectIcon.forEach((click) => { // Deactivate the other password fields
      if (click.name !== field.name ) {
        click.toggleIcon.iconClicked = false;
      }
    });
  }

  scrollEvent = (): void => {
    //temperory comment out buggy panel open logic to allow autocomplete works correctly on scrolling
    // if (this.autoComplete?.panelOpen){
    //     // this.autoComplete.updatePosition();
    //     this.autoComplete.closePanel();
    //   }
  };


  showFieldGroup(fields: FormField[]):boolean{
    let adminOnlyFields = fields.filter( field=> field.superAdminOnly == true );
    if(adminOnlyFields.length==fields.length){
      return (this.authService.isSuperAdmin())?true:false;
    }
    return true ;
  }


  showField(field: FormField):boolean{
    return (field?.superAdminOnly==true)&& !this.authService.isSuperAdmin?false:true;
  }

  // open datePicker IonModal

  modalDidDismiss(event: any, field: FormField):void {
    if (event) {
      field.isOpen = false;
    }
  }
  //only field that use modal can use this
  setOpen(field: FormField):void {
    field.isOpen = !field.isOpen ;
  }
  // end datePicker

  // If VAT% is on lineItem, use that
  // Else check product library/pricelist
  // Else check system profile level (add field to company)
  // Else Use 20%

  ngOnInit(): void {
    this.today = new Date().toISOString();
    window.addEventListener('scroll', this.scrollEvent, true);
    this.fieldContainerService.ionContentHtml.subscribe(scrollTop => {
      this.scrollTop = scrollTop; // how much the modal has scrolled down
    });
  }

  ngAfterViewInit() {
    this.fieldGroups.forEach(fieldGroup=>{
      //ui structure has an extra layer, this logic followed
      fieldGroup.fields.forEach((field) => {
        // Subscription which updates the text property of a field when its value is changed
        // this is used to make sure the most recent text values are available for details view
        let formControl: AbstractControl = this.formGroup.controls[field.name];
        this.fieldSubscriptions.push(formControl?.valueChanges.subscribe(newValue => {
          this.setDetailsText(field, newValue);
        }));

        if (field.type == 'autocomplete') {
          if (field.availableRecords$ instanceof Observable) {
            field.availableRecords$.subscribe(availableRecords => {
              if (availableRecords?.length > 0) {
                field.availableRecords = availableRecords;
                field.filteredRecords$ = formControl?.valueChanges.pipe(
                  startWith(''),
                  map(value => this._filter(value, field.availableRecords))
                );
              } else {
                // Handing when no records were found
                formControl.disable();
                field.placeholder = 'None available';
                return;
              }
            });
          }

        }
        if(field.type == 'list'){ // handles documentHistory
          field.data$.subscribe(records=>{
            this.loadingIndicator = false;
            field.data = records;
          })
        }
        if( ['inlineMultiDatetime'].includes(field.type) ){
          let dateRange = this.formGroup.controls[field.name].value??false ? this.formGroup.controls[field.name].value:[];
          this.fieldDateRange.push({
            name:field.name, //same field name to form controls
            currentDateRange:dateRange
          });
        }
      });
    })
    // This stops the 'expression changed after...' error
    this.cdRef.detectChanges();
  }

  ionViewWillLeave() {
    this.fieldDateRange = [];
    this.fieldSubscriptions.forEach(fieldSubscription => fieldSubscription.unsubscribe());
  }

  ngAfterViewChecked(){
  }

  /**
   *
   * Uses the id provided to search an array of available options and return the option text.
   *
   * @param id id of selected option
   * @param options array of available options made of id and text
   */
  getSelectText(id: string, options: {id: string, text: string}[]): string {
    const selectedOption = options.find((option) => {
      return option.id === id;
    });
    return selectedOption?.text;
  }

  /**
   *
   * Generates details text based on field type.
   *
   * If field contains multiple values then the text is concatenated.
   *
   * @param field Field to set details text of
   * @param value Value to set the text to
   */
  setDetailsText(field: FormField, newValue: string | string[] | null): void {
    if (field.type === 'multiselect' && Array.isArray(newValue)) {
      field.text = newValue.map((value) => {
        return this.getSelectText(value, field.options);
      }).join(' , ');
      this.cdRef.detectChanges(); //avoid changeAfterCheck error
    } else if (field.type === 'select' && typeof newValue === 'string') {
      field.text = this.getSelectText(newValue, field.options);
      this.cdRef.detectChanges(); //avoid changeAfterCheck error
    } else if ( ['multiDateTime'].includes(field.type)){
      if(Array.isArray(newValue)){
        this.fieldDateRange.push({
          name:field.name, //same field name to form controls
          currentDateRange: newValue
        });
      }
      this.cdRef.detectChanges();
    } else if (typeof newValue === 'string') {
      field.text = newValue;
      this.cdRef.detectChanges();//avoid changeAfterCheck error
    }
  }

  /**
   *
   * Returns elements from an array where the name field contains a substring of the value passed in.
   *
   * Used to filter elements searched for from available elements in autocomplete.
   *
   * @param value Value to filter by
   * @param availableRecords Array of elements to filter
   * @returns Filtered array
   */
  private _filter(value: any, availableRecords: SelectValue[]): SelectValue[] {
    const filterValue = value?.toLowerCase();
    return availableRecords.filter(record => { // DeliveryLocations & TradingPartner interface uses "name" but companyReference uses "tenantName"
      let name;
      if ('tenantName' in record){
        name = record.tenantName;
      } else if ('deliveryLocationName' in record) {
        name = record.deliveryLocationName;
      } else if ('priceListName' in record) {
        name = record.priceListName;
      }
      return name.toLowerCase().includes(filterValue);
    });
  }
  /**
   *
   * Emits selected option.
   *
   * Used in autocomplete to emit the selected record from a list of options.
   *
   * @param record The selected record
   * @param field The field which had the option selected
   */
  // Assigns default company and adds it to the form field
  optionSelect(record: SelectValue, field: FormField) {

    if (field.type == 'autocomplete') {
      field.selectedRecord$.next(record);
    }

  }

  isDesktop(): boolean {
    return getPlatforms().includes('desktop');
  }

  display(data: object){
    return JSON.stringify(data);
  }

  //start date time stuff
  /**
   *
   * Emits multi date time value.
   *
   * Used in multi date time to get and sort user input date
   *
   * @param $event Event from ion-datetime
   * @param name The name of field that user are changing about
   * @param position Index of date that it should change, from:0, to:1
   */
  //date range length must < 2 in maintainence <--important!!
  dateTimeChange($event:any,name:string,position:number):void{
    let inputDate = $event.detail.value ;
    let rangeToChange = (this.fieldDateRange.find(range=>range.name == name)).currentDateRange;
    if(rangeToChange.length==0){ // single click return single day range
      rangeToChange.push(inputDate);
      return;
    }
    if(rangeToChange.length==1){
      if(new Date(inputDate)> new Date(rangeToChange[0])){
        if(position==0){
          rangeToChange[0] = inputDate;
        }
        if(position==1){
          rangeToChange.push(inputDate);
        }
        this.patchDateValue(name,rangeToChange);
      }
      if(inputDate==rangeToChange[0]){
        if(position==0){
          rangeToChange = [];
        }
        if(position==1){
          rangeToChange.push(inputDate);
        }
        this.patchDateValue(name,rangeToChange);
        return;
      }
      if(new Date(inputDate)< new Date(rangeToChange[0])){
        if(position==0){
          rangeToChange[0] = inputDate;
        } //picker disable date < rangeToChange [0]
        this.patchDateValue(name,rangeToChange);
      }
      return;
    }
    if(rangeToChange.length>1){ //2 is the maximum length number
      if(new Date(inputDate)<= new Date(rangeToChange[1])){
        if(position==0){
          rangeToChange[0] = inputDate ;
        }
      }
      if(new Date(inputDate)>= new Date(rangeToChange[0])){
        if(position==1){
          rangeToChange[1] = inputDate ;
        } //picker disable date > rangeToChange [1]
      }
      if(new Date(inputDate)> new Date(rangeToChange[0])&&position==0){
        rangeToChange[0] = inputDate ;
        rangeToChange[1] = inputDate ;
      }
      //this will only happens if they first picked a single day
      if(new Date(inputDate)< new Date(rangeToChange[0])){
        if(position==1){
          rangeToChange[1] = inputDate ;
          rangeToChange[0] = inputDate ;
        } //picker disable date > rangeToChange [1]
      }
      this.patchDateValue(name,rangeToChange);
      return;
    }
    return;
  }
  //multiDateTime component function to return minimum date range for calender
  getMinDate(name:string,position:number):string{
    let currentDateRange = (this.fieldDateRange.find(range=>range.name == name))?.currentDateRange;
    if(!currentDateRange??false){
      return null;
    }
    let minYear = new Date().getFullYear()-100;
    let minDate = minYear+"-01-01T00:00:00.000Z";
    if(currentDateRange.length>=1&&position==1){
      if(currentDateRange[0]!=currentDateRange[1]){
        minDate = currentDateRange[0];
        return minDate ;
      }else{
        minDate = undefined;
        return minDate;
      }
    }
    return minDate ;
  }
  //multiDateTime componment function to return maximum date range for calender
  getMaxDate(name:string,position:number):string{
    let currentDateRange = (this.fieldDateRange.find(range=>range.name == name))?.currentDateRange;
    if(!currentDateRange??false){
      return null;
    }
    let maxYear = new Date().getFullYear()+100;
    let maxDate = maxYear+"-01-01T00:00:00.000Z";
    if(currentDateRange.length>1&&position==0){
      maxDate = currentDateRange[0]==currentDateRange[1]?undefined:currentDateRange[1];
    }
    return maxDate ;
  }

  //multiDateTime component function to determine ToDate calender disable status
  getDateTimeDisable(name:string):Boolean{
    let disable = true ;
    let currentDateRange = (this.fieldDateRange.find(range=>range.name == name))?.currentDateRange;
    if(!currentDateRange??false){
      return disable ;
    }
    if(currentDateRange.length>0&&(currentDateRange[0]??false)||(currentDateRange[1]??false)){
      disable = false ;
    }
    return disable ;
  }

  //multiDateTime component value binding function
  getDateValue(name:string,position:number):string|null{
    if(this.fieldDateRange.length<1){
      return null; //this.fieldDateRange has no default which cause error
    }
    let currentDateRange = (this.fieldDateRange.find(range=>range.name == name))?.currentDateRange;
    if(!currentDateRange??false){
      return null ;
    }
    let dateValue = null;
    if(currentDateRange.length==1){
      if(position==0){
        dateValue = currentDateRange[0];
      }
      if(position==1){
        dateValue = null ;
      }
    }
    if(currentDateRange.length>1&&(currentDateRange[0]!=null)&&(currentDateRange[1]!=null)){
      if(position==0){
        dateValue = currentDateRange[0];
      }
      if(position==1){
        dateValue = currentDateRange[1];
      }
    }
    return dateValue ;
  }

  //multiDateTime component reset date button function
  resetDateTime(name:string){
    (document.getElementById(name+"From") as HTMLIonDatetimeElement).reset();
    (document.getElementById(name+"To") as HTMLIonDatetimeElement).reset();
    (this.fieldDateRange.find(range=>range.name == name)).currentDateRange = [null,null];
    this.cdRef.detectChanges();
  }

  //multiDateTime component single day button function
  singleDay(name:string){
    let currentDateRange = (this.fieldDateRange.find(range=>range.name == name)).currentDateRange;
    if(currentDateRange.length>0){
      currentDateRange[1] = currentDateRange[0];
    }//could throw an error here
    this.cdRef.detectChanges();
  }

  //patch form control value after calculation of the date range.
  patchDateValue(name:string,dateArray:Array<string>){
    this.formGroup.controls[name].setValue(dateArray);
  }

  //programmatically scroll to element inside a modal by onclick(used by search filter modal)
  async scrollToList(elementId:string) {
    try{
      const viewportToElement = document.getElementById(elementId).getBoundingClientRect().top;
      const viewportToModal = document.getElementById("containerList").getBoundingClientRect().top;
      const relativeTop = viewportToElement - viewportToModal + this.scrollTop - 65 ; //65 is result of many hours tweaking, don't touch

      if(this.fieldContainerService.getIonContent()??false){
        this.fieldContainerService.getIonContent().scrollToPoint(0,relativeTop,500);
      }else{
        throw new Error ('unable to get ion content, make sure the field is inside any ion-content such as ion-modal etc.');
      }
      this.cdRef.detectChanges();
    }catch(error){
      throw new Error ( error );
    }
  }
  //end date time stuff

  //filter used function for apply button
  applyField(name:string) {
    this.applyFieldEvent.emit(name);
  }

  //filter used function for apply button
  getApplyStatus(name:string){
    let filter = this.filters.find( filter=> filter.name == name) ;
    return (filter.applied) ;
  }

  //This function is listener that get user viewport size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.viewportWidth = event.target.innerWidth;
    this.viewportHeight = event.target.innerHeight;
  }

  /**
   *
   * Dynamically use the field size attribute (big/medium/small) based on client view port
   *
   * Used to control the ui field width by viewport
   *
   * @param field The field that contains size attribute for itself
   *
  */
  getFieldColumnSize(field: FormField, fieldMaxColumnSize:number ):string {
    let resultColumnSize = '12' ;
    if(this.viewportWidth>=960){
      let eachColumnSize = 12/fieldMaxColumnSize ;
      resultColumnSize = ( field.fieldColumnSize * eachColumnSize ).toString();
    }
    return resultColumnSize;
  }

  getFieldGroupColumnSize(fieldGroup:FieldGroup<FormField>):string{
    let resultColumnSize = '12';
    if(this.viewportWidth>1366){
      let eachColumnSize = 12/ this.groupMaxColumnSize ;
      resultColumnSize = ( fieldGroup.groupColumnSize * eachColumnSize ).toString() ;
    }
    return resultColumnSize;
  }

  //find triggered validator by form and by field
  getValidatorMessage(field: FormField):{showErrorMessage:boolean,message:string}{
    try{
      let showValidator = {showErrorMessage:false,message:null};
      let allValidators:Array<ValidationOption|FormValidationOption> = [];
      let control = this.formGroup.controls[field.name];

      this.formValidators?.forEach( formValidator =>{  // display messg for getFormValidatorFunction()
        if(control.hasError(formValidator.errorType)){
          allValidators.push(formValidator);
        };
      })

      field.validators?.forEach( validator =>{        // display messg for getValidatorFunction()
        if(control.hasError(validator.errorType)){
          allValidators.push(validator);
        }
      })

      if(allValidators.length>0 && (control.dirty|| control.touched) ){
        showValidator = { showErrorMessage:true,message:allValidators[0].message};
      }else{
        showValidator = {showErrorMessage:false,message:null};
      }

      return showValidator;
    }catch(error){
      throw new Error (error);
    }
  }

  isEmptyLabel(fieldGroup:FieldGroup<FormField>):boolean{
    if(fieldGroup.label==''){
      return false ;
    }
    return true;
  }

  getFieldGroupMarginTop(groupPosition:number,fieldGroup:FieldGroup<FormField>):string{
    if(fieldGroup.name == 'sidenav-autocomplete-company'){
      return '0px';
    }
    // if(groupPosition==0){
    //   return 'initial';
    // }
    // if(fieldGroup.label == ''){
    //   return '0px';
    // }
    console.log(groupPosition);
    console.log(fieldGroup);
    return '20px';
  }

  //fiter out group that is admin only
  // TODO: bad security practice, move the validation to server side before production
  getFieldGroups(fieldGroups:FieldGroup<FormField>[]):FieldGroup<FormField>[]{
    let reducedFieldGroups = [];
    for( let i = 0;i<fieldGroups.length;i++){
      if(this.showFieldGroup(fieldGroups[i].fields)){
        reducedFieldGroups.push(fieldGroups[i]);
      }
    }
    return reducedFieldGroups;
  }

  onCardToggleClick(fieldGroup: FieldGroup<FormField> & { hide?: boolean }){
    fieldGroup.hide = !fieldGroup.hide;
  }

  getCardToggleName(fieldGroup : FieldGroup<FormField> & { hide?: boolean }): string{
    if(fieldGroup.hide){
      return "chevron-up-outline";
    }else{
      return "chevron-down-outline";
    }
  }

  getFieldRowToggle(fieldGroup : FieldGroup<FormField> & { hide?: boolean }): string{
    if(fieldGroup.hide){
      return "field-row-hide";
    }else{
      return "field-row-show";
    }
  }

  getFieldItemMode(): string{
    if(this.editMode){
      return "field-item-edit";
    }else{
      return "field-item";
    }
  }

}
