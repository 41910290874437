import { Injectable } from '@angular/core';
import { RecordObject  } from 'app/shared/common/interfaces/global';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor() { }

  private selectMode$ = new BehaviorSubject<boolean>(false);
  public readonly selectModeSubscription = this.selectMode$.asObservable();
  public set selectMode(selectMode:boolean) {
    this.selectMode$.next(selectMode);
  }

  private selected$ = new BehaviorSubject<RecordObject[]>([]);
  public readonly selectedSubscription = this.selected$.asObservable();
  public set selected(records:RecordObject[]) {
    this.selected$.next(records);
  }
  
  private recordPageRequest$ = new ReplaySubject<RecordPageRequest>(1);
  public readonly recordPageRequestSubscription = this.recordPageRequest$.asObservable();
  public set recordPageRequest(recordPageRequest:RecordPageRequest){
    this.recordPageRequest$.next(recordPageRequest);
  }

  public get selected():RecordObject[]{
    return this.selected$.value ; 
  }

}
