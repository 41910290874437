// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep app-field-container ion-list ion-item {
  margin-bottom: 20px;
}

:host ::ng-deep app-field-container ion-list {
  max-width: min-content;
}

#deleteButton {
  color: red;
}

.save-apply-button-icon {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/filter-modal/filter-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[":host ::ng-deep app-field-container ion-list ion-item{\n    margin-bottom : 20px;\n}\n\n:host ::ng-deep app-field-container ion-list{\n    max-width: min-content;\n}\n\n#deleteButton{\n    color:red;\n}\n\n.save-apply-button-icon{\n    margin-left : 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
