import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IonContent, ModalController, ScrollDetail } from '@ionic/angular';
import { ValidatorService } from 'app/core/services/validator.service';
import { Filter} from 'app/shared/common/interfaces/filter';
import { FieldGroup, FormField, Ui } from 'app/shared/common/interfaces/ui';
import { FieldContainerService } from 'app/shared/components/field-container/field-container.service';

@Component({
  selector: 'app-search-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})

//Input an array of fields, Output user edited filter value
export class FilterModalComponent implements OnInit {
  @ViewChild('fieldContainerContent') fieldContainerContent: IonContent;//scroll to reference

  @Input() fieldGroups: FieldGroup<FormField>[];
  filterForm:FormGroup;

  @Input() filters?: Array<Filter>; //if undefined, oninit assign a default one
  editMode : boolean = true ; disableDeleteButton: boolean = false ;
  constructor(public modalController:ModalController,public fieldContainerService:FieldContainerService,
    public validatorService:ValidatorService) { }

  ngOnInit() {
    this.filterForm = new FormGroup({});
    this.readFields(); //this will add form control and generate default filter object in process
    this.patchFilterValue();
    let ui:Partial<Ui> = { // create ui object to apply the formGroup validations
      uiTabs:[
        {
          name: '',
          panel: null,
          groupMaxColumnSize:1,
          fieldGroups: this.fieldGroups // get fieldGroups from @input fieldGroups,
        }
      ]
    };
    this.filterForm = this.validatorService.setValidatorFromUI(this.filterForm, ui); // set the formGroup validation for the filter modal

  }

  ngAfterViewInit(){
    //patch again here because multi_date time bug, to be fix in futre, not app broken bug.
    this.patchFilterValue();
    this.fieldContainerService.setIonContent(this.fieldContainerContent); //setting an anchor for scroll event
  }

  onScroll(ev: CustomEvent<ScrollDetail>):void{
    this.fieldContainerService.setContent(ev.detail.scrollTop); //emits thhe scroll event
  }

  cancel():void {
    this.modalController.dismiss();
  }

  confirm(nextAction:string):void{
    this.filterForm.markAllAsTouched();
    if(this.filterForm.invalid){
      return; //prevent user create invalid filter
    }
    //TODO - Add validation
    //map field container's form value object as an array [{key:'',value:''}].
    let formValue = Object.keys(this.filterForm.value).map(key=>({'key':key,'value':this.filterForm.value[key]})) ;
    formValue.forEach(control=>{
      control.value = (!Array.isArray(control.value))?[control.value]:control.value //put the non array value into array
      //filters is either default one or input one, we can only save form value here
      this.filters.find(filter=>filter.name==control.key).value = control.value ;
      if(control.value.length>0&&control.value[0]==null){
        this.filters.find(filter=>filter.name==control.key).applied = false ;
      }else{
        this.filters.find(filter=>filter.name==control.key).applied = true ;
      }
    })
    this.modalController.dismiss(this.filters,nextAction);
  }

  removeFilter():void{
    this.modalController.dismiss([],'delete');
  }
  //read formGroupField, to add form control and generate default filter object
  readFields():void{
    let defaultFilters: Array<Filter> =[]; //provide blank filter for creating filter
    this.fieldGroups[0].fields.forEach( field => {
      this.filterForm.addControl(field.name,new FormControl());
      //*note: all filter Value is an array of string to allow more dyanmic input
      let defaultFilter = {name:field.name,fieldType:field.type,value:[],applied:false};
      defaultFilter.applied = field.name == 'filterName' ? true : false;//filter name default true
      defaultFilters.push(defaultFilter);
    });
    if(this.filters==undefined){
      this.filters = defaultFilters ;//assign default template filter when adding a new filter
      this.disableDeleteButton = true ;
    }
  }

  patchFilterValue():void{
    this.filters.forEach(filter=>{
      if( filter.value?.[0]?.length > 0 ?? false){
        if(filter.value.length == 1){
          this.filterForm.controls[filter.name].patchValue(filter.value[0] );
        }else{
          this.filterForm.controls[filter.name].patchValue(filter.value );
        }
      }
    })
  }

}
