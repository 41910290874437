import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { HelperService } from 'app/core/services';
import { DocumentService } from 'app/documents/document.service';
import { PriceListService } from 'app/price-list/price-list.service';
import { PriceListProduct } from 'app/shared/common/interfaces/priceList';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-products-modal',
  templateUrl: './add-products-modal.component.html',
  styleUrls: ['./add-products-modal.component.scss'],
})
export class AddProductsModalComponent implements OnInit {

  constructor(private modalController: ModalController, private documentService:DocumentService,private priceListService:PriceListService,
    private helperService:HelperService,     public router: Router,
  ) { }
  //TODO: Replace with proper products
  // The quantity is required to know how many item were added
  @Input() products: (Partial<PriceListProduct>&{enabled?: boolean, quantity?: number})[];
  @Input() lineItems;
  options:any = []
  selectedPriceListId:any

  ngOnInit() {


      if (this.checkDocumentPage()) {
        combineLatest([this.documentService.activePriceListId$,this.documentService.availablePriceLists$]).pipe(
          filter(([_, availablePriceLists]) => availablePriceLists !== null && availablePriceLists !== undefined)
        )
        .subscribe(([activePriceListId,availablePriceLists])=>{
          this.selectedPriceListId = activePriceListId;
          if(this.selectedPriceListId) {
            this.priceListService.getPriceList(this.selectedPriceListId).subscribe(res=>{
              const notAddedProducts = this.filterNotAddedProducts(res);
              this.products = notAddedProducts;
            })
          }

          this.options = [];
          availablePriceLists.forEach(priceList=>{
            this.options.push({
              id:priceList.priceListId,
              text:priceList.priceListName
            })
          });

        })
      }
    this.products.forEach(product => {
      product.quantity = null;
  });

  }


  private filterNotAddedProducts(priceList) {
    const notAddedProducts = priceList.priceListProducts
      .filter(product =>
        !this.lineItems.map(lineItem => lineItem.productId).includes(product._id)
      )
      .map(product => ({
        ...product, // Spread the existing product properties
        quantity: 0 // Set quantity to 0 or null as needed
      }));

    return notAddedProducts; // Return the array of not added products with quantity set
  }

  updatePriceList(event) {
    this.documentService.activePriceListId$.next(event);
    this.priceListService.getPriceList(event).subscribe(pricelist => {
        const notAddedProducts = this.filterNotAddedProducts(pricelist);
        this.products = notAddedProducts.map(product => ({
            ...product,
        }));
    });
  }

  checkDocumentPage():boolean {
    const isDocumentRoute= this.helperService.isDocumentRoute(this.router.url.split('/')[1]);
    if(isDocumentRoute){
      return true
    }
    return false
  }

  async onSubmit() {
    // Remove any products with quantity less than or equal to 0
    this.products = this.products.filter(product => product.quantity && product.quantity > 0);
    await this.modalController.dismiss({products: this.products});
  }

  async onCancel() {
    await this.modalController.dismiss();
  }

}
