import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NestedFieldList } from 'app/shared/common/interfaces/document';
import { PriceList, PriceListProduct, PriceListReference } from 'app/shared/common/interfaces/priceList';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import {  Ui } from 'app/shared/common/interfaces/ui';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PriceListService {

  constructor(private http: HttpClient) { }

  getPriceLists(companyId: string, recordPageRequest: RecordPageRequest): Observable<{records:PriceList[]; total: number}> {
    return this.http.post<{records:PriceList[]; total: number}>('price-list/list/', {companyId, recordPageRequest} );
  }

  getPriceListReferences(priceListId:PriceList['_id'][]): Observable<PriceListReference[]> {
    return this.http.post<PriceListReference[]>('price-list/priceListReferences',{priceListId})
  }

  getPriceList(id: string): Observable<PriceList> {
    return this.http.get<PriceList>(`price-list/${id}`);
  }

  createPriceList(priceList: PriceList) {
    return this.http.post('price-list', {priceList}, {responseType: 'text'});
  }

  updatePriceList(priceList: PriceList) {
    return this.http.put('price-list', {priceList}, {responseType: 'text'});
  }

  deletePriceLists(priceListIds: string[]) {
    return this.http.delete('price-list', { body: { priceListIds }, responseType: 'text' });
  }

  ui:Omit<Ui,'_id'> = { // priceList ui
    uiTabs: [
      {
        name: 'price-list-details',
        panel: {
          title: 'PRICELISTDETAILS',
          description: 'PRICELISTDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'general-details',
            label: 'GENERALDETAILS',
            fieldMaxColumnSize:1,
            groupColumnSize:1,
            fields: [
              {
                name: 'name',
                label: 'PRICELISTNAME',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'PRICELISTNAMEREQUIRED'},
                ]
              },
              {
                name: 'description',
                label: 'DESCRIPTION',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              }
            ]
          },
        ]
      },
      {
        name: 'lineItems',
        panel: {
          title: 'LINEITEMS',
          description: 'LINEITEMSDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'lineItemsGroup',
            label: '',
            fieldMaxColumnSize:1,
            groupColumnSize:1,
            fields: [
              {
                name: 'lineItems',
                type: 'lineItems',
                products$: new Subject<Partial<PriceListProduct>[]>(),
                lineItems$: new ReplaySubject<NestedFieldList[]>(),
                fieldColumnSize: 1,
              }
            ]
          }
        ]
      }
    ],
    metadataUiData: [
      {
        name: 'createdBy',
        label: 'CREATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'createdDate',
        label: 'CREATEDDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updatedBy',
        label: 'UPDATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updateDate',
        label: 'UPDATEDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      }
    ]
  }
}
