import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, getPlatforms, IonInfiniteScroll, ViewDidEnter, ViewWillLeave, ViewWillEnter, ModalController} from '@ionic/angular';
import { DatatableComponent, SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { Observable, of, Subject, Subscription } from 'rxjs';

import { ActionBarService, AuthService, FilterService, HelperService, SelectCompanyService, TradingPartnershipService } from 'core/services';
import { SearchService } from 'shared/services/search.service';
import { Action } from 'common/actions';
import {  RecordPageRequest } from 'app/shared/common/interfaces/search';

import { CompanyService } from 'app/company/company.service';
import { LocationsService } from 'app/locations/locations.service';
import { UsersService } from 'app/users/users.service';
import { TableService } from 'app/core/services/table.service';
import { ProductsService } from 'app/products/products.service';
import { PriceListService } from 'app/price-list/price-list.service';
import { RecordObject  } from 'app/shared/common/interfaces/global';
import { TranslateService } from '@ngx-translate/core';
import { DocumentRecordType, NonDocumentRecordType, routeRecordType } from 'app/shared/common/interfaces/recordTypes';
import { PrintService } from 'app/core/services/print.service';
import { DocumentService } from 'app/documents/document.service';
import { MetadataModalService } from 'app/shared/modals/metadata-modal/metadata-modal.service';
import { UKDatePipe } from 'app/shared/pipes/date.pipe';
import { ClientColumn } from 'app/shared/interfaces/clientColumn';
import { AccountActivationModalPage, CreateActionModalComponent } from 'app/shared/modals';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Company, DefaultSort } from 'app/shared/common/interfaces/company';
import { ExchangeStatusPipe } from 'app/shared/pipes/exchangeStatus.pipe';
import { TradingPartnershipSelector } from 'app/shared/common/interfaces/tradingPartnership';
import { Document } from 'app/shared/common/interfaces/document';
import { User } from 'app/shared/common/interfaces/user';
import { DeliveryLocation } from 'app/shared/common/interfaces/deliveryLocation';
import { Product } from 'app/shared/common/interfaces/product';
import { PriceList } from 'app/shared/common/interfaces/priceList';
import * as DocumentHelpers from 'app/shared/common/modules/document-helpers';
import { ExchangeStatus } from 'app/shared/common/interfaces/documentStatus';
import { AlertDialogComponent } from 'app/shared/components/alert-dialog/alert-dialog';
@Component({
  selector: 'record-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, ViewWillLeave, ViewWillEnter, ViewDidEnter {

  @ViewChild('datatable', {static: false}) table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  readonly headerHeight = 50;
  readonly rowHeight = 50;

  searchForm: UntypedFormGroup;
  exchangeStatusPipe: ExchangeStatusPipe = new ExchangeStatusPipe();

  recordPageRequest: RecordPageRequest;

  activeRoute: string;
  userId: string;

  totalRows = 0;

  loadingIndicator = true;
  reorderable = true;
  SelectionType = SelectionType;

  rows: RecordObject [] = [];
  allRowsFetched: boolean = true; // To be used with TP selector for document count
  selected: RecordObject [] = [];

  columns:ClientColumn[] = [];

  columnMode = ColumnMode;

  buttonEventSubscription: Subscription;
  backButtonEventSubscription: Subscription;
  selectCompanyEventSubscription: Subscription;
  recordPageRequestSubscription: Subscription;
  filterResultSubscription:Subscription;
  userSubscription: Subscription;
  selectModeEventSubscription: Subscription;
  tradingPartnershipSelectors: TradingPartnershipSelector[];
  sidenavConfigsSubscription: Subscription;

  selectionType = SelectionType;
  selectMode = false;

  selectedPageOption: number = 10;
  pageOptions : Array<number> = [10,25,50,100,150,200,250];
  pageOption : number;

  inputValue :number;
  nextRoute:string;
  destroy$ = new Subject<void>();

  searchBarInput$ = new Subject<void>();

  constructor(private actionBarService: ActionBarService, private alertDialogComponent: AlertDialogComponent,
              public authService:AuthService,
              private companyService: CompanyService, private documentService:DocumentService,
              public filterService: FilterService, private helperService:HelperService,
              private locationService: LocationsService, private modalController: ModalController,
              private navController: NavController, private priceListService: PriceListService,
              private productService: ProductsService, private printService: PrintService,
              public router: Router, private searchService: SearchService,
              private selectCompanyService:SelectCompanyService, public tableService:TableService,
              private tradingPartnershipService: TradingPartnershipService, private translate:TranslateService,
              private userService: UsersService, private metadataModalService:MetadataModalService,
            ) {
              }

  ngOnInit() {
    this.activeRoute = this.router.url.split('/')[1]; // url is set on sidenav.ts
    this.nextRoute = this.activeRoute === 'users' ? 'management' : 'form';
    this.recordPageRequestSubscription = this.tableService.recordPageRequestSubscription.subscribe( recordPageRequest=>{
      this.recordPageRequest = recordPageRequest; //read any other component alternated record page request
      //TODO: should be more then simply over write
    });
    this.searchForm = new UntypedFormGroup({
      filter: new UntypedFormControl('')
    });

    this.initializeTradingPartnershipSelector(); // TODO review whether it is needed here

    //For multiple documents we can create the /document route specifying the types in the url
    switch (this.activeRoute) {
      case 'orders': // TODO make company-level JSON definition - related to sidenav.ts "appPages" // https://app.asana.com/0/1204014722864744/1207040487339087/f
        this.recordPageRequest = {
          recordTypeName: DocumentRecordType.Order,
          order: 'desc',
          sortByField: 'metadata.creationDate',
          stringFilter: '',
          page: 0,
          pageSize: 10
        }
        let ordersPipe: UKDatePipe = new UKDatePipe('en_GB');
        this.columns = [
          { name: 'Document Number', prop: 'documentNumber', translateKey: 'ORDERNUMBER' }, //fields.documentNumber not displaying on html... -could flatten the nested array of objects on "this.rows"
          { name: 'exchangeStatus', prop: 'exchangeStatus', translateKey:'EXCHANGESTATUS',pipe: this.exchangeStatusPipe },
          { name: 'metadata.creationDate', prop: 'metadata.creationDate', translateKey:'CREATIONDATE', type: 'datetime', pipe: ordersPipe }
        ];
        break;
      case 'invoices': // later make company-level JSON definition - related to sidenav.ts "appPages" // TODO put Asana link
        this.recordPageRequest = {
          recordTypeName: DocumentRecordType.Invoice,
          order: 'desc',
          sortByField: 'metadata.creationDate',
          stringFilter: '',
          page: 0,
          pageSize: 10
        }

        let invoicesPipe: UKDatePipe = new UKDatePipe('en_GB');
        this.columns = [
          { name: 'Document Number', prop: 'documentNumber', translateKey: 'INVOICENUMBER' },
          { name: 'exchangeStatus', prop: 'exchangeStatus', translateKey:'EXCHANGESTATUS', pipe:this.exchangeStatusPipe},
          { name: 'metadata.creationDate', prop: 'metadata.creationDate', translateKey:'CREATIONDATE', type: 'datetime', pipe: invoicesPipe }
        ];
        break;
      case 'users':
        this.recordPageRequest = {
          recordTypeName: NonDocumentRecordType.User,
          order: 'asc',
          sortByField: 'firstName',
          stringFilter: '',
          page: 0,
          pageSize: 10,
        }
        this.columns = [
          { name: 'First Name', prop: 'firstName' ,translateKey:'FIRSTNAME' },
          { name: 'Last Name', prop: 'lastName' ,translateKey:'LASTNAME' },
          { name: 'Email', prop: 'email' ,translateKey:'EMAIL' }
        ];
      break;
      case 'products':
        this.recordPageRequest = {
          recordTypeName: NonDocumentRecordType.Product,
          order: 'asc',
          sortByField: 'name',
          stringFilter: '',
          page: 0,
          pageSize: 10
        }
        this.columns = [
          { name: 'Name', prop: 'name' ,translateKey:'NAME' },
          { name: 'Description', prop: 'description' ,translateKey:'DESCRIPTION'  }
        ];
        break;
      case 'price-list':
        this.recordPageRequest = {
          recordTypeName: NonDocumentRecordType.PriceList,
          order: 'asc',
          sortByField: 'name',
          stringFilter: '',
          page: 0,
          pageSize: 10,
        }
        this.columns = [
          { name: 'Name', prop: 'name' ,translateKey:'NAME' },
          { name: 'Description', prop: 'description' ,translateKey:'DESCRIPTION' },
          { name: 'Associated with', prop: 'associatedWith' ,translateKey:'ASSOCIATEDWITH' }
        ];
        break;
      case 'locations':
        this.recordPageRequest = {
            recordTypeName: NonDocumentRecordType.Location,
            order: 'asc',
            sortByField: 'name',
            stringFilter: '',
            page: 0,
            pageSize: 10
          }
        this.columns = [
            { name: 'Name', prop: 'name' ,translateKey:'NAME' },
            { name: 'Address Line 1', prop: 'autofillData.deliveryAddressLine1' ,translateKey:'ADDRESSLINE1' },
            { name: 'Postcode', prop: 'autofillData.deliveryPostCode' ,translateKey:'POSTCODE' }
          ];
          break;
      default:
        break;
    }
    this.translateColumnText();//translate instantly
    this.translate.onLangChange.subscribe( ()=>{
      this.translateColumnText(); //translate again each time lang change
    });

    this.searchBarInput$.pipe(
      debounceTime(2000), // Wait for 2 seconds of inactivity
      switchMap( () => of ( //switch map discard previous subscription imedtiately if new value emit
          this.applyRecordPageRequest()
      ))
    ).subscribe();

  }
  ionViewWillEnter() {
    this.buttonEventSubscription = this.actionBarService.buttonEventSubscription.subscribe(event => {
      switch (event) {
        case Action.edit:
          this.editAction();
          break;
        case Action.selectMode:
          this.tableService.selectMode = !this.selectMode;
          break;
        case Action.create:
          this.createAction();
          break;
        case Action.open:
          this.viewAction();
          break;
        case Action.delete:
          this.deleteAction();
          break;
        case Action.duplicate:
          this.duplicateAction();
          break;
        case Action.filter:
          this.filterAction();
          break;
        case Action.metadata:
          this.metadataAction();
          break;
        case Action.print:
          this.printAction();
          break;
        default:
          break;
      }
    });

    this.backButtonEventSubscription = this.actionBarService.backButtonEventSubscription.subscribe(() => {
      this.backAction();
    })
    //side nav event trigger this
    this.selectCompanyEventSubscription = this.selectCompanyService.selectCompanyEventSubscription.subscribe(() =>{
      this.initializeTradingPartnershipSelector(); // TODO is it needed here?
      this.applyRecordPageRequest();
    })

    //subscrbe rows to filter pop over applyfilter action result
    this.filterResultSubscription = this.filterService.filterResultSubscription.subscribe( data => {
      //for desktop
      if(this.isDesktop){
        this.rows = [...data.records];
        this.totalRows = data.total;
        this.loadingIndicator = false;
      }else{
        if (!(this.rows.length === this.totalRows)){
          this.rows.push(...data.records);
          this.totalRows = data.total;
          this.recordPageRequest.page++;
        }
      }
    })
  }
  //called each time route change, including previous route
  ionViewDidEnter(): void {
    this.initializeTradingPartnershipSelector();

    this.sidenavConfigsSubscription = this.companyService.sidenavConfigs$.pipe(takeUntil(this.destroy$)).subscribe( sidenavConfigs =>{
      let activeSidenavConfig = sidenavConfigs.find( sidenavConfig=> sidenavConfig.appPage.url.split('/')[1] == this.activeRoute);
      if(activeSidenavConfig.columns){
        this.columns = activeSidenavConfig.columns ;
        this.columns = this.addColumnPipes(this.columns);
        this.translateColumnText();
        if(!activeSidenavConfig.defaultSort){
          return ;
        }
        this.recordPageRequest = this.buildRecordPageRequest(this.activeRoute, activeSidenavConfig.defaultSort);
        this.applyRecordPageRequest();
      }else{
        throw new Error ('Invalid columns definition');
      }
    });

    this.tableService.selected = [] ;
    this.selectModeEventSubscription = this.tableService.selectModeSubscription.pipe(takeUntil(this.destroy$)).subscribe( selectMode =>{
      this.selectMode = selectMode ; //subscribe selectMode since its real time data useful
      if(this.selectMode == false){
        this.selected = [];
      }
    })
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe(user=>{
      this.userId=user._id;
      this.getFilterGroups();
    })
    // Needed for mobile view to avoid duplicate 0th page request
    if (!this.isDesktop()) {
      this.recordPageRequest.page++;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ionViewWillLeave() {
    this.selected = [];
    this.buttonEventSubscription.unsubscribe();
    this.backButtonEventSubscription.unsubscribe();
    this.selectCompanyEventSubscription.unsubscribe();
    this.selectModeEventSubscription.unsubscribe();
    this.rows = [];
    this.recordPageRequest.page = 0;
    this.recordPageRequestSubscription.unsubscribe();
  }

  // Event on row click
  onActivate(event) {
    // This needs to be revisited. on a single click, up to 4 events could be emitted.
    // This is the shortest way to ensure the user view is not opened on a single click on a checkbox
    if ((!this.selectMode && event.type === 'dblclick')&& event.event.target.localName != 'input' ) { //excluded dbclick on input(checkbox) target
      this.tableService.selected = [event.row] as RecordObject [];
        this.navController.navigateForward([this.activeRoute, this.nextRoute], { queryParams: { id: event.row._id, editMode: false } });
      //this is where you left the record list view
    }
  }

  // Event triggered on page change or scroll
  onPage(event) {
    console.log('paged', event);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  isDesktop(): boolean {
    return getPlatforms().includes('desktop');
  }


  editAction(record?: RecordObject ): void {
    if (record) {
      this.router.navigate([this.activeRoute, this.nextRoute], { queryParams: { id: this.selected[0]._id, editMode: true } });
    } else {
      this.router.navigate([this.activeRoute, this.nextRoute], { queryParams: { id: this.selected[0]._id, editMode: true } });
    }
  }


  metadataAction(): void {
    if(this.selected.length===1){
      this.metadataModalService.tableMetadataModal( this.activeRoute , this.selected[0] );
    }else{
      throw new Error ('Metadata require a single document to be shown');
    }
  }

  viewAction(record?: RecordObject ): void {
    if (record) {
      this.router.navigate([this.activeRoute, this.nextRoute], { queryParams: { id: record._id, editMode: false} });
    } else {
      this.router.navigate([this.activeRoute, this.nextRoute], { queryParams: { id: this.selected[0]._id, editMode: false} });
    }
  };

  deleteAction(): void {
    const selectedIds = this.selected.map(x => x._id);
    let docIds: string[] = [""];
    //TODO: need to think a way to generalize this
    switch (this.activeRoute) {
      case 'orders': case 'invoices':
        docIds = this.selected.map((x: Document) => x.documentNumber);
        break;
      case 'users':
        docIds = this.selected.map((x: User) => `${x.firstName} ${x.lastName}`);
        break;
      case 'products':
        docIds = this.selected.map((x: Product) => `${x.name}`);
        break;
      case 'price-list':
        docIds = this.selected.map((x: PriceList) => `${x.name}`);
        break;
      case 'locations':
        docIds = this.selected.map((x: DeliveryLocation) => `${x.name}`);
        break;
      default:
        break;
    }

    this.alertDialogComponent.confirmAlert("DELETEDOCHEADER", docIds.join(", "),
    "DELETEDOCMESSAGE","DELETEDOCCONFIRM","DELETEDOCCANCEL",
    () => { //Confirmed
      //TODO: need to think a way to generalize this
      switch (this.activeRoute) {
        case 'orders': case 'invoices': //TODO: isRouteDocument() something like that here?
          this.documentService.deleteDocuments(selectedIds).subscribe(() => {
            this.applyRecordPageRequest();
          });
          break;
        case 'users':
          this.userService.deleteUsers(selectedIds).subscribe(() => {
            this.applyRecordPageRequest();
          });
          break;
        case 'products':
          this.productService.deleteProducts(selectedIds).subscribe(() => {
            this.applyRecordPageRequest();
          });
          break;
        case 'price-list':
          this.priceListService.deletePriceLists(selectedIds).subscribe(() => {
            this.applyRecordPageRequest();
          });
          break;
        case 'locations':
          this.locationService.deleteLocations(selectedIds).subscribe(() => {
            this.applyRecordPageRequest();
          });
          break;
        default:
          break;
      }
      // Clearing out the selected elements after deletion
      this.selected = [];
      this.tableService.selected = [] ;
    }, () => { //Cancelled
      //Nothing to do;
    }).then(()=>{ //On dialog dismiss
      //Nothing to do;
    });
  }

  //Overloaded functions for cards
  duplicateAction(record?:  RecordObject ): void {
    if (record) {
      this.router.navigate([this.activeRoute, this.nextRoute], { queryParams: { id: record._id, editMode: true, duplicate: true } });
    } else {
      this.router.navigate([this.activeRoute, this.nextRoute], { queryParams: { id: this.selected[0]._id, editMode: true, duplicate: true } });
    }
  }

  backAction(): void {
    this.selected = [];
    if(this.selectMode){
      //we reset the whole select mode thing here
      this.tableService.selectMode = false;
    }else{
      this.navController.navigateRoot(['dashboard']);
    }
  }

  printAction(): void {
    let documents: Document[] = this.selected.filter(
      (record): record is Document => this.isDocument(record)
    );
    this.printService.printMulti(documents);
  }

  isDocument(record: RecordObject): record is Document {
    return (record as Document).documentNumber !== undefined;
  }

  //create modal
  async filterAction() {
    //popover is in action bar instead because css

  }

  setFilter(filter: string) {
    this.searchForm.controls.filter.setValue(filter);
  }

  onInputChange(){
    this.searchBarInput$.next();
  }

  //update table with an updated recordPageRequest
  applyRecordPageRequest() {
    this.recordPageRequest.stringFilter = this.searchForm.get('filter').value; //make sure all record search has latest string filter value
    this.rows = [];
    if (!this.isDesktop()) {
      this.recordPageRequest.page = 0;
      this.rows = [];
      this.getMobileRecords();
    } else {
      this.getRecords();
    }
    //after this recordPageRequest applied, broadcast the new recordPageRequest to subscriber
    this.tableService.recordPageRequest = this.recordPageRequest ;
  }

  getId(row) {
    return row._id;
  }

  sort(event) {
    this.recordPageRequest.order = event.newValue;
    this.recordPageRequest.sortByField = event.column.prop;
    this.applyRecordPageRequest();
  }

  setPage(event) {
    //custom footer only have page so work around is -1 to make it an offset.
    this.recordPageRequest.page = event.page-1;
    this.applyRecordPageRequest();
  }

  getRecords() {
    let searchCallBack : Observable<{records: RecordObject []; total: number;}> ;
    const lookupIds = (this.isDocumentRecordType()) ?
      this.getSelectedTradingPartnerIds() : [this.companyService.activeCompany.companyId];

    if(this.recordPageRequest.filters == null){
      searchCallBack = this.searchService.getRecords(this.recordPageRequest, lookupIds);
    }
    if(this.recordPageRequest.filters!=null && this.recordPageRequest.filters.length>0){
      searchCallBack = this.searchService.applyFilterSearch(this.recordPageRequest, lookupIds);
    }

    searchCallBack.subscribe((data) => {
      this.rows = [...data.records];
      this.totalRows = data.total;

      if (this.isDocumentRecordType()) {
        if (this.allRowsFetched) { // TODO temp, until I find a way to run it only once on init
          this.disableTradingPartnershipSelectorsWithNoDocuments();
          this.allRowsFetched = !this.allRowsFetched;
        }

        // Hide draft documents from recipient
        this.rows = this.filterRowsByDocumentParties(this.companyService.activeCompany.companyId, this.rows as Document[]);
      }

      this.loadingIndicator = false;
    });
  }

  getMobileRecords(event?: any) {
    let searchCallBack : Observable<{records: RecordObject []; total: number;}> ;
    const lookupIds = (this.isDocumentRecordType()) ?
      this.getSelectedTradingPartnerIds() : [this.companyService.activeCompany.companyId];

    if(this.recordPageRequest.filters == null){
      searchCallBack = this.searchService.getRecords(
        this.recordPageRequest,
        lookupIds
      );
    }
    if(this.recordPageRequest.filters!=null && this.recordPageRequest.filters.length>0){
      searchCallBack = this.searchService.applyFilterSearch(
        this.recordPageRequest,
        lookupIds
      );
    }
    searchCallBack.subscribe(data=>{
      if ((this.rows.length === this.totalRows) && event) {
        event.target.disabled = true;
      } else {
        this.rows.push(...data.records);
        this.totalRows = data.total;
        this.recordPageRequest.page++;
        event?.target.complete();
      }
    })
  }

  selectRecord(record: RecordObject ) {
    let isSelected = this.selected.some(x => x._id === record._id);
    if (isSelected) {
      let selectedIndex = this.selected.findIndex(x => x._id === record._id);
      this.selected.splice(selectedIndex, 1);
    } else {
      this.selected.push(record);
    }
    this.tableService.selected = this.selected ;
  }

  isSelected(record): boolean {
    return this.selected.some(x => x._id === record._id);
  }

  doInfinite(event) {
    this.getMobileRecords(event);
  }

  log(value:any){
    console.log("Parse Value: " +  JSON.stringify(value) + " | " +  Object.prototype.toString.call(value));
  }
  parseValue(value: any){
    console.log("Parse Value: " +  JSON.stringify(value) + " | " +  Object.prototype.toString.call(value));
    let d = new Date(value);
    if(d.getTime() === d.getTime()){
      //This is a Date
      return this.formatDate(d);
    }else{
      return value;
    }
  }

  //TODO: maybe restructure this to use .format function
  //https://www.w3docs.com/snippets/javascript/how-to-format-a-javascript-date.html
  formatDate(date: Date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    let hour = '' + date.getHours();
    let minute = '' + date.getMinutes();
    let second = '' + date.getSeconds();

    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    if (second.length < 2)
        second = '0' + second;
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':');
  }

  //each checkbox checked value
  public getChecked(row: any): boolean {
    let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
    var contains = selectedObjAsString.includes(JSON.stringify(row));
    return contains ;
  }

  //each checkbox change  function
  public onCheckboxChange(row: any): void {
    if (this.getChecked(row) === false) {
      // add the row to selected
      this.selected.push(row);
    } else {
      // indexOf don't work on array of object but only array of string
      let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
      let removeElementIndex = selectedObjAsString.indexOf(JSON.stringify(row));
      this.selected.splice(removeElementIndex, 1);
    }
    this.tableService.selected = this.selected ;
  }

  //headerbox value
  public getCheckedAll(): boolean{
    let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
    let rowsObjAsString = this.rows.map(function(item){return JSON.stringify(item)});
    //if less are selected then all rows, impossible to have all rows
    if(selectedObjAsString.length<rowsObjAsString.length){
      return false;
    }
    else{
      let equals = true;
      //more or eqaully selected as row, once selected don't includes a row, its not all rows.
      rowsObjAsString.forEach(row => {
        if(!selectedObjAsString.includes(row)){
          equals = false ;
        }
      });
      return equals ;
    }
  }

  //headerbox change function
  public onCheckboxAllChange(){
    let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
    let rowsObjAsString = this.rows.map(function(item){return JSON.stringify(item)});
    let totalNotIncludedRow = 0 ;
    rowsObjAsString.forEach( row => {
      if(!selectedObjAsString.includes(row)){totalNotIncludedRow++}
    });
    //if some or no rows are included -> include the excluded
    if(totalNotIncludedRow>0){
      rowsObjAsString.forEach( row => {
        if(!selectedObjAsString.includes(row)){
          this.selected.push(this.rows[rowsObjAsString.indexOf(row)]);
        }
      });
    }else{
    //else all rows are included -> exclude all
      rowsObjAsString.forEach( row => {
        //re-map for real time data
        let newSelectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
        this.selected.splice( newSelectedObjAsString.indexOf(row), 1);
      });
    }
    this.tableService.selected = this.selected ;
  }

  onEnterPageSize(event){
    if(event.target.value==''){return;}
    this.recordPageRequest.pageSize = Number(event.target.value) ;
    this.applyRecordPageRequest();
  }

  //get all filter group each time route is change in table
  getFilterGroups(){
    this.filterService.getFilterGroups(this.userId);
  }

  updateStringFilter(){
    this.recordPageRequest.stringFilter = this.searchForm.get('filter').value;
    this.tableService.recordPageRequest = this.recordPageRequest;
  }
  //function after long press on card in mobile mode
  //since this function will execute before clickCard, it is only for select Mode controlling
  pressCard(){
    if(this.selectMode==false){
      this.tableService.selectMode = true ;
    }
  }

  clickCard(record:RecordObject ){
    if(!this.selectMode){
      (this.viewAction as (record?:RecordObject ) => void)(record);
      return;
    }
    if(this.selectMode){
      this.selectRecord(record);
      if(this.selected.length==0){
        this.tableService.selectMode = false ;
      }
      return;
    }
  }

  getRowClass(row:any){ //rowClass is an ngx-datatable object
    let isViewed = false;
    if(row.hasOwnProperty('documentType')){ //is document
      if(row.hasOwnProperty('metadata')){

        isViewed = row.metadata.viewed == true? true:false ; //viewed is viewed, not viewed is not viewed
      }else{
        isViewed = false ; //no metadata only mean it was never viewed
      }
    }else{ //not a document, default instant viewed
      isViewed = true ;
    }
    if(isViewed){
      return {'viewed':true}
    }else{
      return{'not-viewed':true}
    }
  }

  translateColumnText(){
    this.columns.forEach( column=>{ //column.name is a place holder for translated Value, it can'be the key, because it is the value
      this.translate.get(column.translateKey).subscribe( translatedValue =>{
        column.name = translatedValue ; //changing column of this.columns
      })
    })
  }

  async createAction() {
    const isDocumentRoute= this.helperService.isDocumentRoute(this.router.url.split('/')[1]);
    if(!isDocumentRoute){
      if (this.router.url ==='/users') {
        return this.showSignUpModal();
      }

      this.router.navigate(
        [('/'+this.router.url.split('/')[1]+`/${this.nextRoute}`)],
        {
          queryParams: {editMode: true},
          queryParamsHandling: "merge"
        }
      );
      return;
    }

    const createActionTradingPartnerModal = await this.modalController.create({
      component: CreateActionModalComponent,
    });
    createActionTradingPartnerModal.present();
    const { role } = await createActionTradingPartnerModal.onWillDismiss(); //no data it is passed with observables

    if(role=='apply'){
      this.modalController.dismiss();
    }
  }


  buildRecordPageRequest(activeRoute:string, defaultSort :DefaultSort):RecordPageRequest{
    let recordTypeName = routeRecordType[activeRoute];
    return {
      recordTypeName: recordTypeName,
      order: defaultSort.sortOrder,
      sortByField: defaultSort.fieldName,
      stringFilter: '',
      page: 0,
      pageSize: 20,
      filters:null
    };
  }

  addColumnPipes( columns: Array<ClientColumn>):Array<ClientColumn>{
    let columnWithPipes = columns ;
    columnWithPipes.forEach( column =>{
      if(column.type == 'datetime'){
        column = this.addDatetimePipe(column);
      }
      if( column.prop == 'exchangeStatus'){
        column = this.addExchangeStatusPipe(column);
      }
    })
    return columnWithPipes;
  }

  addDatetimePipe( column: ClientColumn ):ClientColumn{
    let columnDatetimePipe: UKDatePipe = new UKDatePipe('en_GB');
    column.pipe = columnDatetimePipe ;
    return column ;
  }


  addExchangeStatusPipe (column: ClientColumn):ClientColumn{
    let columnExchangeStatusPipe : ExchangeStatusPipe = new ExchangeStatusPipe();
    column.pipe = columnExchangeStatusPipe ;
    return column ;
  }

  isDocumentRecordType(): boolean {
    if (this.recordPageRequest) {
      return Object.values(DocumentRecordType).includes(this.recordPageRequest.recordTypeName as DocumentRecordType);
    }
    else {
      return false;
    }
  }

  searchBarKeypress(keyCode:any):void{
    if(keyCode===13){ //13 is the key Enter
      this.applyRecordPageRequest();
    }
  }

  countDocumentsPerTradingPartnership(dataset: Document[], tradingPartnershipId: TradingPartnershipSelector['_id']) {
    return dataset.filter(data => data.tradingPartnershipId === tradingPartnershipId).length;
  }

  disableTradingPartnershipSelectorsWithNoDocuments(): void {
    this.tradingPartnershipSelectors = this.tradingPartnershipSelectors.map(
      tradingPartnershipSelector => {
        const noOfRecords = this.countDocumentsPerTradingPartnership(this.rows as Document[], tradingPartnershipSelector._id);
        const isDisabled = (noOfRecords === 0) ? true : false;
        return {
            ...tradingPartnershipSelector,
            disabled: isDisabled
        }
      });
  }

  onTradingPartnershipSelected(updatedTradingPartnerSelection: TradingPartnershipSelector[]): void {
    this.tradingPartnershipSelectors = updatedTradingPartnerSelection;
    this.getRecords();
  }

  getSelectedTradingPartnerIds(): TradingPartnershipSelector['_id'][] {
    const noOfSelected = this.tradingPartnershipSelectors
      .filter(tradingPartnerSelector => !tradingPartnerSelector.disabled && tradingPartnerSelector.selected).length;

      if (noOfSelected === 0) {
        return this.tradingPartnershipSelectors
          .map(selectedTradingPartnership => selectedTradingPartnership._id);
      }

      return this.tradingPartnershipSelectors
      .filter(tradingPartnership => tradingPartnership.selected)
      .map(selectedTradingPartnership => selectedTradingPartnership._id);
  }

  filterRowsByDocumentParties(selectedCompanyId: Company['_id'], documentRows: Document[]): Document[] {
    return documentRows.filter(documentRow => {
      const documentTradingPartnership = this.tradingPartnershipService.reduceToPlainTradingPartnership(
        this.tradingPartnershipSelectors.find(tradingPartnershipSelector => tradingPartnershipSelector._id === documentRow.tradingPartnershipId));
      const recipientId =
        DocumentHelpers.determineRecipientId(documentRow.metadata.documentType, documentTradingPartnership);

      return !((selectedCompanyId === recipientId) && (documentRow.exchangeStatus === ExchangeStatus.Draft));
    });
  }
  async showSignUpModal() {
    const modal = await this.modalController.create({
      component: AccountActivationModalPage,
    });
    await modal.present();
  }

  dynamicField(fields: any, prop: string, feature: string, dynamicField: string): string{
    let dynamicFunc = new Function('fields', `return ${prop};`);
    let result: string = "";

    switch(feature.toUpperCase()){
      case "SUM":
        let arrays: any[] = dynamicFunc(fields);
        result = arrays.map(x=>x[dynamicField]).reduce((x,y)=>x+y).toString();
        break;
      default:
        break;
    }

    return result;
  }

  initializeTradingPartnershipSelector(): void {
    this.tradingPartnershipService
      .getTradingPartnershipSelectors(this.companyService.activeCompany.companyId)
      .pipe(takeUntil(this.destroy$)).subscribe(
        (tradingPartnershipSelectors) => {
          this.tradingPartnershipSelectors = tradingPartnershipSelectors;
          this.getRecords(); // TODO temporary fix - something's not right with the lifecycle of the init, tradingPartnershipSelectors gets empty when changing sidenav or company
        });
  }
}
