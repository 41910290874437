import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { take, exhaustMap } from 'rxjs/operators';

import { AuthService } from 'core/services';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.user$.pipe(take(1), exhaustMap(user => {
      if (!user) {
        return next.handle(req);
      }
      const authStr = `Bearer ${user.JWT}`;
      const modifiedReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
                            .set('Authorization', authStr)
      });
      return next.handle(modifiedReq);
    }));

  }
}
