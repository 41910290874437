import { Fields } from './document';
import { DocumentRecordType } from './recordTypes';
import { Ui } from './ui';

export interface DocumentRule {
  documentType: DocumentRecordType;
  childDocuments: Array<ChildDocument>;// empty array instead of null or undefined
  canBeRoot : boolean; //Blank document qualifier
  canDelete : boolean;
  uiId : Ui['_id']; //An ui for this tradingPartnership's document rule.
  defaultAutofillMethod : Array<AutofillSource>;//For import file
  autofillRules : Array<AutofillFieldRule>; //This document as child document
  sender: SourceParty ;
}   

export interface ChildDocument {
  documentType : DocumentRecordType;
  singleChild: boolean ;
}

export interface AutofillFieldRule {
  fieldName : string ;
  method : AutofillSource [];
  targetFieldName? : string ;
  collectionName ? : 'TradingPartnership'|'Company'|'PriceList'; //string literal according to schema
  fieldSourceType ? : FieldSourceType; //use only for Company Collection
}

export enum AutofillSource {
  Inherit = 0,
  Enrich = 1
}

export enum FieldSourceType {
  Buyer = 0 ,
  Supplier = 1
}

export interface DefaultFieldValue {
  fieldName: string;
  fieldValue: string;
  documentType?: DocumentRecordType; 
}

export enum SourceParty {
  Buyer = 0,
  Supplier = 1
}

export interface MethodResult{
  fields:Fields,
  fieldFound:boolean,//that only mean we can find the field properly
}