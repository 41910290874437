// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-permissions-item-container {
  --background: transparent;
}

.user-permissions-list-container {
  background-color: transparent;
}

.custom-border-card {
  border: 2px solid var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/users/user-management-view/user-management-view.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACI,wCAAA;AACJ","sourcesContent":[".user-permissions-item-container {\n    --background: transparent;\n  }\n\n.user-permissions-list-container {\n    background-color: transparent; \n}\n\n.custom-border-card {\n    border: 2px solid var(--ion-color-light); \n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
