import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { UserTradingPartnerModalPage } from 'shared/modals';
import { CompanyReference } from 'common/interfaces/company';
import { Observable } from 'rxjs';
import { UserPrivilege } from 'common/interfaces/privileges';


@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {

  @Input() editMode: boolean;
  @Input() companies$: Observable<CompanyReference[]>;
  @Input() addCompany$: Observable<CompanyReference>;
  @Input() userPrivileges: UserPrivilege[];
  @Input() allowDetailsView;

  companies: CompanyReference[] = [];

  constructor(public modalController: ModalController/* , private fieldContainerService: FieldContainerService */) { }

  ngOnInit() {
    this.companies$.subscribe(companies => {
      this.companies = companies;
    })
  }

  async showTradingPartner(company: CompanyReference) {
    const modal = await this.modalController.create({
      component: UserTradingPartnerModalPage,
      componentProps: {
        // userPrivilege: this.userPrivileges[0], // FIXME: Temporary 0th element until trading partners are implemented
        editMode: this.editMode,
        companyReference: company
      }
    });
    modal.onWillDismiss().then(modalResponse => {
      console.log(modalResponse);
      
      //this.onCompanyUpdate.emit(modalResponse.data.company);
      // this.userPrivileges[0] = modalResponse.data.userPrivilege; // FIXME: Temporary 0th element until trading partners are implemented

    });
    return await modal.present();
  }

  removeCompany(removedCompany: CompanyReference) {
    this.companies.forEach((company, index) => {
      if (removedCompany.companyId === company.companyId) {
        this.companies.splice(index, 1);
      }
    });
  }

  updateCompany(updatedCompany: CompanyReference) {
    const currentUserCompanyIndex = this.companies.findIndex(company => company.companyId === updatedCompany.companyId);
    this.companies[currentUserCompanyIndex] = updatedCompany;
  }
}