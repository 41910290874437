import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { getPlatforms, ModalController } from '@ionic/angular';

import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { AddProductsModalComponent } from 'shared/modals/add-products-modal/add-products-modal.component';
import { LineItemModalComponent } from 'shared/modals/line-item-modal/line-item-modal.component';
import { LineItem } from 'common/interfaces/lineItem';
import { combineLatest, Observable, Subject } from 'rxjs';
import { FieldContainerService } from '../field-container/field-container.service';
import { LineItemTableService } from './line-item-table.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationOption } from 'app/shared/common/interfaces/ui';
import { takeUntil } from 'rxjs/operators';
import { PriceListProduct } from 'app/shared/common/interfaces/priceList';

@Component({
  selector: 'line-item-table',
  templateUrl: './line-item-table.component.html',
  styleUrls: ['./line-item-table.component.scss'],
})
export class LineItemTableComponent implements OnInit, AfterViewInit {
  @ViewChild('datatable', { static: false }) table: DatatableComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  // TODO: remove partial when products are finished
  @Input() products$: Observable<(Partial<PriceListProduct> & { enabled?: boolean })[]>;
  @Input() lineItems$: Subject<Partial<LineItem>[]>;
  @Input() validators: ValidationOption[];



  products: (Partial<PriceListProduct> & { enabled?: boolean })[];
  lineItems: Partial<LineItem>[];


  @Input() editMode = false;

  loadingIndicator = true;
  reorderable = false;

  SelectionType = SelectionType;

  rows: Partial<LineItem>[] = [] as Partial<LineItem>[];
  selected: Partial<LineItem>[] = [] as Partial<LineItem>[];

  columnMode = ColumnMode;
  selectionType = SelectionType;
  destroy$ = new Subject<void>();
  selectMode = false;
  selectionOpen = false;

  editing = {};

  columns = [
    { name: 'LINE NO.', prop: 'lineNumber', translateKey:'LINENO', mobile: false },
    { name: 'BARCODENUMBER', prop: 'barcodeNumber', translateKey:'BARCODENUMBER', mobile: false },
    { name: 'SUPPLIERITEMCODE', prop: 'supplierItemCode', translateKey:'SUPPLIERITEMCODE', mobile: true },
    { name: 'QUANTITY', prop: 'quantity', translateKey:'QUANTITY', mobile: true },
    { name: 'CASESIZE', prop: 'caseSize', translateKey:'CASESIZE', mobile: false },
    { name: 'VATCODE', prop: 'vatCode', translateKey:'VATCODE', mobile: false },
    { name: 'DESCRIPTION', prop: 'description', translateKey:'DESCRIPTION', mobile: true },
    { name: 'PRICE', prop: 'price', translateKey:'PRICE', mobile: true }
  ];

  constructor(private modalController: ModalController, private fieldContainerService: FieldContainerService, private lineItemTableService: LineItemTableService, private translate: TranslateService) { }

  ngOnInit() {
    combineLatest([this.products$, this.lineItems$]).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.products = res[0];
      this.lineItems = Array.isArray(res[1]) ? res[1] : [];  // ensure that this.lineItems is always an array to stop error in console

      this.loadingIndicator = false;
      this.rows = [...this.lineItems];

      this.products?.forEach(product => {
        product.enabled = true;
        const itemAdded = this.lineItems.find(item => item.productId === product._id);
        if (itemAdded) {
          product.enabled = false;
        }
      });
      this.lineItemTableService.setLineItems(this.lineItems); //constant update since we use setlineitem to updates no matter what
    });

    this.translateColumnText();//translate instantly
    this.translate.onLangChange.subscribe( ()=>{
      this.translateColumnText(); //translate again each time lang change
    })
  }

  ngAfterViewInit(): void {
  }


  onSelect({ selected }): void {

    if (selected) {
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
    }
  }

  isDesktop(): boolean {
    return getPlatforms().includes('desktop');
  }

  onLineItemRemove() {
    // see https://stackoverflow.com/questions/4620880/javascript-arrays-of-objects-subtract-one-from-another
    // and https://en.wikipedia.org/wiki/Symmetric_difference
    // and server -> src/listeners/tradingPartners.ts:44
    this.lineItems = this.lineItems
      .filter(product => !this.selected
        .map(selectedItem => selectedItem.productId)
        .includes(product.productId));

    this.assignLineNumbers();

    this.selected.forEach(deletedProduct => {
      this.products.find((product, index) => {
        if (product._id === deletedProduct.productId) {
          this.products[index].enabled = true;
        }
      });
    });
    this.selected = []; // have to assign to empty because select all checkbox is buggy
    this.rows = [...this.lineItems];
    this.lineItemTableService.setLineItems(this.lineItems);
    this.lineItems$.next(this.lineItems);
  }

  assignLineNumbers() {
    this.lineItems.forEach((item, index) => {
      item.lineNumber = (index + 1);
    });
  }

  onClick(event) {
    //event will return empty string if click is targeting checkbox
    if (event.type ==='click'&&event.value!='') {
      this.openLineItemModal(event.row);
    }
  }
  async openLineItemModal(lineItem: Partial<LineItem>): Promise<void> {
    const modal = await this.modalController.create({
      component: LineItemModalComponent,
      componentProps: { lineItem, editMode: this.editMode },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && this.editMode) {
      this.updateLineItemFromModal(data);
    }
  }

  selectRecord(lineItem: Partial<LineItem>) {
    let isSelected = this.selected.some(x => x.productId === lineItem.productId);
    if (isSelected) {
      let selectedIndex = this.selected.findIndex(x => x.productId === lineItem.productId);
      this.selected.splice(selectedIndex, 1);
    } else {
      this.selected.push(lineItem)
    }
  }

  isSelected(lineItem: Partial<LineItem>): boolean {
    return this.selected.some(x => x.productId === lineItem.productId);
  }

  async onSelectionOpen() {
    // Symmetric difference of 2 arrays. To only include products not in line item table
    // https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript

    const notAddedProducts = this.products?.filter(product => !this.lineItems.map(lineItem => lineItem.productId).includes(product._id))
    const modal = await this.modalController.create({
      component: AddProductsModalComponent,
      componentProps: {
        products:notAddedProducts,
        lineItems: this.lineItems || []
      },
      cssClass: 'wide-modal'
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.addLineItemsFromModal(data.products);
    }
  }

  private addLineItemsFromModal(products: (Partial<PriceListProduct> & { enabled?: boolean, quantity?: number })[]) {
    products.forEach(product => {
      if (product.quantity > 0) {
        const newLineItem = {
          productId: product._id,
          supplierItemCode: product.supplierItemCode,
          barcodeNumber: product.barcodeNumber,
          caseSize: product.caseSize,
          description: product.description,
          vatCode: product.vatCode,
          quantity: product.quantity,
          price: product.price
        };
        this.lineItems.push(newLineItem);
      }
    });
    this.assignLineNumbers();
    this.rows = [...this.lineItems];
    this.lineItemTableService.setLineItems(this.lineItems);
    this.lineItems$.next(this.lineItems);
  }


  private updateLineItemFromModal(modalLineItem) {

    let itemIndex = this.lineItems.findIndex(item => item.productId === modalLineItem.productId);
    this.lineItems[itemIndex] = modalLineItem;
    this.rows = [...this.lineItems];

    this.fieldContainerService.lineItem = this.lineItems;

    this.lineItems$.next(this.lineItems);
  }

  translateColumnText(){
    this.columns.forEach( column=>{
      this.translate.get(column.translateKey).pipe(takeUntil(this.destroy$)).subscribe( translatedValue =>{
        column.name = translatedValue ; //changing column of this.columns
      })
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
