// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-expansion-panel {
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/user-trading-partner-modal/user-trading-partner-modal.page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":["mat-expansion-panel { // temporary, to be able to see the whole list of privileges\n  overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
