import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SignedUser } from 'shared/user';
import { User, UserCredentials } from 'common/interfaces/user';
import { CompanyAssociation } from 'app/shared/common/interfaces/companyAssociation';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<SignedUser>(null); 
  private _isAuth = false;

  // The timer should be of type NodeJS.Timer but the compiler freaks out
  private tokenExpirationTimer: any;

  constructor(private http: HttpClient, private router: Router) { 

  }

  public get isAuth(): boolean {
    return this._isAuth;
  }
  
  verifyToken(token:string):Observable<any>{
   return this.http.get<string>(`auth/verifyToken/${token}`);
  }

  public sendActivationLink(email: User['email'], companyId: string, isAdmin: CompanyAssociation['isAdmin']): Observable<string> {
    return this.http.post('auth/activateAccount', { email, companyId, isAdmin }, { responseType: 'text'  })
  }
  
  public isUserRegistered(token:string):Observable<{ email:string,registered:boolean}> {
    return this.http.get<{email:string,registered:boolean}>(`auth/isUserRegistered/${token}`);
  }

  public login(userCredentials: UserCredentials): Observable<{ user: User, token: string}> {
    return this.http.post('auth/signin', {userCredentials})
      .pipe(catchError(this.errorHandler), tap((resData: {user: User, token: string, expiresIn: number}) => {
      this._isAuth = true;
      const jwtExpirationDate = new Date(new Date().getTime() + resData.expiresIn);
      const user = new SignedUser(resData.user, resData.token, jwtExpirationDate);
      this.user$.next(user);
      this.autoLogout(resData.expiresIn);
      localStorage.setItem('user', JSON.stringify(resData.user));
      localStorage.setItem('token', JSON.stringify(resData.token));
      localStorage.setItem('expiration', JSON.stringify(jwtExpirationDate));
    }));
  }

  /**
   *  Verifies the user.
   *
   * @param token - sign up token to verify the user against
   * @param passwords - assigns the provided passwords to the new users
   * @returns An Observable of the response, with a response body of type string.
   */
  public verifySignupAccount(token: string, passwords: {password: string, repeatPassword: string}) {
    return this.http.post('auth/verify', {token, passwords}, { responseType: 'text'})
      .pipe(catchError(err => {
        return throwError(err);
      }));
  }

  /**
   * Tells the server to resend the token to the user. Will generate a newsign up token
   *
   * @param email User email
   * @returns An Observable of the response, with a response body of type string.
   */
  public resendToken(email: string) {
    return this.http.post('auth/resendToken', {email}, { responseType: 'text'})
      .pipe(catchError(err => {
        return throwError(err);
      }));
  }

  /**
   *
   *  Sends a password reset request to the server
   * @param email User email
   * @returns An Observable of the response, with a response body of type string.
   */
  public resetPassword(email: string) {
    return this.http.post('auth/resetPassword', {email}, {responseType: 'text'})
      .pipe(catchError(err => {
        return throwError(err);
      }));
  }

  /**
   * Used for persistent login on refresh
   * @returns Boolean value, whether the user is found in localStorage
   */
  autoLogin(): boolean {
    const userData: { user: User, token: string, jwtExpirationDate: string } = { user: JSON.parse(localStorage.getItem('user')),
                                                                                 token: JSON.parse(localStorage.getItem('token')),
                                                                                 jwtExpirationDate: JSON.parse(localStorage.getItem('expiration')) };

    if (!userData.user) {
      return false;
    }

    const loadedUser = new SignedUser(userData.user, userData.token, new Date(userData.jwtExpirationDate));

    if (loadedUser.JWT) {
      this._isAuth = true;
      this.user$.next(loadedUser);
      // ExpirationDuration is calculated by taking JWT expiration datetime value and subtracting current datetime value
      const expirationDuration = new Date(userData.jwtExpirationDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
      return true;
    }
  }

  isSameUser(userId: string) {
    return this.user$?.value?._id === userId;
  }

  /**
   *
   * @returns true if the active user is a SuperAdmin
   *
   */
  public isSuperAdmin(){
    return this.user$?.value?.isSuperAdmin === true;
  }

    /**
   *
   * @returns true if the active user is a CompanyAdmin
   *
   */
     public isCompanyAdmin(){
      return this.user$?.value?.isSuperAdmin === true; // TODO:temporary use of: superAdmin, change this to admin
    }

  /**
   * Logs the user out when the time runs out on their JWT
   *
   * @param expirationDuration Time in ms after which the user will be logged out
   */
  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  /**
   * Clears the user observable, logging current user out.
   * Redirects back to the login page
   */
  logout() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer); // clears logout timer set in autoLogout when user logs out
    }
    this.tokenExpirationTimer = null;
    this._isAuth = false;
    this.user$.next(null); 
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    this.router.navigate(['/login']);
  }

  public changePassword(userId: string, passwords: object) {
   return this.http.post('auth/changePassword', { userId, passwords }, { responseType: 'text' })
   .pipe(catchError(err => {
     return throwError(err);
   }));
  }

  private errorHandler(errorRes: HttpErrorResponse) {
    let errorMessage = 'Unknown Error';

    switch (errorRes.status) {
      case 404:
      case 401:
        errorMessage = 'Invalid email or password';
        break;
      default:
        return throwError(errorMessage);
    }
    return throwError(errorMessage);
  }
}
