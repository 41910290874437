import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ActionBarService } from 'app/core/services';
import { Action } from 'app/shared/common/actions';
import { User } from 'app/shared/common/interfaces/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '../users.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { AccountActivationModalPage } from 'app/shared/modals';

@Component({
  selector: 'app-user-management-view',
  templateUrl: './user-management-view.component.html',
  styleUrl: './user-management-view.component.scss'
})
export class UserManagementViewComponent {
  user:User;
  userForm:FormGroup;
  editMode: boolean;
  duplicate: boolean;
  destroy$ = new Subject<void>();

  constructor(
    public modalController: ModalController,
    private route:ActivatedRoute,
    private router:Router,
    private navController: NavController,
    private userService: UsersService,
    private actionBarService:ActionBarService,
  ) 
  {}

  ngOnInit() {
    this.userForm = new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      email: new FormControl(),
    })
  }

  ionViewWillEnter() {
    this.actionBarService.buttonEventSubscription.pipe(takeUntil(this.destroy$)).subscribe(event => {
      switch (event) {
        case Action.edit:
          this.editAction();
          break;
        case Action.save:
          this.saveAction();
          break;
        case Action.delete:
          this.deleteAction();
          break;
        case Action.duplicate:
          this.duplicateAction();
          break;
          case Action.create:
            this.createAction();
            break;
        default:
          break;
      }
    });

    this.actionBarService.backButtonEventSubscription.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.backAction();
    });
  }

  ionViewDidEnter(): void {
    this.route.queryParams.subscribe((params:Params)=>{
      this.editMode = params.editMode === 'true';
      this.duplicate = params.duplicate === 'true';
      if (params.id) {
        this.loadUserProfile(params);
      }
    })
  }

  loadUserProfile(params:Params) {
    this.userService.getUser(params.id).pipe(takeUntil(this.destroy$))
    .subscribe(user =>{
      this.user = user;
      this.userForm.patchValue(user)
    })
  }
  
  ionViewWillLeave() {
    this.user = null;
  }

  createAction() {
    this.showSignUpModal();
    
  }
  async showSignUpModal() {
    const modal = await this.modalController.create({
      component: AccountActivationModalPage,
    });
    await modal.present();
  }

  changeEditMode(editMode: boolean): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { editMode },
      queryParamsHandling: 'merge',
    });
  }
  
  backAction(): void {
    this.navController.back();
  }
  
  editAction(): void {
    this.changeEditMode(true);
  }
  
  private saveAction(): void {};
  
  duplicateAction() {
    this.router.navigate(['users', 'management'], { queryParams: { id: this.user._id, editMode: true, duplicate: true } }).then(() => {
      window.location.reload();
    });
  };
  
  deleteAction(): void {
    this.userService.deleteUsers([this.user._id]).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.router.navigate(['/users']);
    });
  };

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
    };

};