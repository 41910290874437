import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { ModalController} from '@ionic/angular';
import { ValidatorService } from 'app/core/services/validator.service';
import { AuthService } from 'core/services';
import { FieldGroup, FormField, Ui  } from 'app/shared/common/interfaces/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.page.html',
  styleUrls: ['./change-password-modal.page.scss'],
})
export class ChangePasswordModalPage implements OnInit {
  @Input() userId: string;
  ui: Partial<Ui>;
  fieldGroups: FieldGroup<FormField>[];
  passwordFormGroup: FormGroup;
  destroy$ = new Subject<void>();
  constructor(public modalController: ModalController, private authService: AuthService, private formBuilder: FormBuilder, private validatorService: ValidatorService ) { }

    ngOnInit() {
      this.fieldGroups = [ // render the changePasswordModal ui
        {
          name: 'passwordFormUI',
          groupColumnSize:1,
          fieldMaxColumnSize: 1,
          fields: [
            {
              name: 'oldPassword',
              label: 'OLDPASSWORD',
              type: 'input',
              inputType: 'password',
              placeholder: 'OLDPASSWORD',
              position: 'floating',
              clearOnEdit:false, // doesn't clear password input when typing again
              toggleIcon: {
                active: 'eye-off-outline',
                inactive: 'eye-outline',
                iconClicked: false,
              },
              fieldColumnSize: 1,
              validators: [
                {name:'required',errorType:'required',message:'Password is required'},
              ],
            },
            {
              name: 'newPassword',
              label: 'NEWPASSWORD',
              type: 'input',
              inputType: 'password',
              position: 'floating',
              clearOnEdit:false,
              toggleIcon: {
                active: 'eye-off-outline',
                inactive: 'eye-outline',
                iconClicked: false,
              },
              fieldColumnSize: 1,
              validators: [
              {name:'required',errorType:'required',message:'Please Enter a Password'},
              {name:'minlength',errorType:'minlength', value:'2', message:`Must be at least 2 characters long`},
              ],
            },
            {
              name: 'repeatPassword',
              label: 'REPEATPASSWORD',
              type: 'input',
              inputType: 'password',
              position: 'floating',
              clearOnEdit:true, // clears password input when typing again
              toggleIcon: {
                active: 'eye-off-outline',
                inactive: 'eye-outline',
                iconClicked: false,
              },
              fieldColumnSize: 1,
              validators: [
                {name:'required',errorType:'required',message:'Please Enter a Password'},
              ],
            }

          ]
        }
      ]

        this.ui= { // create ui object to apply the formGroup validation
          uiTabs: [{
            name: '',   // name & panel not required for <app-field-container>
            panel: null, // name & panel not required for <app-field-container>
            groupMaxColumnSize:1,
            fieldGroups: this.fieldGroups// setValidatorFromUI() function checks inside the fieldGroups to apply the formGroup validations
            }],
            formValidators: [{ // this is another type of formGroup validations, @input the value from html e.g [formValidators]="ui.formValidators"
              name: 'mustMatch',
              fields: ['newPassword', 'repeatPassword'],
              errorType: 'mustMatch',
              message: 'Passwords do not match',
            }],
          };
        this.passwordFormGroup = this.formBuilder.group({
          oldPassword: [''],
          newPassword: [''],
          repeatPassword: [''],
        })
        this.passwordFormGroup = this.validatorService.setValidatorFromUI(this.passwordFormGroup, this.ui); // set the formGroup validation for changePasswordModal
    }

  onSubmit() {
    const passwords = this.passwordFormGroup.value;
    const oldPasswordControl = this.passwordFormGroup.get('oldPassword');

    if (this.passwordFormGroup.invalid) {     // Form is invalid; don't proceed with the request
      this.passwordFormGroup.markAllAsTouched();
      return;
    }
    
    this.authService.changePassword(this.userId, passwords).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.modalController.dismiss();
      },
      error: (error) => {
        if (error) {
          oldPasswordControl.markAsTouched(); // Mark old password as touched
          oldPasswordControl.setValue('');    // Clear the old password field
        }
      },
    });
  }

  onCancel() {
    this.modalController.dismiss();
  }

  ionViewWillLeave() {
    this.passwordFormGroup.reset();
  }

  ngOnDestry() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
