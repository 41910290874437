// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-label-information {
  font-size: 12px;
  color: grey;
}

.radio-label-alert {
  font-size: 12px;
  color: red;
  opacity: 1;
}

.radio-label-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: start; /* Align items to the start */
}

.radio-header {
  font-size: large;
}

.radio-header-alert {
  font-size: small;
}

.radio-label-information-parent-selected {
  font-size: 12px;
  color: #0163aa;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/create-action-modal/create-action-modal.page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EAAiB,WAAA;AAEnB;;AACA;EACE,eAAA;EAAiB,UAAA;EAAa,UAAA;AAIhC;;AAAA;EACE,aAAA;EACA,sBAAA,EAAA,2BAAA;EACA,kBAAA,EAAA,6BAAA;AAGF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,eAAA;EAAiB,cAAA;AAInB","sourcesContent":[".radio-label-information {\n  font-size: 12px; color: grey;\n}\n\n.radio-label-alert {\n  font-size: 12px; color: red ; opacity: 1;\n\n}\n\n.radio-label-container {\n  display: flex;\n  flex-direction: column; /* Stack items vertically */\n  align-items: start; /* Align items to the start */\n}\n\n.radio-header {\n  font-size: large;\n}\n\n.radio-header-alert{\n  font-size: small;\n}\n\n.radio-label-information-parent-selected{\n  font-size: 12px; color:#0163aa;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
