import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TradingPartnership, ExtendedTradingPartnership, ReferencedTradingPartnership, TradingPartnershipSelector, CreateActionModalTradingPartnership } from 'app/shared/common/interfaces/tradingPartnership';
import { forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AssetsService } from './asset.service';

@Injectable({
  providedIn: 'root'
})
export class TradingPartnershipService {
  destroy$ = new Subject<void>();
  
  constructor(
    private assetService: AssetsService,
    private http: HttpClient, 
    public router: Router) {}

  public tradingPartnership$ = new ReplaySubject<TradingPartnership>();

  private currentTradingPartnership$ = new Subject<TradingPartnership>; //TODO: This tradingPartnership work with tradingPartnership selector
  public emitTradingPartnership(tradingPartnership:TradingPartnership|null) { 
    this.currentTradingPartnership$.next(tradingPartnership);
  }
  public currentTradingPartnership = this.currentTradingPartnership$.asObservable();

  public listExtendedTradingPartnerships(companyId:string, extendData: boolean = false): Observable<{records: TradingPartnership[] | ExtendedTradingPartnership[]; total: number}>{
    const parameterString = extendData ? '/?extendData' : '';
    return this.http.get<{records: TradingPartnership[] | ExtendedTradingPartnership[]; total: number}>(`tradingPartnership/list/extended/${companyId}${parameterString}`) ;
  }
  
  public listReferencedTradingPartnerships( companyId:string ):Observable <{ records: ReferencedTradingPartnership [] ; total: number }>{
    return this.http.get<{records:ReferencedTradingPartnership[]; total: number}>(`tradingPartnership/list/referenced/${companyId}`) ;
  }

  public getTradingPartnership(id:TradingPartnership['_id']):Observable<TradingPartnership>{
    return this.http.get<TradingPartnership>(`tradingPartnership/${id}`);
  }

  public getTradingPartnershipSelectors(companyId: TradingPartnership['supplierCompanyId']): Observable<TradingPartnershipSelector[]> {
    // Use switchMap with forkJoin to fetch company logo (getAsset()) to each TP
    return this.listReferencedTradingPartnerships(companyId)
    .pipe(
      switchMap(tradingPartnerships => {
        const tradingPartnershipSelectors$: Observable<TradingPartnershipSelector>[] = 
          tradingPartnerships.records.map(tradingPartnership => {
            // Partial TradingPartnerSelector to be extended with logo below
            let tradingPartnershipSelector = {
              ...tradingPartnership,
              selected: false,
              disabled: false,
            };

            // Set logo if property exists (rxjs map(getAsset)), or set it to null (rxjs of()) if not
            return (tradingPartnership.buyerReference.companyLogoAssetId) ? 
              this.assetService.getAsset(tradingPartnership.buyerReference.companyLogoAssetId).pipe(
                map(asset => ({
                  ...tradingPartnershipSelector, 
                  logo: (asset.data) ? `data:${asset.contentType};base64,${asset.data}` : null
                })),
                takeUntil(this.destroy$)
              ) : of({
                ...tradingPartnershipSelector, 
                logo: null
              });            
          });

        return forkJoin(tradingPartnershipSelectors$);
      }),
      takeUntil(this.destroy$)
    );        
  } 

  public reduceToPlainTradingPartnership(extendedTradingPartnership: ReferencedTradingPartnership | ExtendedTradingPartnership | TradingPartnershipSelector | CreateActionModalTradingPartnership): TradingPartnership {
    return {
      _id: extendedTradingPartnership._id,
      supplierCompanyId: extendedTradingPartnership.supplierCompanyId,
      supplierLocationNumber: extendedTradingPartnership.supplierLocationNumber,
      supplierAccountCode: extendedTradingPartnership.supplierAccountCode,
      buyerCompanyId: extendedTradingPartnership.buyerCompanyId,
      buyerLocationNumber: extendedTradingPartnership.buyerLocationNumber,
      buyerAccountCode: extendedTradingPartnership.buyerAccountCode,
      documentRules: extendedTradingPartnership.documentRules,
      defaultVatRate: extendedTradingPartnership.defaultVatRate,
      autofillData: extendedTradingPartnership.autofillData,
      priceListAssignments: extendedTradingPartnership.priceListAssignments,      
    }
  }
}