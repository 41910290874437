import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ui } from 'app/shared/common/interfaces/ui';
import { Observable } from 'rxjs';
import { DatabaseField, FormField } from 'app/shared/common/interfaces/ui';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private http: HttpClient) { }
  
  getUiDataByCompanyAndType(companyId: string, recordType: string, relatedRecordType?: string):Observable<Ui>{
    let url = `ui/recordType/${companyId}/${recordType}`; 
    url = relatedRecordType? url+ "/"+ relatedRecordType: url; 
    return this.http.get<Ui>(url); // filter action popover is using this to fetch the filter ui
  }

  getUi(uiId:Ui['_id']):Observable<Ui> {
    return this.http.get<Ui>(`ui/${uiId}`);
  }
  getDocumentMetadataUi(documentId: string ){
    let url = `ui/metadata/${documentId}` ;
    return this.http.get<Array<FormField|DatabaseField>>(url);
  }

}
