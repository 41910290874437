import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { RecordPageRequest } from '../common/interfaces/search';
import { RecordObject  } from '../common/interfaces/global';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }

  getRecords(recordPageRequest: RecordPageRequest, lookupIds: string[]): Observable<{records: RecordObject []; total: number;}> {
    return this.http.post<{records: RecordObject []; total: number}>('search/list/', { recordPageRequest, lookupIds });
  }

  //this api is over riding for table to called instead of filter popover
  applyFilterSearch(recordPageRequest: RecordPageRequest, lookupIds: string[]): Observable<{records: RecordObject []; total: number;}> { 
    return this.http.post<{records: RecordObject []; total: number}>('search/filter/', { recordPageRequest, lookupIds });
  }
}