import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Router } from '@angular/router';
import { RecordObject  } from 'app/shared/common/interfaces/global';
import { RecordPageRequest} from 'app/shared/common/interfaces/search';
import { FilterGroup } from 'app/shared/common/interfaces/filter';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
}) // this file is rename to filter service because only filter is dealt with here.
export class FilterService {
  //public lastActiveRoute :string ; //given by table component ionViewDidEnter hook

  private filterGroups$ = new ReplaySubject<Array<FilterGroup>>();
  public readonly filterGroupSubscription = this.filterGroups$.asObservable();

  //Result that returns from filter either disabled or applies 
  private filterResult$ = new ReplaySubject<{records: RecordObject []; total: number}>();
  public readonly filterResultSubscription = this.filterResult$.asObservable();

  constructor(private http: HttpClient, public router: Router) {
  }

  //get filterGroups by id and redordType, no relatedRecordType needed becaause this is a filter only route
  public getFilterGroups(userId:string ){
    let activeRecord = this.router.url.split('/')[1] ; 
    this.http.get<Array<FilterGroup>>(`filter/${userId}/${activeRecord}`).subscribe( filterGroups =>{
      this.filterGroups$.next( filterGroups );
    });
  }

  removeFilter( filterGroupId: string) {
    return this.http.delete(`filter/${filterGroupId}`,{responseType:'text'});
  }

  addFilter( filterGroup: FilterGroup) {
    return this.http.post('filter/',{filterGroup}, {responseType: 'text'});
  }

  editFilter( filterGroup:FilterGroup){
    return this.http.put('filter/', {filterGroup},{responseType:'text'});
  }

  //TODO : API method refactoring here, post to get
  //call api and return then call event to update table records
  applyFilterSearch(recordPageRequest:RecordPageRequest,companyId:string):void {
    this.http.post<{records: RecordObject []; total: number}>('search/filter/', {recordPageRequest,companyId}).subscribe( filterResult=>{
      this.filterResult$.next(filterResult);
    });
  }

  //TODO : API method refactoring here, post to put
  //call api with no filter to reset table records
  disableFilterSearch(recordPageRequest:RecordPageRequest,companyId:string):void{
    this.http.post<{records: RecordObject []; total: number}>('search/list/', {recordPageRequest,companyId} ).subscribe( filterResult=>{
      this.filterResult$.next(filterResult);
    });
  }

}
