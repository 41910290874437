import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormValidationOption, UiTab  } from 'app/shared/common/interfaces/ui';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnChanges {

  @Input() uiTabs:UiTab[]
  @Input() editMode: boolean;
  @Input() formGroup: FormGroup;
  @Input() formValidators: FormValidationOption[];

  type:string;

  constructor() { }

  ngOnInit() {
    if (this.uiTabs && this.uiTabs.length > 0) {
      this.type = this.uiTabs[0]?.name;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ui && changes.ui.currentValue && changes.ui.currentValue.length > 0) {
      this.type = changes.ui.currentValue[0].name;
    }
  }

  public segmentChanged(event) {
    this.type = event.detail.value;
  }

}
