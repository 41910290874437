// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: 0;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

mat-panel-title, mat-panel-description {
  align-items: center;
}

.mat-expansion-panel {
  border-radius: 0 !important;
}

ion-icon {
  width: 24px;
  height: 24px;
}

::ng-deep .mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover {
  background: #f5f5f5 !important;
  border-bottom: 2px solid #e8e8e8;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/expansion-panel/expansion-panel.component.scss"],"names":[],"mappings":"AAAA;;EAEE,aAAA;AACF;;AAEA;EACE,8BAAA;EACA,mBAAA;AACF;;AAGA;EACE,mBAAA;AAAF;;AAIA;EACE,2BAAA;AADF;;AAMA;EACE,WAAA;EACA,YAAA;AAHF;;AAOA;EACE,0BAAA;EACA,2BAAA;AAJF;;AAOA;;EAEE,8BAAA;EACA,gCAAA;AAJF","sourcesContent":[".mat-expansion-panel-header-title,\n.mat-expansion-panel-header-description {\n  flex-basis: 0;\n}\n\n.mat-expansion-panel-header-description {\n  justify-content: space-between;\n  align-items: center;\n}\n\n// centers title and description vertically\nmat-panel-title, mat-panel-description {\n  align-items: center;\n}\n\n// removes rounded corners around panel\n.mat-expansion-panel {\n  border-radius: 0 !important;\n  // box-shadow: unset;\n}\n\n// fixes the size of the icon because ion-icon is too small by default\nion-icon {\n  width: 24px;\n  height: 24px;\n}\n\n// removes horizontal padding from around panel body contents\n::ng-deep .mat-expansion-panel-body { // using ng-deep until a better solution is found; see https://stackoverflow.com/questions/53195131/angular-material-mat-expansion-panel-body-style-not-being-applied/57173921\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n}\n\n.mat-expansion-panel-header.mat-expanded,\n.mat-expansion-panel-header.mat-expanded:hover {\n  background: #f5f5f5 !important; // TODO: do this better when theming\n  border-bottom: 2px solid #e8e8e8; // TODO: do this better when theming\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
