// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/change-password-modal/change-password-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACJ","sourcesContent":[".centered-buttons-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px; \n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
