import { Pipe, PipeTransform } from '@angular/core';
import { ExchangeStatus } from '../common/interfaces/documentStatus';

@Pipe({
  name: 'exchangeStatus'
})
export class ExchangeStatusPipe implements PipeTransform {
  transform(value: ExchangeStatus): string {
    return ExchangeStatus[value];
  }
}
