import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController, Platform, IonMenu, IonSelect, MenuController  } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'app/company/company.service';
import { AuthService, SelectCompanyService } from 'app/core/services';
import { AppPage, CompanyReference, SidenavConfig } from 'app/shared/common/interfaces/company';
import { FieldGroup, FormField } from 'app/shared/common/interfaces/ui';
import { SignedUser } from 'app/shared/user';
import { Subject, Subscription } from 'rxjs';
import { addOutline, createOutline } from 'ionicons/icons';
import {addIcons} from "ionicons";
import { filter, map, switchMap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @ViewChild('languageSelect', { static: false }) languageSelect: IonSelect;
  @Input() isAuthenticated;

  sidenavForm: FormGroup;
  fieldGroups: FieldGroup<FormField>[];

  public selectedIndex = 0;

  user: SignedUser;
  companyName = new FormControl();

  selectedCompany: CompanyReference;
  companyReferences: CompanyReference[];

  // cache data for user to change between disable company and enable one
  lastRoutes: Array<{id:string,index:number}> = [];
  destroy$ = new Subject<void>();
  // isAuthenticated = false;

  public userSubscription: Subscription;

  //suspecting that this appPages should not be static?
  appPages : Array<AppPage> ; 


  constructor(
    public authService: AuthService, 
    private companyService: CompanyService, 
    private menu: IonMenu, 
    private menuController: MenuController,
    private navController: NavController, 
    private platform: Platform, 
    private router: Router, 
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, 
    private selectCompanyService: SelectCompanyService, 
    private translate: TranslateService,
  ) {
    this.initializeApp();
    addIcons({addOutline, createOutline});
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.show();
    });
  }

  ngOnInit() {
    this.authService.user$.pipe(
      filter(user => !!user), // Only continue if there's a valid user
      switchMap(user => {
        this.user = user;
        this.isAuthenticated = !!user;

        return this.companyService.getCompanyReferences();
      })
    ).subscribe(companyReferences => {
        this.companyReferences = companyReferences;
        this.selectedCompany = companyReferences[0];

        this.updateSidenav(this.selectedCompany);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //click event on side nav ['order','invoice'...etc]
  onClick(i: number) {
    this.selectedIndex = i;

    //route to page by appPages array above
    this.companyService.getCompanyReferences().pipe(takeUntil(this.destroy$)).subscribe(companies=>{
      let company = companies.find( company=> company.companyId == this.companyService.activeCompany.companyId) ;
      if(company.disabled&& !(this.authService.isSuperAdmin()) ){
        this.selectedIndex = -1;// -1 unset any selected route
        this.navController.navigateRoot(['/company/disabled']);
      }else{
        this.navController.navigateRoot([this.appPages[i].url]);
      }
    });
  }

  translateMenuText(){
    if(this.appPages){
      this.appPages.forEach(x=>{
        this.translate.get(x.translateKey).pipe(takeUntil(this.destroy$)).subscribe((text:string)=>{
            x.title = text;
        });
      });
    }else{
      throw new Error ('AppPages translation failed, appPages not found.');
    }
  }

  onLangClick(lang: string){
    this.translate.use(lang);
    this.translateMenuText();
  }
  
  dismissMenu() {
    this.menu.close();
  }

  viewAccount() {
    this.dismissMenu(); // Dismiss the side menu
    this.router.navigate(['/users/form'], { queryParams: { id: this.user._id }});
  }

  viewCompany() {
    if (this.selectedCompany) {
      this.dismissMenu();
      this.router.navigate(['/company/form'], {queryParams: {id: this.selectedCompany.companyId, editMode: false}});
    }
    return;
  }

  logout() {
    this.menuController.enable(false);
    this.menuController.swipeGesture(false);
    this.authService.logout();    
  }

  //When a side nav change current company.
  assignCurrentCompany(companyReference: CompanyReference) {
    if(this.companyService.activeCompany) {
      this.cacheLastRoute(this.companyService.activeCompany.companyId,this.router.url.split('/')[1]);
    }

    this.selectedCompany = companyReference;
    this.companyService.activeCompany = this.selectedCompany;

    // Maybe review this?
    // TODO: this is not a proper way to disable doc types for disabled company. Will change in future cycles
    this.companyService.getCompanyReferences().pipe(takeUntil(this.destroy$)).subscribe(companies=>{ //this component is using user company which had no disable properties
      let company = companies.find( company=> company.companyId == this.companyService.activeCompany.companyId) ;
      if(company?.disabled){
        this.navController.navigateForward(['/company/disabled']);
        this.selectedIndex  = -1 ;
        return; //instantly goes to disabled page if disabled
      }
      this.selectCompanyService.selectCompanyEvent(company);// getRecords in table when change company
      let lastRoute = this.lastRoutes.find(route=> route.id==company.companyId) ;
      let nextRoute ;
      if(lastRoute == undefined){
        nextRoute = [''];
        this.selectedIndex = -1;
      }else{
        nextRoute = this.appPages[lastRoute.index].url  ;
        this.selectedIndex = lastRoute.index ; // update css
      }
      this.navController.navigateForward(nextRoute);
    });
  }

  //cache the last valid route for enabled company.
  cacheLastRoute(id:string, route:string) {
    let index = this.appPages.findIndex( page => page.url.split('/')[1] == route);

    if(index == -1) {
      return; // appPage don't have this route, don't cache
    }
    let routeToCache = {id: id, index: index};
    let routeToReplace = this.lastRoutes.findIndex(route=> route.id==id);
    if(routeToReplace>-1){
      this.lastRoutes[routeToReplace] = routeToCache ;
      return;
    }
    this.lastRoutes.push(routeToCache);
  }

  //run only each time company is changed
  renderSidenavConfigs(sidenavConfigs: Array<SidenavConfig>) {
    let appPages : Array<AppPage> = [] ; 

    sidenavConfigs.forEach(sidenavConfig=>{
      appPages.push(sidenavConfig.appPage); 
    })

    this.appPages = appPages ;
    this.translateMenuText();
  }

  updateSidenav(selectedCompany: CompanyReference) {
    this.companyService.getSidenavConfigs(selectedCompany.companyId).pipe(
      takeUntil(this.destroy$),
      map(sidenavConfigs => ({ selectedCompany, sidenavConfigs }))
    ).subscribe({
      next: ({ selectedCompany, sidenavConfigs }) => {
        this.companyService.sidenavConfigs$.next(sidenavConfigs);
        this.renderSidenavConfigs(sidenavConfigs);

        if (selectedCompany) {
          this.selectedCompany = selectedCompany;
          this.assignCurrentCompany(selectedCompany);
        }
      }
    });    
  }
}