import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

import { Company, CompanyReference, SidenavConfig } from 'common/interfaces/company';
import { User } from 'common/interfaces/user';
import { CompanyAssociation } from 'app/shared/common/interfaces/companyAssociation';
import { Ui } from 'app/shared/common/interfaces/ui';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  // Company last selected in the sidenav
  private _activeCompany: CompanyReference;
  sidenavConfigs$ = new ReplaySubject<SidenavConfig[]>(1);

  constructor(private http: HttpClient, ) { }

  getCompany(id: string): Observable<Company> {
    return this.http.get<Company>(`company/${id}`);
  }

  getCompanyReferences(): Observable<CompanyReference[]> {
    return this.http.get<CompanyReference[]>('company/list/');
  }

  createCompany(company: Company): Observable<string> {
    return this.http.post('company', {company}, {responseType: 'text'});
  }

  updateCompany(company: Company): Observable<string> {
    return this.http.put('company', {company}, {responseType: 'text'});
  }

  deleteCompany(companyId: string): Observable<string> {
    return this.http.request('DELETE', 'company/delete', {body: {companyIds: [companyId]}, responseType: 'text'});
  }

  assignUserToCompany(userId: User['_id'], companyId: CompanyReference['companyId'], isAdmin:CompanyAssociation['isAdmin']): Observable<Company | User> {
    return this.http.post<User>('company/addUserToCompany',{userId, companyId, isAdmin});
  }
  
  listUserAssociatedCompanies(): Observable<CompanyAssociation[]> {
    return this.http.get<CompanyAssociation[]>('companyAssociation/list', );
  }
  /**
   * Return a list of companies for the user or the super admin depending on the user account type
   *
   * Providing a company id allows returning only unassigned as trading partner companies
   *
   */

  getSidenavConfigs(companyId: CompanyReference['companyId'] ): Observable<SidenavConfig[]>{
    return this.http.get<SidenavConfig[]>(`company/sidenavConfigs/${companyId}`);
  }

  get activeCompany(): CompanyReference {
    return this._activeCompany;
  }

  set activeCompany(company: CompanyReference) {
    this._activeCompany = company;
  }

  ui:Omit<Ui,'_id'> = { // company ui
    uiTabs: [
      {
        name: 'company-details',
        panel: {
          title: 'COMPANYDETAILS',
          description: 'COMPANYDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'general-company-details',
            label: 'GENERALCOMPANYDETAILS',
            fieldMaxColumnSize: 1,
            groupColumnSize:1,
            fields: [
              {
                name: 'companyName',
                label: 'COMPANYNAME',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'COMPANYNAMEREQUIRED'},
                ]
              }
            ]
          },
          {
            name: 'field-group-vatNumber',
            label: 'GROUPVATNUMBER',
            groupColumnSize:1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'vatNumber',
                label: 'VATNUMBER',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'VATNUMBERREQUIRED'},
                ]
              }
            ]
          },
          {
            name: 'field-group-companyAddress',
            label: 'GROUPCOMPANYADDRESS',
            groupColumnSize:1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'addressLine1',
                label: 'ADDRESSLINE1',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'ADDRESSLINEREQUIRED'},
                ]
              },
              {
                name: 'addressLine2',
                label: 'ADDRESSLINE2',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'addressLine3',
                label: 'ADDRESSLINE3',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'addressLine4',
                label: 'ADDRESSLINE4',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'postcode',
                label: 'POSTCODE',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'POSTCODEREQUIRED'},
                ]
              },
              {
                name: 'countryCode',
                label: 'COUNTRYCODE',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'COUNTRYCODEREQUIRED'},
                ]
              }
            ]
          },
          {
            name: 'company-referral-code',
            label: 'COMPANYREFERRALCODE',
            groupColumnSize: 1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'vatNumber',
                label: 'VATNUMBER',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'companyIdentifiers',
                label: 'COMPANYIDENTIFIERS',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              }
            ]
          }
        ]
      },
      {
        name: 'company-settings',
        panel: {
          title: 'COMPANYSETTINGS',
          description: 'SETTINGSDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'company-action-setting',
            label: 'COMPANYACTIONSETTING',
            fieldMaxColumnSize: 1,
            groupColumnSize:1,
            fields: [
              {
                name: 'vatRate',
                label: 'VATRATE',
                type: 'select',
                options: [],
                fieldColumnSize: 1,
              },
              {
                name: 'exportEnabled',
                label: 'EXPORTENABLED',
                type: 'toggle',
                fieldColumnSize: 1,
              }
            ]
          },
          {
            name: 'company-identity-setting',
            label: 'COMPANYIDENTITYSETTING',
            fieldMaxColumnSize: 1,
            groupColumnSize:1,
            fields: [
              {
                name: 'companyType',
                label: 'COMPANYTYPE',
                type: 'select',
                options: [],
                fieldColumnSize: 1,
              },
              {
                name: 'disabled',
                label: 'DISABLED',
                type: 'toggle',
                fieldColumnSize: 1,
                superAdminOnly: true,
              }
            ]
          },
        ]
      },
      {
        name: 'email-alerts',
        panel: {
          title: 'EMAILALERTS',
          description: 'EMAILALERTSSDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: []
      },
      {
        name: 'default-privileges',
        panel: {
          title: 'DEFAULTPRIVILEGES',
          description: 'DEFAULTPRIVILEGESSDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: []
      },
      {
        name: 'trade-partners',
        panel: {
          title: 'TRADINGPARTNERS',
          description: 'TRADINGPARTNERS',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'field-group-tradingPartner',
            label: '',
            fieldMaxColumnSize:1,
            groupColumnSize:1,
            fields: [
              {
                name: 'tradingPartners',
                label: 'TRADINGPARTNERS',
                type: 'tradingPartners',
                readonly: false,
                fieldColumnSize: 1,
                tradingPartners: []
              }
            ]
          }
        ]
      },
      {
        name: 'counters',
        panel: {
          title: 'COUNTERS',
          description: 'COUNTERSDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'field-group-orderCount',
            label: 'GROUPORDERCOUNT',
            fieldMaxColumnSize:1,
            groupColumnSize:1,
            fields: [
              {
                name: 'orderCount',
                label: 'ORDERCOUNT',
                type: 'input',
                inputType: 'number',
                fieldColumnSize: 1,
              }
            ]
          }
        ]
      }
    ],
    metadataUiData: [
      {
        name: 'createdBy',
        label: 'CREATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'createdDate',
        label: 'CREATEDDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updatedBy',
        label: 'UPDATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updateDate',
        label: 'UPDATEDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      }
    ]
  }
}
