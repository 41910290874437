import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Product } from 'common/interfaces/product';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { Ui } from 'app/shared/common/interfaces/ui';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProducts(companyId: string, recordPageRequest: RecordPageRequest): Observable<{records:Product[]; total: number}> {
    return this.http.post<{records:Product[]; total: number}>('product/list/', {companyId, recordPageRequest} );
  }

  getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(`product/${id}`);
  }

  createProduct(product: Product) {
    return this.http.post('product', {product}, {responseType: 'text'});
  }

  updateProduct(product: Product) {
    return this.http.put('product', { product }, { responseType: 'text' });
  }

  deleteProducts(productIds: string[]) {
    return this.http.delete('product', { body: { productIds }, responseType: 'text' });
  }

  ui:Omit<Ui,'_id'>= { // product ui
    uiTabs: [
      {
        name: 'product-details',
        panel: {
          title: 'PRODUCTDETAILS',
          description: 'PRODUCTDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'general-document-details',
            label: 'GENERALDOCUMENTDETAILS',
            groupColumnSize:1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'name',
                label: 'PRODUCTNAME',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'PRODUCTNAMEREQUIRED'},
                ]
              },
              {
                name: 'description',
                label: 'DESCRIPTION',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'DESCRIPTIONREQUIRED'},
                ]
              },
              {
                name: 'vatCode',
                label: 'DEFAULT VATCODE',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'VATCODEREQUIRED'},
                ]
              },
              {
                name: 'supplierId',
                label: 'SUPPLIERID',
                type: 'input',
                inputType: 'text',
                readonly: false,
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'SUPPLIERIDREQUIRED'},
                ]
              },
              {
                name: 'supplierItemCode',
                label: 'SUPPLIERITEMCODE',
                type: 'input',
                inputType: 'text',
                readonly: false,
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'SUPPLIERITEMCODEREQUIRED'},
                ]
              },
              {
                name: 'barcodeNumber',
                label: 'BARCODENUMBER',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'caseSize',
                label: 'CASESIZE',
                type: 'input',
                inputType: 'number',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'CASESIZEISREQUIRED'},
                ]
              },
              {
                name: 'MeasureUnit',
                label: 'MEASUREUNIT',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'MEASUREUNITREQUIRED'},
                ]
              },
              {
                name: 'MeasureIndicator',
                label: 'MEASUREINDICATOR',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'additionalID',
                label: 'ADDITIONALID',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'price',
                label: 'DEFAULT PRICE',
                type: 'input',
                inputType: 'number',
                fieldColumnSize: 1,
                validators:[
                  {name:'required',errorType:'required',message:'DEFAULTPRICEREQUIRED'},
                ]
              }
            ]
          }
        ]
      }
    ],
    metadataUiData: [
      {
        name: 'createdBy',
        label: 'CREATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'createdDate',
        label: 'CREATEDDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updatedBy',
        label: 'UPDATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updateDate',
        label: 'UPDATEDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      }
    ]
  }
}
