import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DeliveryLocation, DeliveryLocationReference } from 'common/interfaces/deliveryLocation';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { Ui } from 'app/shared/common/interfaces/ui';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getLocations(companyId: string, recordPageRequest: RecordPageRequest): Observable<{records:DeliveryLocation[]; total: number}> {
    return this.http.post<{records:DeliveryLocation[]; total: number}>('location/list/', {companyId, recordPageRequest} );
  }

  getLocationReferences(companyId: string): Observable<DeliveryLocationReference[]> {
    return this.http.get<DeliveryLocationReference[]>(`location/references/${companyId}`);
  }

  getLocation(id: string): Observable<DeliveryLocation> {
    return this.http.get<DeliveryLocation>(`location/${id}`);
  }

  createLocation(location: DeliveryLocation) {
    return this.http.post('location', {location}, {responseType: 'text'});
  }

  updateLocation(location: DeliveryLocation) {
    return this.http.put('location', {location}, {responseType: 'text'});
  }

  deleteLocations(locationIds: string[]) {
    return this.http.delete('location', {body: {locationIds}, responseType: 'text'});
  }

  ui:Omit<Ui,'_id'>= { // location ui
    uiTabs: [
      {
        name: 'location-details',
        panel: {
          title: 'LOCATIONDETAILS',
          description: 'LOCATIONDESCRIPTION',
          icon: 'settings-outline'
        },
        groupMaxColumnSize:1,
        fieldGroups: [
          {
            name: 'address-name',
            label: 'ADDRESSNAME',
            groupColumnSize:1,
            fieldMaxColumnSize:1,
            fields: [
              {
                name: 'deliveryLocationName',
                label: 'LOCATIONNAME',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              }
            ]
          },
          {
            name: 'address-lines',
            label: 'ADDRESSLINES',
            fieldMaxColumnSize:1,
            groupColumnSize:1,
            fields: [
              {
                name: 'deliveryAddressLine1',
                label: 'ADDRESSLINE1',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1
              },
              {
                name: 'deliveryAddressLine2',
                label: 'ADDRESSLINE2',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1
              },
              {
                name: 'deliveryAddressLine3',
                label: 'ADDRESSLINE3',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1
              },
              {
                name: 'deliveryAddressLine4',
                label: 'ADDRESSLINE4',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1
              }
            ]
          },
          {
            name: 'address-code',
            label: 'ADDRESSCODE',
            fieldMaxColumnSize:1,
            groupColumnSize:1,
            fields: [
              {
                name: 'deliveryPostCode',
                label: 'POSTCODE',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'deliveryCountryCode',
                label: 'COUNTRYCODE',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              },
              {
                name: 'deliveryCode',
                label: 'DELIVERYCODE',
                type: 'input',
                inputType: 'text',
                fieldColumnSize: 1,
              }
            ]
          }
        ]
      },
    ],
    metadataUiData: [
      {
        name: 'createdBy',
        label: 'CREATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'createdDate',
        label: 'CREATEDDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updatedBy',
        label: 'UPDATEDBY',
        type: 'input',
        inputType: 'text',
        fieldColumnSize: 1,
        readonly: true
      },
      {
        name: 'updateDate',
        label: 'UPDATEDATE',
        type: 'datetime',
        format: 'YYMMDD',
        fieldColumnSize: 1,
        readonly: true
      }
    ]
  }
}
