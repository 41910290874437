// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trading-partner-selector-container-hscroll {
  overflow-x: auto;
  white-space: nowrap;
  margin: 0 auto;
  padding-bottom: 8px; /* TODO used for the scrollbar. Decide whether it is needed or not */
}

.trading-partner-selector-container-vscroll {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.settings-icon {
  margin-left: auto;
  font-size: 1.2rem;
}

.custom-chip {
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/trading-partner-selector/trading-partner-selector.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EAEA,cAAA;EACA,mBAAA,EAAA,oEAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ","sourcesContent":[".trading-partner-selector-container-hscroll {\n    overflow-x: auto;\n    white-space: nowrap;\n    \n    margin: 0 auto;\n    padding-bottom: 8px; /* TODO used for the scrollbar. Decide whether it is needed or not */\n}\n\n.trading-partner-selector-container-vscroll {\n    display: flex;\n    flex-direction: column;\n    align-items: left;\n}\n\n.settings-icon {\n    margin-left: auto;\n    font-size: 1.2rem;\n}\n\n.custom-chip {\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
