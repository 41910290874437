import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TableService } from 'app/core/services';
import { RecordObject } from 'app/shared/common/interfaces/global';
import { FieldGroup, FormField } from 'app/shared/common/interfaces/ui';
import { UiService } from 'app/ui/ui.service';

@Component({
  selector: 'app-metadata-modal',
  templateUrl: './metadata-modal.component.html',
  styleUrls: ['./metadata-modal.component.scss'],
})
export class MetadataModalComponent implements OnInit {
  @Input() selected: RecordObject; //we will only show a single record metadata
  @Input() fields: Array<FormField>;
  fieldGroups:FieldGroup<FormField>[];//Assert to FieldGroup, that is required by <app-field-container> html template
  metadataForm: FormGroup;

  constructor(public modalController:ModalController,public formBuilder:FormBuilder, public uiService:UiService,
    public tableService : TableService
  ) {}

  ngOnInit() {
    this.metadataForm = this.formBuilder.group({});

    this.fieldGroups = [{
      name : 'metadatamodal',
      label:'MEATADATA',
      fields : this.fields,
      groupColumnSize: 1,
      fieldMaxColumnSize:1
    }];

    this.fields.forEach(field=>{

      this.metadataForm.addControl(field.name,new FormControl());
      //assign value from selected to modal

      if(this.selected.hasOwnProperty('metadata')){

        let metadata = this.selected['metadata'];

        field.text = metadata[field.name];

      }else{

        throw new Error ('Metadata not found from record.');

      }
    });
  }

  onCancel(){
    this.modalController.dismiss();
  }

}