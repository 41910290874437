import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private translate: TranslateService) { }

  getTranslatedText(translateKey: string): string{
    let value = "";
    if(translateKey && translateKey.length > 0){
      value = this.translate.instant(translateKey);
    }
    return value;
  }
}
