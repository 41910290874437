// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list ion-list {
  margin: 0px 20px 0px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/action-privileges/action-privileges.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourcesContent":["ion-list ion-list { // indents the actions under the parent record types\n  margin: 0px 20px 0px 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
