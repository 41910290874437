import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseField, FormField } from 'app/shared/common/interfaces/ui';
import { MetadataModalComponent } from './metadata-modal.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { HelperService, TableService } from 'app/core/services';
import { RecordObject } from 'app/shared/common/interfaces/global';
import { UiService } from 'app/ui/ui.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataModalService {

  buttonEventSubscription: Subscription ; 
  uiSubscription : Subscription ; //need it for table view ui retrival

  constructor(
    private modalController:ModalController, private tableService:TableService, private helperService: HelperService,
    private uiService:UiService
  ) { }

  //condition 1 : from table view, we have selected or activeRoute => we will use it to get the ui then the fields any way
  //condition 2 : from record view, we have the fields literally 

  public tableMetadataModal ( activeRoute: string , selected : RecordObject){
    let isDocument = this.helperService.isDocumentRoute( activeRoute ) ; 
    if(isDocument){
      this.uiService.getDocumentMetadataUi(selected._id).subscribe( metadataUi=>{
        this.presentMetadataModal(metadataUi);
      });
    }else{
      //something provide on branch feature-ui-refactor
    }
  }

  public async presentMetadataModal( fields:Array<FormField|DatabaseField> ) { //metadata ui skip fieldGroups and use fields

    const modal = await this.modalController.create({
      component: MetadataModalComponent,
      componentProps: {
        selected : this.tableService.selected[0],
        fields : fields
      }
    });

    await modal.present();

    //TODO : when we get ui from here
    await modal.onDidDismiss().then(()=>{
      if(this.uiSubscription){
        this.uiSubscription.unsubscribe();
      }
    });

  }

}
