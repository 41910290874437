import { Injectable } from '@angular/core';
import {DocumentRecordType, NonDocumentRecordType, routeRecordType} from '../../shared/common/interfaces/recordTypes'; 
import { DocumentRule } from 'app/shared/common/interfaces/documentRule';
import { FormField, UiTab,  } from 'app/shared/common/interfaces/ui';

@Injectable({ providedIn: 'root' })
export class HelperService {

  constructor() { }

  /*
    Based on active recordType, determine rules to be applied
  */
  getRecordTypeDocumentRule(documentRules: Array<DocumentRule>, recordType : string ):DocumentRule|undefined{
    let appliedRule = documentRules.find(rule=> rule.documentType == recordType); //get rules by record
    return appliedRule ; 
  }

  //recordType should be pure active route, no params, no /form
  checkRecordIsDocument(recordType: string):boolean{
    //read through document and non document record type enum
    let documentRecordTypeValues = Object.values(DocumentRecordType).map(value => ({ stringValue : value }));
    let nonDocumentRecordTypeValues = Object.values(NonDocumentRecordType).map(value => ({ stringValue : value }));
    //check is recordType contained in enum
    let isDocumentRecordType = documentRecordTypeValues.find( value => value.stringValue == recordType ) ; 
    let isNonDocumentRecordType = nonDocumentRecordTypeValues.find( value => value.stringValue == recordType ) ;
    //Return boolean based on find result. Throw error if recordType is no where found.
    if( isDocumentRecordType!= undefined){
      return true; 
    }
    if( isNonDocumentRecordType != undefined){
      return false;
    }
    throw new Error ('Unexpected or invalid recordType intercepted');
  }

  //true - Document , false - Non Document, Error - invalid route
  isDocumentRoute(route:string):boolean{
    let recordTypeName = routeRecordType[route] ;
    if(Object.values(DocumentRecordType).includes(recordTypeName)){
      return true;
    }
    if(Object.values(NonDocumentRecordType).includes(recordTypeName)){
      return false ;
    }
    throw new Error ('Unexpected or invalid active route intercepted.');
  }

  iterateFields(uiTabs: UiTab[], callback: (field: FormField) => void) {
    uiTabs.forEach((ui) => {
      ui.fieldGroups.forEach((fieldGroup) => {
        fieldGroup.fields.forEach((field) => {
            callback(field);
        });
      });
    });
  }
}
