import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AuthService } from 'core/services';
import { PrivilegesTree, UserPrivilege } from 'common/interfaces/privileges';
import { Company, CompanyReference } from 'common/interfaces/company';
// import { ActionPrivilegesService } from 'shared/action-privileges/action-privileges.service';
import { CompanyDetailsComponent } from 'shared/components';

import { CompanyService } from 'app/company/company.service';


@Component({
  selector: 'app-user-trading-partner-modal',
  templateUrl: './user-trading-partner-modal.page.html',
  styleUrls: ['./user-trading-partner-modal.page.scss'],
})
export class UserTradingPartnerModalPage implements OnInit {
  // userPrivilege (below) is retrieved from the componentProps property in the object passed into this.modalController.create()
  userPrivilege: UserPrivilege;
  isSuperAdmin = false;
  privilegesTree: PrivilegesTree;
  editMode: boolean;
  companyReference: CompanyReference;
  selectedTradingPartner: CompanyReference;

  @ViewChild('tradePartner') public companyDetails: CompanyDetailsComponent;

  constructor(private modalController: ModalController,
    //  private actionPrivilegesService: ActionPrivilegesService,
    private companyService: CompanyService, private authService: AuthService) { }

  ngOnInit() {  }

  ionViewWillEnter() {
    // TODO: refactor privileges
    // this.privilegesTree = this.actionPrivilegesService.convertPrivilegesToTree(this.userPrivilege.actionPrivileges);
    this.companyService.getCompany(this.companyReference.companyId).subscribe((company: Company) => {
      this.companyDetails.company = company;
      this.companyDetails.companyForm.patchValue({ 
        companyName: company.tenantName, // might need to adjust formControl name.. the formControl name in here should same as the one in company-details.components.ts
        // vatNumber: company.vatNumber, // TODO gets vatNumber & address from autofill
        // address: {
        //   addressLine1: company
        //   addressLine2: company,
        //   addressLine3: company,
        //   addressLine4: company,
        // }
      });

      // Remove companies that are already assigned as a TP to a user // TODO align tradingPartner with new interface https://app.asana.com/0/1204014722864744/1204661292532416/f
      // this.companyDetails.company.tradingPartners.forEach((tp, index) => {
      //   this.companyReference.tradingPartners.forEach(excludedTp => {
      //     if (excludedTp._id === tp._id) {
      //       this.companyDetails.company.tradingPartners.splice(index, 1);
      //     }
      //   });
      // });
    });
    this.isSuperAdmin = this.authService.isSuperAdmin();
  }

  onTradingPartnerSelect(partner: CompanyReference) {
    this.selectedTradingPartner = partner;
  }

  // removeTradingPartner(removedTradingPartner: CompanyReference) {  // TODO align tradingPartner with new interface https://app.asana.com/0/1204014722864744/1204661292532416/f
  //   this.companyReference.tradingPartners.forEach((tp, index) => {
  //     if (removedTradingPartner._id === tp._id) {
  //       this.companyReference.tradingPartners.splice(index, 1);
  //     }
  //   });
  // }

  // addTradingPartner() { // TODO align tradingPartner with new interface https://app.asana.com/0/1204014722864744/1204661292532416/f
  //   if (!this.selectedTradingPartner) return;
  //   if (!this.companyReference.tradingPartners.some(tp => tp._id === this.selectedTradingPartner._id)) {
  //     this.companyReference.tradingPartners.push(this.selectedTradingPartner);
  //   }
  // }

  save() { // TODO align tradingPartner with new interface https://app.asana.com/0/1204014722864744/1204661292532416/f
    // TODO: put this back in 
    // this.userPrivilege.actionPrivileges = this.actionPrivilegesService.convertPrivilegesToDB(this.privilegesTree);
    this.modalController.dismiss({company: this.companyReference});
    // , userPrivilege: this.userPrivilege});
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
