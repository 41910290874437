import { Component, ElementRef, Input,  ViewChild } from '@angular/core';
import mermaid, { MermaidConfig } from 'mermaid';
import { Document } from 'app/shared/common/interfaces/document';
import { Router } from '@angular/router';
import { DocumentRecordType, routeRecordType } from 'app/shared/common/interfaces/recordTypes';
import { DocumentService } from 'app/documents/document.service';
import { forkJoin, Observable, of } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { TransactionStatus } from 'app/shared/common/interfaces/documentStatus';
import { catchError } from 'rxjs/operators';
import { Transaction, TransactionDocument } from 'app/shared/common/interfaces/transaction';
@Component({
  selector: 'app-document-transaction-modal',
  templateUrl: './document-transaction-modal.page.html',
  styleUrls: ['./document-transaction-modal.page.scss']
})
export class DocumentTransactionModalPage {
  @ViewChild('mermaidDiv', { static: false }) mermaidDiv: ElementRef;
  @Input() documentId;
  transaction:Transaction;
  transactionsDummyData:Transaction[] = [
  {
    _id: "80000004",
    tradingPartnership: "77737777377",
    documentIds: ["8686868", "91919191", "76767676", "363636363"],
    documentHierarchy: {
      documentIds: ["660157e123f0c4002695c73a", "660157e130e0c4002695c73a"],
      documentType: DocumentRecordType.Order,
      childDocuments: [
        {
          documentIds: ["660157e560e0c4002695c73a", "660157e123f0c4002695c73a"],
          documentType: DocumentRecordType.Invoice,
          childDocuments: [
            {
              documentIds: ["76767676"],
              documentType: DocumentRecordType.DespatchAdvice,
              childDocuments: [],
            },
            {
              documentIds: ["363636363"],
              documentType: DocumentRecordType.RemittanceAdvice,
              childDocuments: [],
            },
          ],
        },
      ],
    },
    transactionStatus: TransactionStatus.Completed,
  },
  {
    _id: "90000005",
    tradingPartnership: "88888888888",
    documentIds: ["660157e560e0c4002695c73a", "92929292", "47474747", "26262626"],
    documentHierarchy: {
      documentIds: ["660157e130e0c4002695c73a", "770157e130e0c4002695c73b"],
      documentType: DocumentRecordType.Order,
      childDocuments: [
        {
          documentIds: ["660157e560e0c4002695c73a", "770157e123f0c4002695c73b"],
          documentType: DocumentRecordType.Invoice,
          childDocuments: [
            {
              documentIds: ["47474747"],
              documentType: DocumentRecordType.ReceivingAdvice,
              childDocuments: [],
            },
            {
              documentIds: ["26262626"],
              documentType: DocumentRecordType.PaymentOrder,
              childDocuments: [],
            },
          ],
        },
      ],
    },
    transactionStatus: TransactionStatus.Processing,
  },
];


  constructor(private router: Router, private documentService:DocumentService, public modalController:ModalController) {}

  ngOnInit(): void {
      /* link to config style: https://mermaid.js.org/config/theming.html#theme-variables */
    const config: MermaidConfig = {
        theme: 'base',
        logLevel: 'fatal',
        themeVariables: {
          primaryColor: '#3880ff',
          primaryTextColor: '#333333',
          primaryBorderColor: '#E5E4E2',
          lineColor: '#3387b5',
          secondaryColor: 'transparent', 
          fontFamily: 'FreeMono, monospace',
          fontSize: '20px',
        },
        startOnLoad: false,
        flowchart: {
          useMaxWidth: true,
          htmlLabels: true,
          curve: 'linear'
        },
        securityLevel: 'loose'
      };
    mermaid.initialize(config);
    
    this.transaction = this.documentId === '660157e123f0c4002695c73a' ? this.transactionsDummyData[1] : this.transactionsDummyData[0];

    const documentObservables: Observable<Document>[] = [];
    this.iterateChildDocuments(this.transaction.documentHierarchy.childDocuments, (childDocument) => {
      childDocument.documentIds.forEach(documentId => {
        if (documentId && documentId.length > 0) {
            documentObservables.push(this.documentService.getDocument(documentId).pipe(catchError(err=>{
                console.error(err,'error fetching document...')
                return of (null) // return null observable for not found documents
            })))

        }
      });
    });
    
    forkJoin(documentObservables).subscribe({
        next: (documents) => {
          const filteredDocuments = documents.filter(document => document !== null);
          const graphDefinition = this.generateFlowchart(this.transaction, filteredDocuments);
          this.renderMermaidDiagram(graphDefinition);
        },
        error: (err) => {
          console.error('Something went wrong...', err);
        }
      });
  }

  public iterateChildDocuments(childDocuments: TransactionDocument[], callback: (childDocument: TransactionDocument) => void): void {
    childDocuments.forEach(childDocument => {
      callback(childDocument);
      if (childDocument.childDocuments && childDocument.childDocuments.length > 0) {
        this.iterateChildDocuments(childDocument.childDocuments, callback);
      }
    });
  }

  private async renderMermaidDiagram(flowchart:string): Promise<void> {
    try {
      const element = this.mermaidDiv.nativeElement;
      const { svg } = await mermaid.render('id',flowchart);
      if (element) {
        element.innerHTML = svg;
        await mermaid.run({suppressErrors:true});
        this.addEventListeners('.selectable-item');
      }
    } catch (error) {
      console.error('Error rendering mermaid diagram:', error);
    }
  }
  
  private addEventListeners(className: string): void {
    const selectedElements = this.mermaidDiv.nativeElement.querySelectorAll(className);
    selectedElements.forEach((node:HTMLElement)=>{
            node.addEventListener('mousedown',(event)=> {
            this.onActivate(node, event)});
    })
  }
  
  private onActivate( node:HTMLElement, event:MouseEvent): void {
    const documentId = node.getAttribute('data-id').split('-')[1];
    const documentType = node.getAttribute('data-id').split('-')[0];
    const activeRoute = Object.keys(routeRecordType).find(key => routeRecordType[key] === documentType); 
    const clickedElement = event.target as HTMLElement;
    const  isIconClicked = clickedElement.classList.contains('new-tab-icon');
    const url = this.router.serializeUrl(this.router.createUrlTree([`${activeRoute}`,'form'], {queryParams:{id:`${documentId}`, editMode:false}}));
    if (isIconClicked) {
      if (event.type ==='mousedown') { 
        window.open(url, '_blank');
        return;
      }
    }
    if (event.type ==='mousedown' && event.ctrlKey || event.button === 1) { // event.button === 1 is the scroll event 
        window.open(url, '_blank');
        return;
    }
    else if (event.type ==='mousedown') {
        this.router.navigate([`${activeRoute}`,'form'], {queryParams:{id:`${documentId}`, editMode:false}});
        this.modalController.dismiss();
        return;
    }
  }

  private generateFlowchart(transaction: Transaction, documents: Document[]): string {
    let flowchart = `
      flowchart TD
      classDef selectable-item cursor:pointer;
      class order order-layout;
      class invoice invoice-layout;
      class remadv remadv-layout;
      class despatch despatch-layout;
      style transaction fill:transparent,stroke:orange,color:orange; \n`;

    const order = transaction.documentHierarchy.documentType === DocumentRecordType.Order;
    let isInvoiceDocument;
    let isAsnDocument;
    this.iterateChildDocuments(transaction.documentHierarchy.childDocuments, childDocument=> {
        if (childDocument.documentType === DocumentRecordType.Invoice) {
          isInvoiceDocument = true;
        }
        if (childDocument.documentType === DocumentRecordType.RemittanceAdvice) {
          isAsnDocument = true
        }
    })
    if (order) {
        flowchart += `
        order[<span class="icon-order-layout order-icon"><div class="order-layout-text">Order: ${transaction._id}<div class="transaction-container"><span class="transaction-text">${TransactionStatus[transaction.transactionStatus]}</span>
        <span class="container-icon ${TransactionStatus[transaction.transactionStatus]}-icon"></span></div></div></span>]:::order-layout; \n`;
      if (isInvoiceDocument) {
        flowchart += `
        order-->invoice[<span class="icon-layout invoice-icon"><span>Invoice</span></span>]:::invoice-layout \n`;
        transaction.documentHierarchy.childDocuments.forEach(doc => {
          if (doc.documentType === DocumentRecordType.Invoice) {
            const invoiceids = doc.documentIds;
            documents.forEach(document => {
              if (document && invoiceids.includes(document._id)) {
                flowchart += `
                invoice---${document.metadata.documentType}-${document._id}[<div class="invoice-result-container"><span class="invoice-result-text">${document.documentNumber}</span><span class="container-icon new-tab-icon"></span></div>]:::selectable-item; 
                style ${document.metadata.documentType}-${document._id} fill:transparent,stroke:#3387b5;
                \n`;
              }
            });
          }
        });

      }
      if (isAsnDocument) {
        flowchart += `
        style warehouse fill:transparent,stroke:#08aa23,color:#08aa23; 
        style pending-asn fill:transparent,stroke:#08aa23,color:#08aa23;
        order-->asn[<span class="icon-layout asn-icon">ASN</span>]:::asn-layout;
        asn -->despatch[<span class="despatch-layout-text">Despatch</span>]:::despatch-layout;
        asn -->pending-asn
        despatch---|<span class="status-despatch">despatched</span>|warehouse>Warehouse]:::selectable-item;
        \n`;
      }
    }
    if (transaction.transactionStatus === TransactionStatus.Processing) {

    flowchart += `
     order -->|<span class="container-icon processing-icon"></span>|transaction[${TransactionStatus[transaction.transactionStatus]}]; \n`
    } else if (transaction.transactionStatus === TransactionStatus.Completed) {
    flowchart += `
     order -->|<span class="container-icon tick-icon"></span>|transaction[${TransactionStatus[transaction.transactionStatus]}]; \n`
    } else if (transaction.transactionStatus === TransactionStatus.Cancelled) {
    flowchart += `
     order -->|<span class="container-icon cancel-icon"></span>|transaction[${TransactionStatus[transaction.transactionStatus]}]; \n`
    }
    return flowchart;
  }
  
}
