import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { CheckPrivilegeService } from 'app/core/services/check-privilege.service';

import { Observable, Subject, combineLatest, from, of, } from 'rxjs';
import { catchError, filter, finalize, map ,    mergeMap,    switchMap,    take, tap, toArray, } from 'rxjs/operators';

import { Action, BinaryActions } from 'app/shared/common/actions';
import { AuthService } from './auth.service';

import * as PrivilegeHelpers from 'app/shared/common/modules/privilege-helpers';
import * as DocumentHelpers from 'app/shared/common/modules/document-helpers';

import { Platform } from '@ionic/angular';
import { TradingPartnershipService } from './trading-partnership.service';
import { DocumentRecordType } from 'app/shared/common/interfaces/recordTypes';
import { DocumentService } from 'app/documents/document.service';
import { Document } from 'app/shared/common/interfaces/document';
import { CompanyService } from 'app/company/company.service';
import { TradingPartnership } from 'app/shared/common/interfaces/tradingPartnership';
import { ExchangeStatus } from 'app/shared/common/interfaces/documentStatus';
import { CompanyReference } from 'app/shared/common/interfaces/company';
import { RecordObject } from 'app/shared/common/interfaces/global';
import { TableService } from './table.service';
import { HelperService } from './helper.service';

/**
 * Used to control which buttons are visible in the action bar
 *
 * Also used to handle click events in the action bar
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ActionBarService {
  document:Document;
  tradingPartnership:TradingPartnership;
  documentFormExchangeMask = BinaryActions.all;
  constructor(private route: ActivatedRoute, private router: Router, private checkPrivilegeService: CheckPrivilegeService, 
              public authService: AuthService, private platform: Platform, private tradingPartnershipService: TradingPartnershipService,
              private documentService:DocumentService, private companyService:CompanyService, private tableService: TableService,
              private helperService:HelperService)
  {
    // subscription to route url change
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = val.url.split('?')[0]; // current route, excluding parameters, i.e /orders/form
        this.params = this.route.snapshot.queryParams; // parameters
        this.renderButtons();
      }
    });

    //subscription to selected document change
    this.tableService.selectedSubscription.pipe(
      tap( selectedRecords =>{
        this.recordsSelected = selectedRecords.length;
      }),
      switchMap(selectedRecords => this.determineDocumentTablePageMasks(selectedRecords)),
    ).subscribe(releasePrivilegeMask=>{
      if(this.url ==='/orders' || this.url ==='/invoices') { //TODO: Nikesh agree this need to be a function
        this.recordTypeMasks[this.url] = releasePrivilegeMask;
        this.renderButtons();
      }
    });

  }

  set recordsSelected(recordsSelected: number) {
    this._recordsSelected = recordsSelected;
  }

  private url: any;
  private params: Params;
  private _recordsSelected: number;

  /* Object containing a reference to BinaryActions masks associated with each record type. */
  /* The key is the route url which points to that record type; the value is a BinaryActions mask. */
  private recordTypeMasks = {
    /* disabled all filter actions for demo*/
    '/orders': PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.filter)) ),
    '/orders/form':PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.selectMode, BinaryActions.filter))),
    '/invoices':  PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.filter)) ),
    '/invoices/form': PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.selectMode, BinaryActions.filter))),
    '/users/form': PrivilegeHelpers.allExcept( BinaryActions.release),
    '/users/management': PrivilegeHelpers.allExcept(BinaryActions.release),
    '/users':  PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.filter))),
    '/company/form': PrivilegeHelpers.allExcept( (PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.create)) ),
    '/company/disabled': BinaryActions.none,
    '/products': PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.filter))),
    '/products/form': PrivilegeHelpers.allExcept ( (PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.create)) ),
    '/price-list': PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.filter))),
    '/price-list/form': PrivilegeHelpers.allExcept( (PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.create)) ),
    '/locations': PrivilegeHelpers.allExcept((PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.filter))),
    '/locations/form': PrivilegeHelpers.allExcept( (PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.create)) )
  }
  
  private buttonEvent$ = new Subject<Action>();
  public readonly buttonEventSubscription = this.buttonEvent$.asObservable().pipe(
    // When .unsubscribe is called, finalize() is used for clean up
    finalize(() => {
      this.recordsSelected = 0;
    })
  );

  private backButtonEvent$ = new Subject<Action>();
  public readonly backButtonEventSubscription = this.backButtonEvent$.asObservable();

  private renderButtons$ = new Subject<BinaryActions>();
  public readonly renderButtonsSubscription = this.renderButtons$.asObservable();

  public buttonEvent(event: Action) {
    this.buttonEvent$.next(event);
  }

  public backButtonEvent() {
    // Put null because after updating packages compiler says its expecting an argument but got 0
    this.backButtonEvent$.next(null);
  }

  public renderButtons() {
    if (!this.authService.user$.value){ // prevents buttons from rendering if no user logged in
      return;
    }
    if (this.url === '/orders/form' || this.url === '/invoices/form') {//TODO: Nikesh agree this need to be a function
      combineLatest([this.documentService.currentDocument.pipe(
        (filter(tradingParntership=> tradingParntership !=null))
        ),
        this.tradingPartnershipService.currentTradingPartnership.pipe(
          (filter(tradingParntership=> tradingParntership !=null))
        ),
      ]).pipe(
        switchMap(([document, tradingPartnership]) => {
          this.document = document;
          this.tradingPartnership = tradingPartnership;
          if (this.document && this.tradingPartnership) {
            this.documentFormExchangeMask = this.generateDocumentFormExchangeMask();
          return this.checkVisibleButtons();
          }
        })
      ).subscribe(binaryActions=>{
        this.renderButtons$.next(binaryActions);
      })
    } else {
      this.checkVisibleButtons().subscribe(binaryActions=>{
        this.renderButtons$.next(binaryActions);
      })
    }
  }
  
  /**
   *
   * @returns A number, who's binary form represents a selection of actions.
   *
   * Uses button visibility masks to produce a BinaryActions number that only contains actions present in all masks.
   *
   * The result is to be consumed by the action-bar component to determine which buttons should be visible on the action bar.
   *
   */
  private generateDocumentFormExchangeMask():BinaryActions { 
    const recipientId:CompanyReference['companyId'] = DocumentHelpers.determineRecipientId(this.document.metadata.documentType,this.tradingPartnership);
    const isActiveCompanyRecipient:boolean = recipientId === this.companyService.activeCompany?.companyId;

    if (!isActiveCompanyRecipient && this.document.exchangeStatus === ExchangeStatus.Draft) { // Sender action bar: document hasn't been sent by the sender
      return BinaryActions.all;
    }
    if (!isActiveCompanyRecipient && this.document.exchangeStatus !== ExchangeStatus.Draft) { // Sender action bar: document has been submitted
      return PrivilegeHelpers.allExcept(PrivilegeHelpers.combineWithOr(BinaryActions.release, BinaryActions.edit));
    }
    if (isActiveCompanyRecipient && this.document.exchangeStatus !== ExchangeStatus.Draft) { // Recipient action bar: document has been submitted
      return PrivilegeHelpers.allExcept(PrivilegeHelpers.combineWithOr(BinaryActions.release));
    }
    return PrivilegeHelpers.allExcept(PrivilegeHelpers.combineWithOr(BinaryActions.release));
  }

  private determineDocumentTablePageMasks(selectedRecords: RecordObject[]): Observable<BinaryActions> {
    const removeReleaseMask = PrivilegeHelpers.removeFrom(this.recordTypeMasks[this.url], BinaryActions.release);
    const addReleaseMask = PrivilegeHelpers.addTo(this.recordTypeMasks[this.url], BinaryActions.release);
    const isDocumentTableRoute = (this.url === '/invoices' || this.url === '/orders');
    const selectedDocuments: Document[] = (selectedRecords as Document[]).filter((document: Document) => 
      Object.values(DocumentRecordType).includes(document?.metadata?.documentType)
    );
    
    if (selectedDocuments.length > 0 && isDocumentTableRoute) {
      return from(selectedDocuments).pipe(
        mergeMap((document: Document) =>
          this.tradingPartnershipService.getTradingPartnership(document.tradingPartnershipId).pipe(
            map(tradingPartnership => {
              const recipientId: CompanyReference['companyId'] = DocumentHelpers.determineRecipientId(document.metadata.documentType, tradingPartnership);
              const isActiveCompanyRecipient: boolean = recipientId === this.companyService.activeCompany?.companyId;
              const isDocumentDraft: boolean = document.exchangeStatus === ExchangeStatus.Draft;
              return {
                isActiveCompanyRecipient, 
                isDocumentDraft
              };
            }),
            catchError(err => {
              console.error('Error fetching: Trading partnership details', err);
              return of(null); 
            })
          )
        ),
        toArray(),
        map(results => {
          const isDocumentValidForRelease = results.every(result => result && !result.isActiveCompanyRecipient && result.isDocumentDraft);
          return isDocumentValidForRelease ? addReleaseMask : removeReleaseMask;
        })
      );
    } else {
      return of(removeReleaseMask);
    }
  }
  
  private checkVisibleButtons(): Observable<BinaryActions> {

    const viewTypeMask = this.recordTypeMasks[this.url];
    let selectedMask = BinaryActions.all;
    // Conditions for displaying edit button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 || this.url.split('/')[2] === 'form' || this.url.split('/')[2] === 'management'? BinaryActions.none : BinaryActions.edit)
    );

    // Conditions for choosing between save and edit buttons
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      // (this.params.editMode === 'true' ? PrivilegeHelpers.combineWithOr(BinaryActions.edit, BinaryActions.release) : BinaryActions.save)
      (this.params.editMode === 'true' ? BinaryActions.edit : BinaryActions.save)
    );

    // Conditions for displaying delete button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected >= 1 || this.url.split('/')[2] === 'form' || this.url.split('/')[2] === 'management'? BinaryActions.none : BinaryActions.delete)
    );

    // Conditions for displaying export button
    // TODO: should only be displayed on orders table/view for now
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected >= 1 || this.url.split('/')[2] === 'form' || this.url.split('/')[2] === 'management'? BinaryActions.none : BinaryActions.export)
    );

    // Conditions for displaying open button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 ? BinaryActions.none : BinaryActions.open)
    );

    // Conditions for displaying copy button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 || this.url.split('/')[2] === 'form' || this.url.split('/')[2] === 'management'? BinaryActions.none : BinaryActions.duplicate)
    );

    // Conditions for displaying create button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      ( (this._recordsSelected > 1 && this.helperService.isDocumentRoute(this.url.split('/')[1])) || this.params.editMode === 'true' ? BinaryActions.create : BinaryActions.none )
    );

    // Conditions for displaying release button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this.params.editMode === 'true'? BinaryActions.release :BinaryActions.none )
    );

    // Conditions for displaying filter button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 || this.url.split('/')[2] === 'form' || this.url.split('/')[2] === 'management'? BinaryActions.filter : BinaryActions.none)
    );

    // Conditions for displaying metadata button, 
    // based on  whether is it in a view and is it a document
    //TODO: adding statement such that ASN, Report is included in the non remove from
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected===1|| this.url.split('/')[2] === 'form' ? BinaryActions.none: BinaryActions.metadata)
    );
    // Conditions for displaying transaction button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this.url === '/orders/form' || this.url === '/invoices/form' ? BinaryActions.none : BinaryActions.transaction)
    );
    // Conditions for displaying release button
    selectedMask = PrivilegeHelpers.removeFrom(      
      selectedMask,
      (this.params.editMode === 'true'? BinaryActions.transaction :BinaryActions.none )
    );
    //create action mask if no child document rules
    const deviceMask = this.platform.is('mobile') ? PrivilegeHelpers.allExcept(BinaryActions.export) : PrivilegeHelpers.allExcept(BinaryActions.selectMode);
    this.documentFormExchangeMask = (this.url === '/invoices/form'||this.url ==='/orders/form') ? this.documentFormExchangeMask : BinaryActions.all; // reset Binary actions 
    // The below is temporary. It performs the checkPrivilege funtion with the overload that returns an array of trading partners and
    // is hardcoded to the 0th element.
    // TODO: The tradingPartnerId should be made available here and a more specific overload should be used.
    return this.checkPrivilegeService.checkPrivilege(this.url.split('/')[1]).pipe(
      take(1),
      map(tradingPartnerBinaryActions => {
        // uncomment below line for debugging; allows  buttons to be shown regardless of privilege
        return PrivilegeHelpers.combineWithAnd(viewTypeMask, this.documentFormExchangeMask, selectedMask, deviceMask);
        return PrivilegeHelpers.combineWithAnd(
          viewTypeMask,
          selectedMask,
          tradingPartnerBinaryActions[0].binaryActions
        ); // to be changed; read comment above
      })
    );
  }
  
}