import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CompanyService } from 'app/company/company.service';
import { AutofillService, HelperService, TableService, TradingPartnershipService } from 'app/core/services';
import { CreateActionModalTradingPartnership, ReferencedTradingPartnership, TradingPartnership } from 'app/shared/common/interfaces/tradingPartnership';
import { combineLatest, Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {Document} from 'app/shared/common/interfaces/document';
import { AutofillSetting, CreateOption } from 'app/shared/common/interfaces/global';
import { DocumentRule, SourceParty } from 'app/shared/common/interfaces/documentRule';
import { routeRecordType } from 'app/shared/common/interfaces/recordTypes';
import { Router } from '@angular/router';

@Component({
  selector: 'create-action-modal.page',
  templateUrl: './create-action-modal.page.html',
  styleUrls: ['./create-action-modal.page.scss'],
})
export class CreateActionModalComponent implements OnInit {

  destroy$ = new Subject<void>();

  //page 1
  createActionModalTradingPartnerships:Array<CreateActionModalTradingPartnership>;
  isTradingPartnershipPage : boolean = true; 
  selectedTradingPartnership : CreateActionModalTradingPartnership;

  //page 2
  createOptions : Array<CreateOption>;
  selectedDocumentIds : Array<Document['_id']> = []; 
  selectedDocuments : Array<Document> = []; 
  documentRules :  Array<DocumentRule>;
  selectedOption: CreateOption;
  backButtonEventSubscription: Subscription;
  tradingPartnershipId : TradingPartnership['_id'];

  constructor(private modalController: ModalController, private companyService:CompanyService, private router:Router,
    private tradingPartnershipService:TradingPartnershipService, private tableService:TableService, private helperService:HelperService,
    private autofillService:AutofillService
  ) {
    // TODO: new issues on asana for back button behaviour //ref - https://ionicframework.com/docs/developing/hardware-back-button
  }

  ngOnInit() {

    combineLatest([
      this.tradingPartnershipService.listReferencedTradingPartnerships(this.companyService.activeCompany.companyId),
      this.tableService.selectedSubscription
    ])
    .pipe(takeUntil(this.destroy$))
    .subscribe(([tradingPartnershipRecords,selected])=>{

      let referencedTradingPartnerships:ReferencedTradingPartnership[] = tradingPartnershipRecords.records;
      this.selectedDocuments = selected as Document[]; //type assertion because selected could be document or non document

      //build all possible create options
      this.createActionModalTradingPartnerships = referencedTradingPartnerships.map( tradingPartnership=>{

        return {
          ...tradingPartnership,
          tradingPartnerRole: this.getTradingPartnerRole(tradingPartnership),//Used in UI to show the role of a tradingPartnership
          parentDocumentIsSelected: false, //default false
          createOptions:this.getCreateOptions(tradingPartnership)
        }

      });

      //exclude create options from receiver
      for(let i = this.createActionModalTradingPartnerships.length-1 ; i >= 0 ; i-- ){
        let tradingPartnership = this.createActionModalTradingPartnerships[i] ;

        if(tradingPartnership.createOptions.length === 0 ){
          continue;
        }

        //exclude options - sender is not active company (only sender would like to draft and release a document)
        tradingPartnership.createOptions = tradingPartnership.createOptions.filter( createOption =>{

          if(createOption.documentRule==undefined){
            throw new Error ('A document rule in a create option must not be undefined');
          }

          if( tradingPartnership.tradingPartnerRole == 'buyer' && createOption.documentRule.sender == SourceParty.Supplier ){
            return true ; //trading partner role is buyer -> active company role is supplier, sender should be supplier
          }

          if( tradingPartnership.tradingPartnerRole == 'supplier' && createOption.documentRule.sender == SourceParty.Buyer ){
            return true ; //trading partner role is supplier -> active company role is buyer, sender should be buyer
          }

          return false; //active company failed to be the sender for this option

        });

        //after the exclusion if we have no create options, remove tradingPartnerships from option
        if(tradingPartnership.createOptions.length === 0){
          this.createActionModalTradingPartnerships.splice(i,1);
        }
        //else we don't touch this tradingPartnership, to show error to user.
      }

      //start working with a selected document here
      if( selected?.length>0 && this.isSameTradingPartnership(this.selectedDocuments ) ){
        let selectedDocument = (selected[0]) as Document; //selected document is an array
        this.selectedDocumentIds = selected.map( selected=> selected._id ); //get the id of the selected document

        //set isParentDocumentInRules value here
        this.createActionModalTradingPartnerships.forEach( tradingPartnership=>{
          tradingPartnership.parentDocumentIsSelected =  this.isParentDocumentInRules(selectedDocument,  tradingPartnership.createOptions,tradingPartnership.documentRules );
        });

        //pre-select tradingPartnership option that is the same from parent document tradingPartnership
        this.selectedTradingPartnership = this.createActionModalTradingPartnerships.find( tradingPartnership =>
          tradingPartnership._id == selectedDocument.tradingPartnershipId //preselect tradingPartnership
        );
      }

      //pre select if there are only 1 trading partnership
      if( this.createActionModalTradingPartnerships.length == 1){
        this.selectedTradingPartnership = this.createActionModalTradingPartnerships[0] ; 
      }

    });

  }

  async onCancel() {
    await this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nextPage():void {
    this.createOptions = this.selectedTradingPartnership.createOptions ; 
    this.isTradingPartnershipPage = false;
    this.tradingPartnershipId = this.selectedTradingPartnership._id;
    this.preSelectDocumentToCreate(this.selectedTradingPartnership.documentRules, this.createOptions);
  }

  previousPage() : void {
    this.isTradingPartnershipPage = true;
  }

  pickTradingPartnership($event): void {
    //type assertion required to get value from ionic native event subject
    this.selectedTradingPartnership = $event.target.value as CreateActionModalTradingPartnership;
  }

  getTargetTradingPartnerName( createActionModalTradingPartnership : CreateActionModalTradingPartnership): String {
    if(this.companyService.activeCompany.companyId == createActionModalTradingPartnership.buyerCompanyId ){
      return createActionModalTradingPartnership.supplierReference.tenantName;
    }
    if (this.companyService.activeCompany.companyId == createActionModalTradingPartnership.supplierCompanyId){
      return createActionModalTradingPartnership.buyerReference.tenantName;
    }
    throw new Error ('Unexpected tradingPartnership, please contact support');
  }

  //return all create options by context of active route and child document
  getCreateOptions(referencedTradingPartnership:ReferencedTradingPartnership ): Array<CreateOption>{

    let createOptions: Array<CreateOption> = [];
    let activeRecordType = routeRecordType[(this.router.url.split('/')[1])]; //Record Type value from active route as key
    let activeRecordDocumentRule : DocumentRule|undefined = this.helperService.getRecordTypeDocumentRule(referencedTradingPartnership.documentRules, activeRecordType);

    if(activeRecordDocumentRule == undefined ){
      return createOptions;
    }

    //Blank document options - read all document rules for all blank document
    createOptions = createOptions.concat( this.getBlankDocumentOptions(referencedTradingPartnership.documentRules));

    //Child document options - read the current active recordType's document rule, childDocuments properties
    if(activeRecordDocumentRule.childDocuments != undefined && activeRecordDocumentRule.childDocuments.length>0){
      createOptions = createOptions.concat(this.getChildDocumentOptions(activeRecordDocumentRule, referencedTradingPartnership.documentRules));
    }

    return createOptions;

  }

  //return create options in context of child document
  getChildDocumentOptions(activeRecordDocumentRule:DocumentRule, documentRules: Array<DocumentRule>):Array<CreateOption>{
    let childDocumentOptions : Array<CreateOption> = [];

    activeRecordDocumentRule.childDocuments.forEach( childDocument =>{
      let childDocumentType = childDocument.documentType;
      let childDocumentOption : CreateOption = { //push each option by childDocuments properties
        recordType:childDocumentType,
        nextRoute:[this.getRecordRoute(childDocumentType)],
        documentRule: this.helperService.getRecordTypeDocumentRule(documentRules,childDocumentType),
        isChild:true,
        parentNotAcquired:true,
      };

      //check if parent document is acquired for child document option
      if(this.selectedDocuments.length<1){
        childDocumentOption.parentNotAcquired = true;
        childDocumentOptions.push(childDocumentOption);
        return;
      }

      //check if active record type meet the record type of expected parent document
      if(this.selectedDocuments[0].metadata.documentType != activeRecordDocumentRule.documentType){
        childDocumentOption.parentNotAcquired = true;
      }else{
        childDocumentOption.parentNotAcquired = false;
      }

      childDocumentOptions.push(childDocumentOption);
    });

    return childDocumentOptions;
  }

  //return create options in context of child document
  getBlankDocumentOptions(  documentRules: Array<DocumentRule>):Array<CreateOption>{
    let blankDocumentOptions = [];
    documentRules.forEach( documentRule =>{
      if(documentRule.canBeRoot){
        let blankDocumentOption : CreateOption = {
          recordType: documentRule.documentType,
          nextRoute: [this.getRecordRoute(documentRule.documentType)],
          documentRule: documentRule,
          isChild:false,
          parentNotAcquired:false
        }
        blankDocumentOptions.push(blankDocumentOption);
      }
    });

    return blankDocumentOptions;
  }

  //get route(key of recordTypeRoute) by a given recordTypeName 
  getRecordRoute(recordTypeName:string):string{
    try{
      let route = null;
      Object.keys(routeRecordType).forEach(key=>{
        if(routeRecordType[key]==recordTypeName){
          route = key;
        }
      });
      if(route == null ){
        route = 'dashboard';
        //TODO: temp solution to route any unknown to dashboard or we can send user an alert and throw an error on our end
        //throw new Error ('Document route not found, invalid record type name.');
      }
      return ('/'+route+'/form');
    }catch(error){
      //TODO: call an alert here instead of throw error
      throw error;
    }
  }

  //user clicks on an option in pop over, EVENT FUNCTION
  selectOption($event):void{
    this.selectedOption = $event.target.value as CreateOption ;
  }

  isSameTradingPartnership( documents:Array<Document> ): boolean {
    let tradingPartnershipId = documents[0].tradingPartnershipId;
    documents.forEach( document =>{
      if(document.tradingPartnershipId!=tradingPartnershipId){
        return false; 
      }
    })
    return true; 
  }

  confirmDocument():void{

    //Set autofillSetting based on selected option
    let autofillSetting : AutofillSetting = {
      isChildDocument : this.selectedOption.isChild,
      targetRecordType : this.selectedOption.documentRule.documentType,
      selectedDocumentId: this.selectedDocumentIds, //latest selected context about create option
      tradingPartnershipId: this.tradingPartnershipId,
    };

    this.autofillService.autofillSetting = autofillSetting;

    this.router.navigate( 
      this.selectedOption.nextRoute,
      { 
        queryParams: {editMode: true, id:undefined},
        queryParamsHandling: "merge"
      }
    )

    this.modalController.dismiss();

  }

  preSelectDocumentToCreate(documentRules:Array<DocumentRule>, createOptions:Array<CreateOption>):void{
    let currentRoute = this.router.url.split('/')[1];
    //document selected, find the first child document option based on active record context
    let activeRulesWithChild = documentRules.filter( documentRule => (documentRule.documentType == routeRecordType[currentRoute] ) && documentRule.childDocuments?.length>0 ); 
    if(this.selectedDocumentIds.length>0 && activeRulesWithChild.length>0){
      this.selectedOption = this.createOptions.find( createOption =>
        (createOption.isChild && (createOption.recordType == activeRulesWithChild[0].childDocuments[0].documentType))
      );
      return;
    }
    //nothing selected, find the first blank document option
    let blankDocumentOptions = createOptions.filter( createOption => createOption.isChild==false);
    let blankDocumentOption = blankDocumentOptions.find( createOption=> createOption.recordType == routeRecordType[ currentRoute ]);
    if(blankDocumentOption){
      this.selectedOption = blankDocumentOption;
      return ; 
    }
    
    this.selectedOption = null

  }

  //Identify tradingPartner role in a tradingPartnership. Vice versa our active company role will be of opposite role.
  getTradingPartnerRole( referencedTradingPartnership:ReferencedTradingPartnership):string{
    if(this.companyService.activeCompany.companyId == referencedTradingPartnership.buyerCompanyId ){
      return 'supplier';
    }
    if (this.companyService.activeCompany.companyId == referencedTradingPartnership.supplierCompanyId){
      return 'buyer';
    }
    throw new Error ('Unexpected tradingPartnership, please contact support');
  }

  //check if document is a parent document for a child document in a document rule (only filter from valid create options)
  isParentDocumentInRules( document:Document, createOptions: CreateOption[], documentRules: DocumentRule[] ) : boolean{
    let isParentDocumentInRules = false ; 

    createOptions.forEach( createOption =>{

      if(!createOption.isChild){
        return;
      }
      //get rule that, its child document has record type that is included from create option
      let parentDocumentRules = documentRules.filter( documentRule => {
        let childDocumentRecordTypes:string[] = documentRule.childDocuments.map( childDocument => childDocument.documentType);
        if(childDocumentRecordTypes.includes(createOption.recordType)){
          return true
        }
        return false;
      })

      if (parentDocumentRules.some( documentRule => documentRule.documentType === document.metadata.documentType)){
        isParentDocumentInRules = true ;
      } 

    })

    return isParentDocumentInRules ; 
  }

}
