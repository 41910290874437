// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  margin: 0px;
  font-size: 14px !important;
}

ion-list {
  padding: 0px;
}

ion-item {
  --min-height:fit-content !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  --border-style: none;
  --inner-padding-end:0px;
  --padding-start:0px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/invoice-totals/invoice-totals.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,0BAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,mCAAA;EACA,2BAAA;EACA,8BAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":["ion-label{\n  margin: 0px;\n  font-size: 14px!important;\n}\n\nion-list{\n  padding: 0px;\n}\n\nion-item{\n  --min-height:fit-content !important;\n  padding-top:5px!important;\n  padding-bottom: 5px !important;\n  --border-style: none;\n  --inner-padding-end:0px;\n  --padding-start:0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
