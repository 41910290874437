// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-container {
  display: inline-block;
  border-radius: 50%;
  padding: 8px;
}
.icon-container:host {
  background-color: backgroundColor;
}`, "",{"version":3,"sources":["webpack://./src/app/action-bar/action-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AACE;EACE,iCAAA;AACJ","sourcesContent":[".icon-container {\n    display: inline-block;\n    border-radius: 50%;\n    padding: 8px;\n    \n  &:host {\n    background-color: #{backgroundColor};\n  }\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
