import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { User } from 'app/shared/common/interfaces/user';
import { AuthService} from 'app/core/services';
import { of, Subject,} from 'rxjs';
import { CompanyService } from 'app/company/company.service';
import { ValidatorService } from 'app/core/services/validator.service';
import { FieldGroup, FormField, Ui } from 'app/shared/common/interfaces/ui';
import { CompanyAssociation } from 'app/shared/common/interfaces/companyAssociation';
import { catchError, takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-account-activation-modal-page',
  templateUrl: './account-activation-modal-page.html',
  styleUrls: ['./account-activation-modal-page.scss'],
})

export class AccountActivationModalPage implements OnInit {
  ui: Partial<Ui>;
  fieldGroups: FieldGroup<FormField>[];
  formGroup: FormGroup;
  destroy$ = new Subject<void>();
  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService,
    private validatorService:ValidatorService,
  ) {}

  ngOnInit() {
    this.fieldGroups = [ // render the sign up modal ui
      {
        name: 'emailFormUI',
        fieldMaxColumnSize:1,
        groupColumnSize:1,
        fields: [
          {
            name: 'email',
            label: 'EMAIL',
            type: 'input',
            inputType: 'email',
            placeholder: 'EMAIL',
            fieldColumnSize: 1,
            validators:[
              {name:'required',errorType:'required',message:'EMAILREQUIRED'},
              {name:'email',errorType:'email',message:'EMAILPATTERN'}
            ]
          },
          {
            name: 'tenantName',
            label: 'TENANTNAME',
            type: 'input',
            inputType: 'text',
            placeholder: 'TENANTNAME',
            readonly:true,
            fieldColumnSize: 1,
            validators:[
              {name:'required',errorType:'required',message:'COMPANYNAMEREQUIRED'}
            ]
          },
          {
            name: 'companyAccountType', //MAY CHANGE TO 'admin' IN FUTURE
            label: 'COMPANYACCOUNTTYPE',
            type: 'select',
            options: [
              { text: 'User', id: 'false' },
              { text: 'Admin', id: 'true'},
            ],
            placeholder: 'COMPANYACCOUNTTYPE',
            fieldColumnSize: 1,
            validators:[
              {name:'required',errorType:'required',message:'COMPANYACCOUNTTYPEREQUIRED'}
            ]
          },
        ],
      },
    ]
    this.ui = { // requires ui object to apply the validations
      uiTabs: [{
          name: '', // name & panel not required for <app-field-container>
          panel: null,
          groupMaxColumnSize:1,
          fieldGroups: this.fieldGroups // setValidatorFromUI() function checks inside the fieldGroups to apply the formGroup validations
        }]
    };
    this.formGroup = this.formBuilder.group({
      email: [''],
      tenantName: [this.companyService.activeCompany.tenantName],
      companyAccountType: [''],
    });
    this.formGroup = this.validatorService.setValidatorFromUI(this.formGroup,this.ui) ;
  }

  ionViewWillLeave() {
    this.formGroup.reset();
  }

  onSubmit(): void {

    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return ; 
    }

    if (this.formGroup.valid) {
      const email: User['email'] = this.formGroup.value.email;
      const isAdmin:CompanyAssociation['isAdmin'] = this.formGroup.value.companyAccountType;
      this.authService.sendActivationLink(email,this.companyService.activeCompany.companyId,isAdmin).pipe(
        takeUntil(this.destroy$),
        catchError(()=>{
          this.formGroup.patchValue({email: ''});
          this.formGroup.markAllAsTouched();
          return of(null)
        })
      ).subscribe((res)=>{
        if (res === 'OK') {
           this.modalController.dismiss();
        }
      })
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
