import { DocumentRecordType } from './recordTypes';

export interface ResponseStatus {
    documentType: DocumentRecordType;  
    responseCoverage: ResponseCoverage;
}

export enum ExchangeStatus {
    Draft = 0,
    Released = 1,
    Viewed = 2,
    Modified = 3,
}
  
export enum TransactionStatus {
    Processing = 0,
    Cancelled = 1,
    Completed = 2,
}
  
export enum ResponseCoverage {
    None = 0,
    Partial = 1,
    Full = 2,
}