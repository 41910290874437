import { Directive } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslationService } from 'app/core/services/translation.service';

export interface OverlayEventDetail<T = any> {
  data?: T;
  role?: string;
}

@Directive({
  selector: 'AlertDialogComponent'
})
export class AlertDialogComponent {

  constructor(private alertCtrl: AlertController,
    private translationService: TranslationService
  ){

  }

  public async basicAlert(header: string, subHeader: string, message: string,
    buttonText: string): Promise<OverlayEventDetail<any>>{
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: this.translationService.getTranslatedText(header),
      subHeader: this.translationService.getTranslatedText(subHeader),
      message: this.translationService.getTranslatedText(message),
      buttons: [this.translationService.getTranslatedText(buttonText)]
    });

    await alert.present();
    return alert.onDidDismiss();
  }

  public async confirmAlert(header: string, subHeader: string, message: string,
  confirmButtonText: string, cancelButtonText: string, confirmHandler: (value: any) => boolean | void | {
    [key: string]: any;
  }, cancelHandler:(value: any) => boolean | void | {
    [key: string]: any;
  }): Promise<OverlayEventDetail<any>>{
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: this.translationService.getTranslatedText(header),
      subHeader: this.translationService.getTranslatedText(subHeader),
      message: this.translationService.getTranslatedText(message),
      buttons: [
        {
          text: this.translationService.getTranslatedText(cancelButtonText),
          role: 'cancel',
          handler: cancelHandler
        },
        {
          text: this.translationService.getTranslatedText(confirmButtonText),
          role: 'confirm',
          handler: confirmHandler
        }
      ]
    });

    await alert.present();
    return alert.onDidDismiss();
  }
}
