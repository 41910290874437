import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InvoiceTotals } from 'app/shared/common/interfaces/invoiceTotals';
import { LineItem } from 'app/shared/common/interfaces/lineItem';
import { combineLatest, Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'invoice-totals',
  templateUrl: './invoice-totals.component.html',
  styleUrls: ['./invoice-totals.component.scss'],
})
export class InvoiceTotalsComponent implements OnInit {
  @ViewChild('vatTable') vatTable: NgxDatatableModule;
  @Input() lineItems$: Subject<Partial<LineItem>[]>;
  @Input() surcharge$: BehaviorSubject<number>; // TODO not working; see https://app.asana.com/0/1204014722864744/1207480118495943/f
  @Input() vatBreakdown$: Subject<InvoiceTotals[]>; // TODO should not be array
  lineItems: Partial<LineItem>[];
  vatBreakdownHeaders: any;
  vatBreakdown: any;

  surcharge: number;

  constructor() {
    this.vatBreakdownHeaders = [
      { name: 'Line Net Amount', prop: 'lineNetAmount' },
      { name: 'Total Net Amount', prop: 'totalNetAmount' },
      { name: 'Total VAT Amount', prop: 'totalVatAmount' },
      { name: 'Surcharges Amount', prop: 'surchargesAmount' },
      { name: 'Total Invoice Amount', prop: 'totalInvoiceAmount' },
    ];
  }

  ngOnInit() {
    combineLatest([
      this.surcharge$, // TODO not working; see https://app.asana.com/0/1204014722864744/1207480118495943/f
      this.lineItems$
    ]).subscribe(([surcharge, lineItems]) => {
      this.lineItems = lineItems;
      this.surcharge = surcharge;
      this.vatBreakdown = [];

      // Calculate total net amount
      let totalNetAmount = 0;
      let totalVAT = 0;
      lineItems?.forEach(lineitem => {
        const costPerLineitem = lineitem.price * lineitem.quantity;
        let vatPercent = 0;
        if (lineitem.vatCode === 'S') {
          vatPercent = 0.2;
      } else if (lineitem.vatCode === 'L') {
          vatPercent = 0.17;
      } else if (lineitem.vatCode === 'Z') {
        vatPercent = 0;
      }
      let vatForItem = costPerLineitem * vatPercent;
        totalNetAmount += costPerLineitem;
        totalVAT += vatForItem;
      });

      // TODO: Find out about below lineNetAmount
      this.vatBreakdown.push({
        lineNetAmount: totalNetAmount.toFixed(2), //What is difference between this and totalNetAmount?
        totalNetAmount: totalNetAmount.toFixed(2),
        totalVatAmount: totalVAT.toFixed(2),
        surchargeAmount: (+surcharge).toFixed(2),
        totalInvoiceAmount: (totalNetAmount + totalVAT + Number(surcharge)).toFixed(2)
      })
      this.vatBreakdown$.next(this.vatBreakdown);
    });
  };
}