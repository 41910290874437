import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, } from 'rxjs';
import { Document, } from 'app/shared/common/interfaces/document';
import { HttpClient } from '@angular/common/http';
import { ExportDocument } from 'app/shared/common/interfaces/global';
import { ExchangeStatus } from 'app/shared/common/interfaces/documentStatus';
import { CompanyService } from 'app/company/company.service';
import { TradingPartnership } from 'app/shared/common/interfaces/tradingPartnership';
import * as DocumentHelpers from 'app/shared/common/modules/document-helpers';
import { CompanyReference } from 'app/shared/common/interfaces/company';
@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private http:HttpClient, private companyService:CompanyService) {

   }
  public document$ = new Subject<Document>;
  public emitDocument(document:Document|null) { // accept null to emit null value which is used for destroying the observable
  this.document$.next(document);
  }
  public currentDocument = this.document$.asObservable();


  public activePriceListId$ = new BehaviorSubject<any>([]);
  public availablePriceLists$ = new BehaviorSubject<any>([]);


  getDocument(documentId: Document['_id']):Observable<Document> {
    return this.http.get<Document>(`document/${documentId}`);
  }

  createDocument(document: Document) {
    return this.http.post('document',{document},{responseType:'text'})
  }

  updateDocument(document: Document) {
    return this.http.put('document', {document}, {responseType: 'text' });
  }

  deleteDocuments(documentIds: Document['_id'][]) {
    return this.http.delete('document', { body: {documentIds}, responseType: 'text' });
  }

  exportDocument(documentIds: Document['_id'][]): Observable<ExportDocument> {
    return this.http.post<ExportDocument>('document/export', { documentIds });
  }

  exchangeStatus(document:Document, status:ExchangeStatus, tradingPartnership?:TradingPartnership):Document{
    try {
    let recipientId:CompanyReference['companyId'];
    let isActiveCompanyRecipient:boolean;
    if (tradingPartnership) {
      recipientId = DocumentHelpers.determineRecipientId(document.metadata.documentType,tradingPartnership);
      isActiveCompanyRecipient = recipientId === this.companyService.activeCompany?.companyId;
    }
    switch (status) {
      case ExchangeStatus.Draft:  // currently draft status is handled by the autofillService on the server
        document.exchangeStatus = ExchangeStatus.Draft;
        break;
      case ExchangeStatus.Released:
        if (!isActiveCompanyRecipient && document.exchangeStatus === ExchangeStatus.Draft) { // Sender: Submits a draft document, changing status to released
          document.exchangeStatus = ExchangeStatus.Released;
          this.updateDocument(document).subscribe();
        }
        break;
      case ExchangeStatus.Viewed:
       if (isActiveCompanyRecipient && document.exchangeStatus === ExchangeStatus.Released) { // Recipient: Opens a released document, changing the status to viewed
        document.exchangeStatus = ExchangeStatus.Viewed;
        this.updateDocument(document).subscribe();
      }
      case ExchangeStatus.Modified:
      if (isActiveCompanyRecipient && document.exchangeStatus !== ExchangeStatus.Draft) { // Recipient: Edits a non-draft document, marking it as modified
        document.exchangeStatus = ExchangeStatus.Modified;
        return document;
     }
        break;
        default:
          break;
  }
  return document;
    } catch (err) {
      console.error('an err occured during exchangeStatus switch case:',err);
    }
}

}
