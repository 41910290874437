import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TranslationService } from 'app/core/services/translation.service';
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { Document } from 'app/shared/common/interfaces/document';
import { Ui, FormField } from 'app/shared/common/interfaces/ui';
import { LineItem } from 'app/shared/common/interfaces/lineItem';
import { VatBreakdownItem } from 'app/shared/common/interfaces/vatBreakdown';
import { InvoiceTotals } from 'app/shared/common/interfaces/invoiceTotals';
import { formatDate } from '@angular/common';
import { UiService } from 'app/ui/ui.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs/internal/Subject';
import { EnrichUiService } from 'app/ui/enrich-ui.service';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  //ui:Ui;
  //document: Document;
  destroy$ = new Subject<void>();
  constructor(
    private translationService: TranslationService,
    private enrichUiService: EnrichUiService,
    private uiService:UiService
  ) {
  }

  getFieldValue(field: FormField, document: Document): string | Partial<LineItem>[] | VatBreakdownItem[] | InvoiceTotals[] {
    let value: string | Partial<LineItem>[] | VatBreakdownItem[] | InvoiceTotals[] = "";

    if(document.fields[field.name]){
      if(field.type == "input" || field.type == "url" || field.type == "header"){
        value = document.fields[field.name].toString();
      }
      else if(field.type == "datetime"){
          value = formatDate((document.fields[field.name] as Date),field.format,"en_US"); //TODO: use dynamic locale
      }
      else if(field.type == "select"){
        value = document.fields[field.name].toString();
        value = field.options.find(option=>option.id == value).text;
      }
      else if(field.type == "lineItems"){
        value = (document.fields[field.name] as Partial<LineItem>[]);
      }
      else if(field.type == "vatSummaryTable"){
        value = (document.fields[field.name] as VatBreakdownItem[]);
      }
      else if(field.type == "invoiceTotals"){
        value = (document.fields[field.name] as InvoiceTotals[]);
      }
      else{
        value = document.fields[field.name].toString();
      }
    }

    return value;
  }

  printMulti(documents: Document[]): void {
    (pdfMake as any).fonts={
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
      }
    };
    let docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageOrientation: 'landscape',
      content: []
    };
    let content = [];
    let styles = {
      sectionHeader: {
        fontSize: 16
      },
      fieldGroupHeader: {
        fontSize: 12
      },
      fieldLabel: {
        bold: true,
        fontSize: 10
      },
      fieldValue: {
        fontSize: 8
      },
      lineItemHeader: {
        fontSize: 12,
        bold: true
      },
      lineItemTable: {
        fontSize: 10,
        margin: [0, 10, 0 ,10] as [number, number, number, number]
      },
      lineItemTableHeader: {
        fontSize: 12,
        bold: true,
        color: 'black'
      },
      lineBreak: {
        marginTop: 10
      }
    };

    const contentObservables = documents.map((document) =>
      this.getUi(document.uiId).pipe(
        switchMap((ui) => {
          content = this.contentAppend(content, ui, document);
          content.push({text: "", pageBreak: "after"});
          return of(content);
        }),
        takeUntil(this.destroy$)
      )
    );

    forkJoin(contentObservables).subscribe(() => {
      content.pop(); //remove the last pagebreak
      docDefinition.content = content;
      docDefinition.styles = styles;
      pdfMake.createPdf(docDefinition).open();
    });
  }

  print(ui: Ui, document: Document): void{
    (pdfMake as any).fonts={
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
      }
    };
    let docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageOrientation: 'landscape',
      content: []
    };
    let content = [];
    let styles = {
      sectionHeader: {
        fontSize: 16
      },
      fieldGroupHeader: {
        fontSize: 12
      },
      fieldLabel: {
        bold: true,
        fontSize: 10
      },
      fieldValue: {
        fontSize: 8
      },
      lineItemHeader: {
        fontSize: 12,
        bold: true
      },
      lineItemTable: {
        fontSize: 10,
        margin: [0, 10, 0 ,10] as [number, number, number, number]
      },
      lineItemTableHeader: {
        fontSize: 12,
        bold: true,
        color: 'black'
      },
      lineBreak: {
        marginTop: 10
      }
    };

    content = this.contentAppend(content, ui, document);

    docDefinition.content = content;
    docDefinition.styles = styles;
    pdfMake.createPdf(docDefinition).open();
  }

  private contentAppend(content: any[], ui: Ui, document: Document): any[]{
    ui.uiTabs.forEach(uiTab=>{
      let columnBase: boolean = true;
      let groupMaxColumnSize: number = uiTab.groupMaxColumnSize;

      if(uiTab.name != "recordHistory"){
        let tabTitle = "";
        if(uiTab.name == "DOCUMENT"){
          tabTitle = document.metadata.documentType;
        }else{
          tabTitle = uiTab.panel.title;
        }
        content.push({ text: this.translationService.getTranslatedText(tabTitle), style: "sectionHeader"});
        content.push({text: "", style: "lineBreak"});
        if(columnBase){
          let columns = [];
          let currentGroupSize: number = 0;
          uiTab.fieldGroups.forEach(fieldGroup=>{
            let column = [];
            let groupColumnSize: number = fieldGroup.groupColumnSize;
            currentGroupSize += groupColumnSize; //increase the currentGroupSize
            column.push({ text: this.translationService.getTranslatedText(fieldGroup.label), style: "fieldGroupHeader"});
            if(fieldGroup.name != "documentHistoryGroup"){
              fieldGroup.fields.forEach(field=>{
                let fieldValue = this.getFieldValue(field, document);
                if(typeof fieldValue === "string"){
                  column.push({ columns: [ { text: this.translationService.getTranslatedText(field.label), style: "fieldLabel" }, { text: fieldValue, style: "fieldValue" }]});
                }else if(field.type == "lineItems"){
                  //column.push({text: "", style: "lineBreak"});
                  //column.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader" });
                  currentGroupSize = groupMaxColumnSize;
                  columns.push(column);
                  content.push({ columns: [...columns] });
                  columns = [];
                  column = [];
                  let tableBody = [];
                  let tableHeader = [];
                  (fieldValue as Partial<LineItem>[]).forEach((lineItem, index)=>{
                    let tableRow = [];
                    Object.keys(lineItem).forEach((key)=>{
                      if(index == 0){
                        console.log("[Print.LineItem Type]: " + typeof lineItem[key as keyof LineItem]);
                        tableHeader.push({text: key, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                      }
                      tableRow.push({ text: lineItem[key as keyof LineItem], style: "fieldValue", aliagnment: 'center'});
                    });
                    if(index == 0){
                      tableBody.push(tableHeader);
                    }
                    tableBody.push(tableRow);
                  });
                  let table = {
                    table: {
                      style: "lineItemTable",
                      body: tableBody
                    }
                  };
                  column.push({ columns: [table]});
                }else if(field.type == "vatSummaryTable"){
                  //column.push({text: "", style: "lineBreak"});
                  //column.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
                  currentGroupSize = groupMaxColumnSize;
                  columns.push(column);
                  content.push({ columns: [...columns] });
                  columns = [];
                  column = [];

                  let tableBody = [];
                  let tableHeader = [];
                  (fieldValue as VatBreakdownItem[]).forEach((vatItem, index)=>{
                    let tableRow = [];
                    Object.keys(vatItem).forEach((key)=>{
                      if(index == 0){
                        tableHeader.push({text: key, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                      }
                      tableRow.push({ text: vatItem[key as keyof VatBreakdownItem], style: "fieldValue", aliagnment: 'center'});
                    });
                    if(index == 0){
                      tableBody.push(tableHeader);
                    }
                    tableBody.push(tableRow);
                  });
                  let table = {
                    table: {
                      style: "lineItemTable",
                      body: tableBody
                    }
                  };
                  column.push(table);
                }else if(field.type == "invoiceTotals"){
                  //column.push({text: "", style: "lineBreak"});
                  //column.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
                  currentGroupSize = groupMaxColumnSize;
                  columns.push(column);
                  content.push({ columns: [...columns] });
                  columns = [];
                  column = [];

                  let tableBody = [];
                  let tableHeader = [];
                  (fieldValue as InvoiceTotals[]).forEach((invoiceItem, index)=>{
                    let tableRow = [];
                    Object.keys(invoiceItem).forEach((key)=>{
                      if(index == 0){
                        tableHeader.push({text: key, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                      }
                      tableRow.push({ text: invoiceItem[key as keyof InvoiceTotals], style: "fieldValue", aliagnment: 'center'});
                    });
                    if(index == 0){
                      tableBody.push(tableHeader);
                    }
                    tableBody.push(tableRow);
                  });
                  let table = {
                    table: {
                      style: "lineItemTable",
                      body: tableBody
                    }
                  };
                  column.push(table);
                }else{
                  //TODO: more components
                }
              });
            }else{
              fieldGroup.fields.forEach(field=>{
                let histories = document.history;
                column.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
                //column.push({text: "", style: "lineBreak"});
                currentGroupSize = groupMaxColumnSize;
                columns.push(column);
                content.push({ columns: [...columns] });
                columns = [];
                column = [];

                let tableBody = [];
                let tableHeader = [];
                histories.forEach((history, index)=>{
                  let tableRow = [];
                  field.properties.forEach(prop=>{
                    if(index == 0){
                      tableHeader.push({text: prop.prop, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                    }
                    tableRow.push({ text: history[prop.prop], style: "fieldValue", aliagnment: 'center'});
                  });
                  if(index == 0){
                    tableBody.push(tableHeader);
                  }
                  tableBody.push(tableRow);
                });
                let table = {
                  table: {
                    style: "lineItemTable",
                    body: tableBody
                  }
                };
                column.push(table);
              });
            }

            column.push({text: "", style: "lineBreak"});
            columns.push(column);
            if(currentGroupSize>=groupMaxColumnSize){
              content.push({ columns: [...columns] });
              columns = []; //clean the columns for next row
              currentGroupSize = 0; //clean the currentGroupSize for next row
            }
          });
          content.push({ columns: columns });
        }else{
          uiTab.fieldGroups.forEach(fieldGroup=>{
            content.push({ text: this.translationService.getTranslatedText(fieldGroup.label), style: "fieldGroupHeader"});
            fieldGroup.fields.forEach(field=>{
              let fieldValue = this.getFieldValue(field, document);
              if(typeof fieldValue === "string"){
                content.push({ columns: [ { text: this.translationService.getTranslatedText(field.label), style: "fieldLabel" }, { text: fieldValue, style: "fieldValue" }]})
              }else if(field.type == "lineItems"){
                content.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
                let tableBody = [];
                let tableHeader = [];
                (fieldValue as Partial<LineItem>[]).forEach((lineItem, index)=>{
                  let tableRow = [];
                  Object.keys(lineItem).forEach((key)=>{
                    if(index == 0){
                      console.log("[Print.LineItem Type]: " + typeof lineItem[key as keyof LineItem]);
                      tableHeader.push({text: key, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                    }
                    tableRow.push({ text: lineItem[key as keyof LineItem], style: "fieldValue", aliagnment: 'center'});
                  });
                  if(index == 0){
                    tableBody.push(tableHeader);
                  }
                  tableBody.push(tableRow);
                });
                let table = {
                  table: {
                    style: "lineItemTable",
                    body: tableBody
                  }
                };
                content.push(table);
              }else if(field.type == "vatSummaryTable"){
                content.push({text: "", style: "lineBreak"});
                content.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
                let tableBody = [];
                let tableHeader = [];
                (fieldValue as VatBreakdownItem[]).forEach((vatItem, index)=>{
                  let tableRow = [];
                  Object.keys(vatItem).forEach((key)=>{
                    if(index == 0){
                      tableHeader.push({text: key, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                    }
                    tableRow.push({ text: vatItem[key as keyof VatBreakdownItem], style: "fieldValue", aliagnment: 'center'});
                  });
                  if(index == 0){
                    tableBody.push(tableHeader);
                  }
                  tableBody.push(tableRow);
                });
                let table = {
                  table: {
                    style: "lineItemTable",
                    body: tableBody
                  }
                };
                content.push(table);
              }else if(field.type == "invoiceTotals"){
                content.push({text: "", style: "lineBreak"});
                content.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
                let tableBody = [];
                let tableHeader = [];
                (fieldValue as InvoiceTotals[]).forEach((invoiceItem, index)=>{
                  let tableRow = [];
                  Object.keys(invoiceItem).forEach((key)=>{
                    if(index == 0){
                      tableHeader.push({text: key, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                    }
                    tableRow.push({ text: invoiceItem[key as keyof InvoiceTotals], style: "fieldValue", aliagnment: 'center'});
                  });
                  if(index == 0){
                    tableBody.push(tableHeader);
                  }
                  tableBody.push(tableRow);
                });
                let table = {
                  table: {
                    style: "lineItemTable",
                    body: tableBody
                  }
                };
                content.push(table);
              }else{
                //TODO: vat and invoice total
              }
            });
          });
        }
      }else{
        //history need special process, because the value is not in document.fields.
        //instead, it's in document.history. also it's a list object
        content.push({ text: this.translationService.getTranslatedText(uiTab.panel.title), style: "sectionHeader"});
        uiTab.fieldGroups.forEach(fieldGroup=>{
          content.push({ text: this.translationService.getTranslatedText(fieldGroup.label), style: "fieldGroupHeader"});
          fieldGroup.fields.forEach(field=>{
            let histories = document.history;
            content.push({ text: this.translationService.getTranslatedText(field.label), style: "lineItemHeader"});
            let tableBody = [];
            let tableHeader = [];
            histories.forEach((history, index)=>{
              let tableRow = [];
              field.properties.forEach(prop=>{
                if(index == 0){
                  tableHeader.push({text: prop.prop, style: "lineItemTableHeader", alignment: 'center'}); //insert the header
                }
                tableRow.push({ text: history[prop.prop], style: "fieldValue", aliagnment: 'center'});
              });
              if(index == 0){
                tableBody.push(tableHeader);
              }
              tableBody.push(tableRow);
            });
            let table = {
              table: {
                style: "lineItemTable",
                body: tableBody
              }
            };
            content.push(table);
          });
        });
      }
      content.push({text: "", style: "lineBreak"});
    });

    return content;
  }

  private getUi(uiId: Document['uiId']): Observable<Ui> {
    return this.uiService.getUi(uiId).pipe(
      catchError(() => {
        return of(null);
      }),
      switchMap((ui) => {
        const uiTabsEnriched = this.enrichUiService.enrichUiTabs(ui.uiTabs);
        ui.uiTabs = uiTabsEnriched;
        return of(ui);
      }),
      takeUntil(this.destroy$)
    );
  }
}
