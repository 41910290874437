export enum Action {
  archive,
  selectMode,
  export,
  print,
  duplicate,
  changeStatus,
  lock,
  delete,
  open,
  create,
  edit,
  save,
  release,
  search,
  ellipsis,
  filter,
  metadata,
  transaction
}

/**
 *
 * A number, who's binary form represents a selection of actions.
 *
 * Can be used as a mask, to disable certain actions.
 *
 * Can be the result of several masks combined, to produce a number which determines actions to be visible.
 *
 * Examples:
 * "BinaryActions.all & ~BinaryActions.print" - All actions except print
 * "BinaryActions.edit | BinaryActions.delete" - Only the edit and delete actions
 * "(someMask & BinaryActions.save) === BinaryActions.save" Returns true if someMask contains the save action.
 *
 */
export enum BinaryActions {
  /* tslint:disable:no-bitwise */
  none = 0,
  edit = 1 << 0, 
  save = 1 << 1, 
  print = 1 << 2,
  duplicate = 1 << 3, 
  selectMode = 1 << 4,
  create = 1 << 5,
  open = 1 << 6,
  delete = 1 << 7,
  export = 1 << 8, 
  filter = 1 << 9, 
  release = 1 << 10,
  metadata = 1 <<11,
  transaction = 1 << 12,
  // TODO: add the rest
  all = ~(~0 << 13) // The number after the << must be incremented to be 1 greater than the previous in this list
  // This list can have a maximum of 29 actions, excluding 'all'
  /* tslint:enable:no-bitwise */
}

export interface ActionButton {
  icon: string ,
  name: string,
  visible: boolean,
  action: Action,
  backgroundColor: string
}