export enum NonDocumentRecordType {
    User = 'USER',
    Product = 'PRODUCT',
    PriceList = 'PRICELIST',
    Location = 'LOCATION',
    Transaction = 'TRANSACTION',
    Company = 'COMPANY',
    TradingPartnership = 'TRADINGPARTNERSHIP'
  }
  
export enum DocumentRecordType {
    Order = 'ORDERS',
    OrderResponse = 'ORDRSP',
    Invoice = 'INVOIC',
    RemittanceAdvice = 'REMADV',
    DespatchAdvice = 'DESADV',
    SalesReport = 'SLSRPT',
    InventoryReport = 'INVRPT',
    ReceivingAdvice = 'RECADV',
    PaymentOrder = 'PAYORD',
    CustomsDeclaration = 'CUSDEC',
    ArrivalNoticeMessage = 'IFTMAN',
    InstructionMessage = 'IFTMIN',
    MultipleCreditAdvice = 'CREMUL',
    MultipleDebitAdvice = 'DEBMUL',
    BalanceMessage = 'BALANC',
    DeliveryJustInTime = 'DELJIT',
    CustomsResponse = 'CUSRES',
    TaxControlMessage = 'TAXCON',
    SyntaxAndServiceReport = 'CONTRL',
    ApplicationErrorAndAcknowledgment = 'APERAK',
    QuotationMessage = 'QUOTES'
  }

export type RecordType = DocumentRecordType | NonDocumentRecordType

//TODO: maintain it each time an actual route is added
//key: Route, value: RecordTypeName
export const routeRecordType =  {
  'orders':'ORDERS',
  'invoices':'INVOIC',
  'users':'USER',
  'products':'PRODUCT',
  'price-list':'PRICELIST',
  'locations':'LOCATION',
  'company':'COMPANY',
};