import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Subscription } from 'rxjs';

import { AuthService, SelectCompanyService } from 'core/services';
import { SignedUser } from 'shared/user';
import { CompanyReference } from 'common/interfaces/company';
import { CompanyService } from './company/company.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  user: SignedUser;
  companies: CompanyReference[];

  isAuthenticated = false;

  public userSubscription: Subscription;

  constructor(
    public authService: AuthService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private selectCompanyService: SelectCompanyService,
    private companyService: CompanyService,
  ) {
    this.initializeApp();
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.isAuthenticated = this.authService.autoLogin();
    this.userSubscription = this.authService.user$.subscribe(user => {
      this.isAuthenticated = !!user;
      //prevent service subscribe to null user
      if(user){
        let activeCompany:CompanyReference;
        this.companyService.listUserAssociatedCompanies().subscribe(companyAssociations=>{
          activeCompany = companyAssociations[0];
          this.companyService.activeCompany = activeCompany;
          this.selectCompanyService.selectCompanyEvent(activeCompany);
        })
      }
      // if (!this.authService.isSuperAdmin) {

      // }
    });
    // this.userSubscription = this.authService.user.subscribe(user => {
    //   this.user = user;
    //   this.isAuthenticated = !!user;
    //   // FIXME: this does not work as intended on the superadmin account
    //   // The superadmin account is not usually assigned to a company and therefore this.user.companies[0] will probably be undefined
    //   // We don't have company list access here but ideally, we just assign the first company in the autocomplete
    // //   if (!this.authService.isSuperAdmin) {
    // //     this.assignCurrentCompany(this.user.companies[0]);
    // //   }

    // //   this.translateMenuText();
    // });
  }

  /**
   * Temporary button for testing logic.
   */
  magicButton() {
    /* test all privilge check overloads */
    // this.checkPrivilegeService.checkPrivilege('orders', 'pizza', 'edit').subscribe(noodle => {
    //   console.log('orders', 'pizza', 'edit', noodle);
    // });
    // this.checkPrivilegeService.checkPrivilege('orders', 'pizza').subscribe(noodle => {
    //   console.log('orders', 'pizza', noodle);
    // });
    // this.checkPrivilegeService.checkPrivilege('orders').subscribe(noodle => {
    //   console.log('orders', noodle);
    // });
    // this.checkPrivilegeService.checkPrivilege().subscribe(noodle => {
    //   console.log(noodle);
    // });

    // Insert your own code here
  }
}
