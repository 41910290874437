import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';


import { AuthService } from 'core/services';
import { SignedUser } from 'app/shared/user';
import { User } from 'app/shared/common/interfaces/user';
import {  ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class IsSuperAdmin implements CanActivate {

  constructor(private authService: AuthService, private router: Router, 
    private toastController: ToastController) {  }

   async canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      const user: SignedUser = this.authService.user$.value;
      const isSuperAdmin: User['isSuperAdmin'] = user.isSuperAdmin === true;
      const currentPage = _state.url.split('/')[1];
      if (isSuperAdmin) {
        return true;
      }

      const toast = await this.toastController.create({ 
        header:'Access Denied:',
        message:`Only super admins can view this ${currentPage} page. `,
        duration:1500, 
        position: 'top',
        color:'danger',
        translucent:false,
        buttons: [
          {
            side: 'end', // position of the close button (start/end)
            icon: 'close', // icon name for the close button
            role: 'cancel', // role which triggers toast dismiss when button clicked
            handler: () => {
              toast.dismiss(); // Manually dismiss the toast
            }
          }
        ]
      })

      await toast.present();
      return this.router.createUrlTree(['/login']);

  }

}



